.giftsSection{
    background-image: url("../../../static/images/gifts.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.searchSettingsContainer {
    display: grid;
    grid-template-columns: 20rem;
    justify-content: space-around;
}

.searchSettingSubContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.searchSettingContent {
    display: grid;
    justify-content: center;
    grid-template-columns: 10rem 10rem;
    margin: 2em;
}
.giftButton {
    background-color: transparent;
}

.moreButton {
    background-color: transparent;
}

.giftLabel{
    margin: 0.5em;
}

.giftCheckbox{
    width: 1.3em;
    height: 1.3em;
    background-color: transparent;
    border-radius: 20%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.giftCheckbox:hover{
    transition: opacity 1s;
    background-color: #000000;
    opacity: 1;
}

.giftCheckbox:checked {
    background-color: white;
}

.searchResult{
    border: 1px solid #ddd;
    margin: 0.5em;
    padding: 0.5em;
}

.searchbar{
    width: 16em;
    height: 2em;
    background-color: transparent;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    color: white;
    font-size: 1.2em;
    font-family: 'werewolf';
}