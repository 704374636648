.navMenu {
    position: fixed;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    height: 52px;
    color: #fff;
    z-index: 300;
    background-color: black;
    background: rgba(0, 0, 0, 1);
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.logo {
    height: 2rem;
    width: 2rem;
    height: auto;
    padding-top: 0.5em;
    padding-left: 1em;
}

.hamburgerMenuIcon {
    display: none;
}


@media screen and (max-width: 1300px) {
    .navMenu-mobile {
        position: fixed;
        height: 100%;
        width: 100%;
        color: #fff;
        z-index: 300;
        background-color: black;
        background: rgba(0, 0, 0, 1);
        display: block;
    }

    .hamburgerMenuIcon {
        height: 1.5em;
        width: 2em;
        margin-top: 0.8em;
        margin-right: 2em;
        display: flex;
    }

    .navbar-brand {}

    .logo {}
}

@media screen and (min-width: 3000px) {}