/*
 *# [Nerd Fonts]  Website: https://www.nerdfonts.com
 *# [Nerd Fonts]  Development Website: https://github.com/ryanoasis/nerd-fonts
 *# [Nerd Fonts]  Version: 2.0.0
 *# [Nerd Fonts]  The following is generated from the build script
 */

 @font-face {
    font-family: 'NerdFontsSymbols Nerd Font';
    /* @TODO you need to adjust the path to the font: */
    src: url("RobotoNerdFont.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  .nf, .nerd-font, .nerd-fonts {
    font-family: 'NerdFontsSymbols Nerd Font';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering (from devicons, et al) */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .nf-custom-c:before {
    content: "\e61e";
  }
  .nf-custom-cpp:before {
    content: "\e61d";
  }
  .nf-custom-electron:before {
    content: "\e62e";
  }
  .nf-custom-elixir:before {
    content: "\e62d";
  }
  .nf-custom-elm:before {
    content: "\e62c";
  }
  .nf-custom-folder:before {
    content: "\e5ff";
  }
  .nf-custom-folder_config:before {
    content: "\e5fc";
  }
  .nf-custom-folder_git:before {
    content: "\e5fb";
  }
  .nf-custom-folder_git_branch:before {
    content: "\e5fb";
  }
  .nf-custom-folder_github:before {
    content: "\e5fd";
  }
  .nf-custom-folder_npm:before {
    content: "\e5fa";
  }
  .nf-custom-folder_open:before {
    content: "\e5fe";
  }
  .nf-custom-go:before {
    content: "\e626";
  }
  .nf-custom-msdos:before {
    content: "\e629";
  }
  .nf-custom-vim:before {
    content: "\e62b";
  }
  .nf-custom-windows:before {
    content: "\e62a";
  }
  .nf-dev-android:before {
    content: "\e70e";
  }
  .nf-dev-angular:before {
    content: "\e753";
  }
  .nf-dev-appcelerator:before {
    content: "\e7ab";
  }
  .nf-dev-apple:before {
    content: "\e711";
  }
  .nf-dev-appstore:before {
    content: "\e713";
  }
  .nf-dev-aptana:before {
    content: "\e799";
  }
  .nf-dev-asterisk:before {
    content: "\e7ac";
  }
  .nf-dev-atlassian:before {
    content: "\e75b";
  }
  .nf-dev-atom:before {
    content: "\e764";
  }
  .nf-dev-aws:before {
    content: "\e7ad";
  }
  .nf-dev-backbone:before {
    content: "\e752";
  }
  .nf-dev-bing_small:before {
    content: "\e700";
  }
  .nf-dev-bintray:before {
    content: "\e794";
  }
  .nf-dev-bitbucket:before {
    content: "\e703";
  }
  .nf-dev-blackberry:before {
    content: "\e723";
  }
  .nf-dev-bootstrap:before {
    content: "\e747";
  }
  .nf-dev-bower:before {
    content: "\e74d";
  }
  .nf-dev-brackets:before {
    content: "\e79d";
  }
  .nf-dev-bugsense:before {
    content: "\e78d";
  }
  .nf-dev-celluloid:before {
    content: "\e76b";
  }
  .nf-dev-chart:before {
    content: "\e760";
  }
  .nf-dev-chrome:before {
    content: "\e743";
  }
  .nf-dev-cisco:before {
    content: "\e765";
  }
  .nf-dev-clojure:before {
    content: "\e768";
  }
  .nf-dev-clojure_alt:before {
    content: "\e76a";
  }
  .nf-dev-cloud9:before {
    content: "\e79f";
  }
  .nf-dev-coda:before {
    content: "\e793";
  }
  .nf-dev-code:before {
    content: "\e796";
  }
  .nf-dev-code_badge:before {
    content: "\e7a3";
  }
  .nf-dev-codeigniter:before {
    content: "\e780";
  }
  .nf-dev-codepen:before {
    content: "\e716";
  }
  .nf-dev-codrops:before {
    content: "\e72f";
  }
  .nf-dev-coffeescript:before {
    content: "\e751";
  }
  .nf-dev-compass:before {
    content: "\e761";
  }
  .nf-dev-composer:before {
    content: "\e783";
  }
  .nf-dev-creativecommons:before {
    content: "\e789";
  }
  .nf-dev-creativecommons_badge:before {
    content: "\e78a";
  }
  .nf-dev-css3:before {
    content: "\e749";
  }
  .nf-dev-css3_full:before {
    content: "\e74a";
  }
  .nf-dev-css_tricks:before {
    content: "\e701";
  }
  .nf-dev-cssdeck:before {
    content: "\e72a";
  }
  .nf-dev-dart:before {
    content: "\e798";
  }
  .nf-dev-database:before {
    content: "\e706";
  }
  .nf-dev-debian:before {
    content: "\e77d";
  }
  .nf-dev-digital_ocean:before {
    content: "\e7ae";
  }
  .nf-dev-django:before {
    content: "\e71d";
  }
  .nf-dev-dlang:before {
    content: "\e7af";
  }
  .nf-dev-docker:before {
    content: "\e7b0";
  }
  .nf-dev-doctrine:before {
    content: "\e774";
  }
  .nf-dev-dojo:before {
    content: "\e71c";
  }
  .nf-dev-dotnet:before {
    content: "\e77f";
  }
  .nf-dev-dreamweaver:before {
    content: "\e79c";
  }
  .nf-dev-dropbox:before {
    content: "\e707";
  }
  .nf-dev-drupal:before {
    content: "\e742";
  }
  .nf-dev-eclipse:before {
    content: "\e79e";
  }
  .nf-dev-ember:before {
    content: "\e71b";
  }
  .nf-dev-envato:before {
    content: "\e75d";
  }
  .nf-dev-erlang:before {
    content: "\e7b1";
  }
  .nf-dev-extjs:before {
    content: "\e78e";
  }
  .nf-dev-firebase:before {
    content: "\e787";
  }
  .nf-dev-firefox:before {
    content: "\e745";
  }
  .nf-dev-fsharp:before {
    content: "\e7a7";
  }
  .nf-dev-ghost:before {
    content: "\e71f";
  }
  .nf-dev-ghost_small:before {
    content: "\e714";
  }
  .nf-dev-git:before {
    content: "\e702";
  }
  .nf-dev-git_branch:before {
    content: "\e725";
  }
  .nf-dev-git_commit:before {
    content: "\e729";
  }
  .nf-dev-git_compare:before {
    content: "\e728";
  }
  .nf-dev-git_merge:before {
    content: "\e727";
  }
  .nf-dev-git_pull_request:before {
    content: "\e726";
  }
  .nf-dev-github:before {
    content: "\e70a";
  }
  .nf-dev-github_alt:before {
    content: "\e708";
  }
  .nf-dev-github_badge:before {
    content: "\e709";
  }
  .nf-dev-github_full:before {
    content: "\e717";
  }
  .nf-dev-gnu:before {
    content: "\e779";
  }
  .nf-dev-go:before {
    content: "\e724";
  }
  .nf-dev-google_cloud_platform:before {
    content: "\e7b2";
  }
  .nf-dev-google_drive:before {
    content: "\e731";
  }
  .nf-dev-grails:before {
    content: "\e7b3";
  }
  .nf-dev-groovy:before {
    content: "\e775";
  }
  .nf-dev-grunt:before {
    content: "\e74c";
  }
  .nf-dev-gulp:before {
    content: "\e763";
  }
  .nf-dev-hackernews:before {
    content: "\e71a";
  }
  .nf-dev-haskell:before {
    content: "\e777";
  }
  .nf-dev-heroku:before {
    content: "\e77b";
  }
  .nf-dev-html5:before {
    content: "\e736";
  }
  .nf-dev-html5_3d_effects:before {
    content: "\e735";
  }
  .nf-dev-html5_connectivity:before {
    content: "\e734";
  }
  .nf-dev-html5_device_access:before {
    content: "\e733";
  }
  .nf-dev-html5_multimedia:before {
    content: "\e732";
  }
  .nf-dev-ie:before {
    content: "\e744";
  }
  .nf-dev-illustrator:before {
    content: "\e7b4";
  }
  .nf-dev-intellij:before {
    content: "\e7b5";
  }
  .nf-dev-ionic:before {
    content: "\e7a9";
  }
  .nf-dev-java:before {
    content: "\e738";
  }
  .nf-dev-javascript:before {
    content: "\e74e";
  }
  .nf-dev-javascript_badge:before {
    content: "\e781";
  }
  .nf-dev-javascript_shield:before {
    content: "\e74f";
  }
  .nf-dev-jekyll_small:before {
    content: "\e70d";
  }
  .nf-dev-jenkins:before {
    content: "\e767";
  }
  .nf-dev-jira:before {
    content: "\e75c";
  }
  .nf-dev-joomla:before {
    content: "\e741";
  }
  .nf-dev-jquery:before {
    content: "\e750";
  }
  .nf-dev-jquery_ui:before {
    content: "\e754";
  }
  .nf-dev-komodo:before {
    content: "\e792";
  }
  .nf-dev-krakenjs:before {
    content: "\e785";
  }
  .nf-dev-krakenjs_badge:before {
    content: "\e784";
  }
  .nf-dev-laravel:before {
    content: "\e73f";
  }
  .nf-dev-less:before {
    content: "\e758";
  }
  .nf-dev-linux:before {
    content: "\e712";
  }
  .nf-dev-magento:before {
    content: "\e740";
  }
  .nf-dev-mailchimp:before {
    content: "\e79a";
  }
  .nf-dev-markdown:before {
    content: "\e73e";
  }
  .nf-dev-materializecss:before {
    content: "\e7b6";
  }
  .nf-dev-meteor:before {
    content: "\e7a5";
  }
  .nf-dev-meteorfull:before {
    content: "\e7a6";
  }
  .nf-dev-mitlicence:before {
    content: "\e78b";
  }
  .nf-dev-modernizr:before {
    content: "\e720";
  }
  .nf-dev-mongodb:before {
    content: "\e7a4";
  }
  .nf-dev-mootools:before {
    content: "\e790";
  }
  .nf-dev-mootools_badge:before {
    content: "\e78f";
  }
  .nf-dev-mozilla:before {
    content: "\e786";
  }
  .nf-dev-msql_server:before {
    content: "\e77c";
  }
  .nf-dev-mysql:before {
    content: "\e704";
  }
  .nf-dev-nancy:before {
    content: "\e766";
  }
  .nf-dev-netbeans:before {
    content: "\e79b";
  }
  .nf-dev-netmagazine:before {
    content: "\e72e";
  }
  .nf-dev-nginx:before {
    content: "\e776";
  }
  .nf-dev-nodejs:before {
    content: "\e719";
  }
  .nf-dev-nodejs_small:before {
    content: "\e718";
  }
  .nf-dev-npm:before {
    content: "\e71e";
  }
  .nf-dev-onedrive:before {
    content: "\e762";
  }
  .nf-dev-openshift:before {
    content: "\e7b7";
  }
  .nf-dev-opensource:before {
    content: "\e771";
  }
  .nf-dev-opera:before {
    content: "\e746";
  }
  .nf-dev-perl:before {
    content: "\e769";
  }
  .nf-dev-phonegap:before {
    content: "\e730";
  }
  .nf-dev-photoshop:before {
    content: "\e7b8";
  }
  .nf-dev-php:before {
    content: "\e73d";
  }
  .nf-dev-postgresql:before {
    content: "\e76e";
  }
  .nf-dev-prolog:before {
    content: "\e7a1";
  }
  .nf-dev-python:before {
    content: "\e73c";
  }
  .nf-dev-rackspace:before {
    content: "\e7b9";
  }
  .nf-dev-raphael:before {
    content: "\e75f";
  }
  .nf-dev-rasberry_pi:before {
    content: "\e722";
  }
  .nf-dev-react:before {
    content: "\e7ba";
  }
  .nf-dev-redhat:before {
    content: "\e7bb";
  }
  .nf-dev-redis:before {
    content: "\e76d";
  }
  .nf-dev-requirejs:before {
    content: "\e770";
  }
  .nf-dev-responsive:before {
    content: "\e797";
  }
  .nf-dev-ruby:before {
    content: "\e739";
  }
  .nf-dev-ruby_on_rails:before {
    content: "\e73b";
  }
  .nf-dev-ruby_rough:before {
    content: "\e791";
  }
  .nf-dev-rust:before {
    content: "\e7a8";
  }
  .nf-dev-safari:before {
    content: "\e748";
  }
  .nf-dev-sass:before {
    content: "\e74b";
  }
  .nf-dev-scala:before {
    content: "\e737";
  }
  .nf-dev-scriptcs:before {
    content: "\e7bc";
  }
  .nf-dev-scrum:before {
    content: "\e7a0";
  }
  .nf-dev-senchatouch:before {
    content: "\e78c";
  }
  .nf-dev-sizzlejs:before {
    content: "\e788";
  }
  .nf-dev-smashing_magazine:before {
    content: "\e72d";
  }
  .nf-dev-snap_svg:before {
    content: "\e75e";
  }
  .nf-dev-sqllite:before {
    content: "\e7c4";
  }
  .nf-dev-stackoverflow:before {
    content: "\e710";
  }
  .nf-dev-streamline:before {
    content: "\e705";
  }
  .nf-dev-stylus:before {
    content: "\e759";
  }
  .nf-dev-sublime:before {
    content: "\e7aa";
  }
  .nf-dev-swift:before {
    content: "\e755";
  }
  .nf-dev-symfony:before {
    content: "\e756";
  }
  .nf-dev-symfony_badge:before {
    content: "\e757";
  }
  .nf-dev-techcrunch:before {
    content: "\e72c";
  }
  .nf-dev-terminal:before {
    content: "\e795";
  }
  .nf-dev-terminal_badge:before {
    content: "\e7a2";
  }
  .nf-dev-travis:before {
    content: "\e77e";
  }
  .nf-dev-trello:before {
    content: "\e75a";
  }
  .nf-dev-typo3:before {
    content: "\e772";
  }
  .nf-dev-ubuntu:before {
    content: "\e73a";
  }
  .nf-dev-uikit:before {
    content: "\e773";
  }
  .nf-dev-unity_small:before {
    content: "\e721";
  }
  .nf-dev-vim:before {
    content: "\e7c5";
  }
  .nf-dev-visualstudio:before {
    content: "\e70c";
  }
  .nf-dev-w3c:before {
    content: "\e76c";
  }
  .nf-dev-webplatform:before {
    content: "\e76f";
  }
  .nf-dev-windows:before {
    content: "\e70f";
  }
  .nf-dev-wordpress:before {
    content: "\e70b";
  }
  .nf-dev-yahoo:before {
    content: "\e715";
  }
  .nf-dev-yahoo_small:before {
    content: "\e72b";
  }
  .nf-dev-yeoman:before {
    content: "\e77a";
  }
  .nf-dev-yii:before {
    content: "\e782";
  }
  .nf-dev-zend:before {
    content: "\e778";
  }
  .nf-fa-500px:before {
    content: "\f26e";
  }
  .nf-fa-address_book:before {
    content: "\f2b9";
  }
  .nf-fa-address_book_o:before {
    content: "\f2ba";
  }
  .nf-fa-address_card:before {
    content: "\f2bb";
  }
  .nf-fa-address_card_o:before {
    content: "\f2bc";
  }
  .nf-fa-adjust:before {
    content: "\f042";
  }
  .nf-fa-adn:before {
    content: "\f170";
  }
  .nf-fa-align_center:before {
    content: "\f037";
  }
  .nf-fa-align_justify:before {
    content: "\f039";
  }
  .nf-fa-align_left:before {
    content: "\f036";
  }
  .nf-fa-align_right:before {
    content: "\f038";
  }
  .nf-fa-amazon:before {
    content: "\f270";
  }
  .nf-fa-ambulance:before {
    content: "\f0f9";
  }
  .nf-fa-american_sign_language_interpreting:before {
    content: "\f2a3";
  }
  .nf-fa-anchor:before {
    content: "\f13d";
  }
  .nf-fa-android:before {
    content: "\f17b";
  }
  .nf-fa-angellist:before {
    content: "\f209";
  }
  .nf-fa-angle_double_down:before {
    content: "\f103";
  }
  .nf-fa-angle_double_left:before {
    content: "\f100";
  }
  .nf-fa-angle_double_right:before {
    content: "\f101";
  }
  .nf-fa-angle_double_up:before {
    content: "\f102";
  }
  .nf-fa-angle_down:before {
    content: "\f107";
  }
  .nf-fa-angle_left:before {
    content: "\f104";
  }
  .nf-fa-angle_right:before {
    content: "\f105";
  }
  .nf-fa-angle_up:before {
    content: "\f106";
  }
  .nf-fa-apple:before {
    content: "\f179";
  }
  .nf-fa-archive:before {
    content: "\f187";
  }
  .nf-fa-area_chart:before {
    content: "\f1fe";
  }
  .nf-fa-arrow_circle_down:before {
    content: "\f0ab";
  }
  .nf-fa-arrow_circle_left:before {
    content: "\f0a8";
  }
  .nf-fa-arrow_circle_o_down:before {
    content: "\f01a";
  }
  .nf-fa-arrow_circle_o_left:before {
    content: "\f190";
  }
  .nf-fa-arrow_circle_o_right:before {
    content: "\f18e";
  }
  .nf-fa-arrow_circle_o_up:before {
    content: "\f01b";
  }
  .nf-fa-arrow_circle_right:before {
    content: "\f0a9";
  }
  .nf-fa-arrow_circle_up:before {
    content: "\f0aa";
  }
  .nf-fa-arrow_down:before {
    content: "\f063";
  }
  .nf-fa-arrow_left:before {
    content: "\f060";
  }
  .nf-fa-arrow_right:before {
    content: "\f061";
  }
  .nf-fa-arrow_up:before {
    content: "\f062";
  }
  .nf-fa-arrows:before {
    content: "\f047";
  }
  .nf-fa-arrows_alt:before {
    content: "\f0b2";
  }
  .nf-fa-arrows_h:before {
    content: "\f07e";
  }
  .nf-fa-arrows_v:before {
    content: "\f07d";
  }
  .nf-fa-asl_interpreting:before {
    content: "\f2a3";
  }
  .nf-fa-assistive_listening_systems:before {
    content: "\f2a2";
  }
  .nf-fa-asterisk:before {
    content: "\f069";
  }
  .nf-fa-at:before {
    content: "\f1fa";
  }
  .nf-fa-audio_description:before {
    content: "\f29e";
  }
  .nf-fa-automobile:before {
    content: "\f1b9";
  }
  .nf-fa-backward:before {
    content: "\f04a";
  }
  .nf-fa-balance_scale:before {
    content: "\f24e";
  }
  .nf-fa-ban:before {
    content: "\f05e";
  }
  .nf-fa-bandcamp:before {
    content: "\f2d5";
  }
  .nf-fa-bank:before {
    content: "\f19c";
  }
  .nf-fa-bar_chart:before {
    content: "\f080";
  }
  .nf-fa-bar_chart_o:before {
    content: "\f080";
  }
  .nf-fa-barcode:before {
    content: "\f02a";
  }
  .nf-fa-bars:before {
    content: "\f0c9";
  }
  .nf-fa-bath:before {
    content: "\f2cd";
  }
  .nf-fa-bathtub:before {
    content: "\f2cd";
  }
  .nf-fa-battery:before {
    content: "\f240";
  }
  .nf-fa-battery_0:before {
    content: "\f244";
  }
  .nf-fa-battery_1:before {
    content: "\f243";
  }
  .nf-fa-battery_2:before {
    content: "\f242";
  }
  .nf-fa-battery_3:before {
    content: "\f241";
  }
  .nf-fa-battery_4:before {
    content: "\f240";
  }
  .nf-fa-battery_empty:before {
    content: "\f244";
  }
  .nf-fa-battery_full:before {
    content: "\f240";
  }
  .nf-fa-battery_half:before {
    content: "\f242";
  }
  .nf-fa-battery_quarter:before {
    content: "\f243";
  }
  .nf-fa-battery_three_quarters:before {
    content: "\f241";
  }
  .nf-fa-bed:before {
    content: "\f236";
  }
  .nf-fa-beer:before {
    content: "\f0fc";
  }
  .nf-fa-behance:before {
    content: "\f1b4";
  }
  .nf-fa-behance_square:before {
    content: "\f1b5";
  }
  .nf-fa-bell:before {
    content: "\f0f3";
  }
  .nf-fa-bell_o:before {
    content: "\f0a2";
  }
  .nf-fa-bell_slash:before {
    content: "\f1f6";
  }
  .nf-fa-bell_slash_o:before {
    content: "\f1f7";
  }
  .nf-fa-bicycle:before {
    content: "\f206";
  }
  .nf-fa-binoculars:before {
    content: "\f1e5";
  }
  .nf-fa-birthday_cake:before {
    content: "\f1fd";
  }
  .nf-fa-bitbucket:before {
    content: "\f171";
  }
  .nf-fa-bitbucket_square:before {
    content: "\f172";
  }
  .nf-fa-bitcoin:before {
    content: "\f15a";
  }
  .nf-fa-black_tie:before {
    content: "\f27e";
  }
  .nf-fa-blind:before {
    content: "\f29d";
  }
  .nf-fa-bluetooth:before {
    content: "\f293";
  }
  .nf-fa-bluetooth_b:before {
    content: "\f294";
  }
  .nf-fa-bold:before {
    content: "\f032";
  }
  .nf-fa-bolt:before {
    content: "\f0e7";
  }
  .nf-fa-bomb:before {
    content: "\f1e2";
  }
  .nf-fa-book:before {
    content: "\f02d";
  }
  .nf-fa-bookmark:before {
    content: "\f02e";
  }
  .nf-fa-bookmark_o:before {
    content: "\f097";
  }
  .nf-fa-braille:before {
    content: "\f2a1";
  }
  .nf-fa-briefcase:before {
    content: "\f0b1";
  }
  .nf-fa-btc:before {
    content: "\f15a";
  }
  .nf-fa-bug:before {
    content: "\f188";
  }
  .nf-fa-building:before {
    content: "\f1ad";
  }
  .nf-fa-building_o:before {
    content: "\f0f7";
  }
  .nf-fa-bullhorn:before {
    content: "\f0a1";
  }
  .nf-fa-bullseye:before {
    content: "\f140";
  }
  .nf-fa-bus:before {
    content: "\f207";
  }
  .nf-fa-buysellads:before {
    content: "\f20d";
  }
  .nf-fa-cab:before {
    content: "\f1ba";
  }
  .nf-fa-calculator:before {
    content: "\f1ec";
  }
  .nf-fa-calendar:before {
    content: "\f073";
  }
  .nf-fa-calendar_check_o:before {
    content: "\f274";
  }
  .nf-fa-calendar_minus_o:before {
    content: "\f272";
  }
  .nf-fa-calendar_o:before {
    content: "\f133";
  }
  .nf-fa-calendar_plus_o:before {
    content: "\f271";
  }
  .nf-fa-calendar_times_o:before {
    content: "\f273";
  }
  .nf-fa-camera:before {
    content: "\f030";
  }
  .nf-fa-camera_retro:before {
    content: "\f083";
  }
  .nf-fa-car:before {
    content: "\f1b9";
  }
  .nf-fa-caret_down:before {
    content: "\f0d7";
  }
  .nf-fa-caret_left:before {
    content: "\f0d9";
  }
  .nf-fa-caret_right:before {
    content: "\f0da";
  }
  .nf-fa-caret_square_o_down:before {
    content: "\f150";
  }
  .nf-fa-caret_square_o_left:before {
    content: "\f191";
  }
  .nf-fa-caret_square_o_right:before {
    content: "\f152";
  }
  .nf-fa-caret_square_o_up:before {
    content: "\f151";
  }
  .nf-fa-caret_up:before {
    content: "\f0d8";
  }
  .nf-fa-cart_arrow_down:before {
    content: "\f218";
  }
  .nf-fa-cart_plus:before {
    content: "\f217";
  }
  .nf-fa-cc:before {
    content: "\f20a";
  }
  .nf-fa-cc_amex:before {
    content: "\f1f3";
  }
  .nf-fa-cc_diners_club:before {
    content: "\f24c";
  }
  .nf-fa-cc_discover:before {
    content: "\f1f2";
  }
  .nf-fa-cc_jcb:before {
    content: "\f24b";
  }
  .nf-fa-cc_mastercard:before {
    content: "\f1f1";
  }
  .nf-fa-cc_paypal:before {
    content: "\f1f4";
  }
  .nf-fa-cc_stripe:before {
    content: "\f1f5";
  }
  .nf-fa-cc_visa:before {
    content: "\f1f0";
  }
  .nf-fa-certificate:before {
    content: "\f0a3";
  }
  .nf-fa-chain:before {
    content: "\f0c1";
  }
  .nf-fa-chain_broken:before {
    content: "\f127";
  }
  .nf-fa-check:before {
    content: "\f00c";
  }
  .nf-fa-check_circle:before {
    content: "\f058";
  }
  .nf-fa-check_circle_o:before {
    content: "\f05d";
  }
  .nf-fa-check_square:before {
    content: "\f14a";
  }
  .nf-fa-check_square_o:before {
    content: "\f046";
  }
  .nf-fa-chevron_circle_down:before {
    content: "\f13a";
  }
  .nf-fa-chevron_circle_left:before {
    content: "\f137";
  }
  .nf-fa-chevron_circle_right:before {
    content: "\f138";
  }
  .nf-fa-chevron_circle_up:before {
    content: "\f139";
  }
  .nf-fa-chevron_down:before {
    content: "\f078";
  }
  .nf-fa-chevron_left:before {
    content: "\f053";
  }
  .nf-fa-chevron_right:before {
    content: "\f054";
  }
  .nf-fa-chevron_up:before {
    content: "\f077";
  }
  .nf-fa-child:before {
    content: "\f1ae";
  }
  .nf-fa-chrome:before {
    content: "\f268";
  }
  .nf-fa-circle:before {
    content: "\f111";
  }
  .nf-fa-circle_o:before {
    content: "\f10c";
  }
  .nf-fa-circle_o_notch:before {
    content: "\f1ce";
  }
  .nf-fa-circle_thin:before {
    content: "\f1db";
  }
  .nf-fa-clipboard:before {
    content: "\f0ea";
  }
  .nf-fa-clock_o:before {
    content: "\f017";
  }
  .nf-fa-clone:before {
    content: "\f24d";
  }
  .nf-fa-close:before {
    content: "\f00d";
  }
  .nf-fa-cloud:before {
    content: "\f0c2";
  }
  .nf-fa-cloud_download:before {
    content: "\f0ed";
  }
  .nf-fa-cloud_upload:before {
    content: "\f0ee";
  }
  .nf-fa-cny:before {
    content: "\f157";
  }
  .nf-fa-code:before {
    content: "\f121";
  }
  .nf-fa-code_fork:before {
    content: "\f126";
  }
  .nf-fa-codepen:before {
    content: "\f1cb";
  }
  .nf-fa-codiepie:before {
    content: "\f284";
  }
  .nf-fa-coffee:before {
    content: "\f0f4";
  }
  .nf-fa-cog:before {
    content: "\f013";
  }
  .nf-fa-cogs:before {
    content: "\f085";
  }
  .nf-fa-columns:before {
    content: "\f0db";
  }
  .nf-fa-comment:before {
    content: "\f075";
  }
  .nf-fa-comment_o:before {
    content: "\f0e5";
  }
  .nf-fa-commenting:before {
    content: "\f27a";
  }
  .nf-fa-commenting_o:before {
    content: "\f27b";
  }
  .nf-fa-comments:before {
    content: "\f086";
  }
  .nf-fa-comments_o:before {
    content: "\f0e6";
  }
  .nf-fa-compass:before {
    content: "\f14e";
  }
  .nf-fa-compress:before {
    content: "\f066";
  }
  .nf-fa-connectdevelop:before {
    content: "\f20e";
  }
  .nf-fa-contao:before {
    content: "\f26d";
  }
  .nf-fa-copy:before {
    content: "\f0c5";
  }
  .nf-fa-copyright:before {
    content: "\f1f9";
  }
  .nf-fa-creative_commons:before {
    content: "\f25e";
  }
  .nf-fa-credit_card:before {
    content: "\f09d";
  }
  .nf-fa-credit_card_alt:before {
    content: "\f283";
  }
  .nf-fa-crop:before {
    content: "\f125";
  }
  .nf-fa-crosshairs:before {
    content: "\f05b";
  }
  .nf-fa-css3:before {
    content: "\f13c";
  }
  .nf-fa-cube:before {
    content: "\f1b2";
  }
  .nf-fa-cubes:before {
    content: "\f1b3";
  }
  .nf-fa-cut:before {
    content: "\f0c4";
  }
  .nf-fa-cutlery:before {
    content: "\f0f5";
  }
  .nf-fa-dashboard:before {
    content: "\f0e4";
  }
  .nf-fa-dashcube:before {
    content: "\f210";
  }
  .nf-fa-database:before {
    content: "\f1c0";
  }
  .nf-fa-deaf:before {
    content: "\f2a4";
  }
  .nf-fa-deafness:before {
    content: "\f2a4";
  }
  .nf-fa-dedent:before {
    content: "\f03b";
  }
  .nf-fa-delicious:before {
    content: "\f1a5";
  }
  .nf-fa-desktop:before {
    content: "\f108";
  }
  .nf-fa-deviantart:before {
    content: "\f1bd";
  }
  .nf-fa-diamond:before {
    content: "\f219";
  }
  .nf-fa-digg:before {
    content: "\f1a6";
  }
  .nf-fa-dollar:before {
    content: "\f155";
  }
  .nf-fa-dot_circle_o:before {
    content: "\f192";
  }
  .nf-fa-download:before {
    content: "\f019";
  }
  .nf-fa-dribbble:before {
    content: "\f17d";
  }
  .nf-fa-drivers_license:before {
    content: "\f2c2";
  }
  .nf-fa-drivers_license_o:before {
    content: "\f2c3";
  }
  .nf-fa-dropbox:before {
    content: "\f16b";
  }
  .nf-fa-drupal:before {
    content: "\f1a9";
  }
  .nf-fa-edge:before {
    content: "\f282";
  }
  .nf-fa-edit:before {
    content: "\f044";
  }
  .nf-fa-eercast:before {
    content: "\f2da";
  }
  .nf-fa-eject:before {
    content: "\f052";
  }
  .nf-fa-ellipsis_h:before {
    content: "\f141";
  }
  .nf-fa-ellipsis_v:before {
    content: "\f142";
  }
  .nf-fa-empire:before {
    content: "\f1d1";
  }
  .nf-fa-envelope:before {
    content: "\f0e0";
  }
  .nf-fa-envelope_o:before {
    content: "\f003";
  }
  .nf-fa-envelope_open:before {
    content: "\f2b6";
  }
  .nf-fa-envelope_open_o:before {
    content: "\f2b7";
  }
  .nf-fa-envelope_square:before {
    content: "\f199";
  }
  .nf-fa-envira:before {
    content: "\f299";
  }
  .nf-fa-eraser:before {
    content: "\f12d";
  }
  .nf-fa-etsy:before {
    content: "\f2d7";
  }
  .nf-fa-eur:before {
    content: "\f153";
  }
  .nf-fa-euro:before {
    content: "\f153";
  }
  .nf-fa-exchange:before {
    content: "\f0ec";
  }
  .nf-fa-exclamation:before {
    content: "\f12a";
  }
  .nf-fa-exclamation_circle:before {
    content: "\f06a";
  }
  .nf-fa-exclamation_triangle:before {
    content: "\f071";
  }
  .nf-fa-expand:before {
    content: "\f065";
  }
  .nf-fa-expeditedssl:before {
    content: "\f23e";
  }
  .nf-fa-external_link:before {
    content: "\f08e";
  }
  .nf-fa-external_link_square:before {
    content: "\f14c";
  }
  .nf-fa-eye:before {
    content: "\f06e";
  }
  .nf-fa-eye_slash:before {
    content: "\f070";
  }
  .nf-fa-eyedropper:before {
    content: "\f1fb";
  }
  .nf-fa-fa:before {
    content: "\f2b4";
  }
  .nf-fa-facebook:before {
    content: "\f09a";
  }
  .nf-fa-facebook_f:before {
    content: "\f09a";
  }
  .nf-fa-facebook_official:before {
    content: "\f230";
  }
  .nf-fa-facebook_square:before {
    content: "\f082";
  }
  .nf-fa-fast_backward:before {
    content: "\f049";
  }
  .nf-fa-fast_forward:before {
    content: "\f050";
  }
  .nf-fa-fax:before {
    content: "\f1ac";
  }
  .nf-fa-feed:before {
    content: "\f09e";
  }
  .nf-fa-female:before {
    content: "\f182";
  }
  .nf-fa-fighter_jet:before {
    content: "\f0fb";
  }
  .nf-fa-file:before {
    content: "\f15b";
  }
  .nf-fa-file_archive_o:before {
    content: "\f1c6";
  }
  .nf-fa-file_audio_o:before {
    content: "\f1c7";
  }
  .nf-fa-file_code_o:before {
    content: "\f1c9";
  }
  .nf-fa-file_excel_o:before {
    content: "\f1c3";
  }
  .nf-fa-file_image_o:before {
    content: "\f1c5";
  }
  .nf-fa-file_movie_o:before {
    content: "\f1c8";
  }
  .nf-fa-file_o:before {
    content: "\f016";
  }
  .nf-fa-file_pdf_o:before {
    content: "\f1c1";
  }
  .nf-fa-file_photo_o:before {
    content: "\f1c5";
  }
  .nf-fa-file_picture_o:before {
    content: "\f1c5";
  }
  .nf-fa-file_powerpoint_o:before {
    content: "\f1c4";
  }
  .nf-fa-file_sound_o:before {
    content: "\f1c7";
  }
  .nf-fa-file_text:before {
    content: "\f15c";
  }
  .nf-fa-file_text_o:before {
    content: "\f0f6";
  }
  .nf-fa-file_video_o:before {
    content: "\f1c8";
  }
  .nf-fa-file_word_o:before {
    content: "\f1c2";
  }
  .nf-fa-file_zip_o:before {
    content: "\f1c6";
  }
  .nf-fa-files_o:before {
    content: "\f0c5";
  }
  .nf-fa-film:before {
    content: "\f008";
  }
  .nf-fa-filter:before {
    content: "\f0b0";
  }
  .nf-fa-fire:before {
    content: "\f06d";
  }
  .nf-fa-fire_extinguisher:before {
    content: "\f134";
  }
  .nf-fa-firefox:before {
    content: "\f269";
  }
  .nf-fa-first_order:before {
    content: "\f2b0";
  }
  .nf-fa-flag:before {
    content: "\f024";
  }
  .nf-fa-flag_checkered:before {
    content: "\f11e";
  }
  .nf-fa-flag_o:before {
    content: "\f11d";
  }
  .nf-fa-flash:before {
    content: "\f0e7";
  }
  .nf-fa-flask:before {
    content: "\f0c3";
  }
  .nf-fa-flickr:before {
    content: "\f16e";
  }
  .nf-fa-floppy_o:before {
    content: "\f0c7";
  }
  .nf-fa-folder:before {
    content: "\f07b";
  }
  .nf-fa-folder_o:before {
    content: "\f114";
  }
  .nf-fa-folder_open:before {
    content: "\f07c";
  }
  .nf-fa-folder_open_o:before {
    content: "\f115";
  }
  .nf-fa-font:before {
    content: "\f031";
  }
  .nf-fa-font_awesome:before {
    content: "\f2b4";
  }
  .nf-fa-fonticons:before {
    content: "\f280";
  }
  .nf-fa-fort_awesome:before {
    content: "\f286";
  }
  .nf-fa-forumbee:before {
    content: "\f211";
  }
  .nf-fa-forward:before {
    content: "\f04e";
  }
  .nf-fa-foursquare:before {
    content: "\f180";
  }
  .nf-fa-free_code_camp:before {
    content: "\f2c5";
  }
  .nf-fa-frown_o:before {
    content: "\f119";
  }
  .nf-fa-futbol_o:before {
    content: "\f1e3";
  }
  .nf-fa-gamepad:before {
    content: "\f11b";
  }
  .nf-fa-gavel:before {
    content: "\f0e3";
  }
  .nf-fa-gbp:before {
    content: "\f154";
  }
  .nf-fa-ge:before {
    content: "\f1d1";
  }
  .nf-fa-gear:before {
    content: "\f013";
  }
  .nf-fa-gears:before {
    content: "\f085";
  }
  .nf-fa-genderless:before {
    content: "\f22d";
  }
  .nf-fa-get_pocket:before {
    content: "\f265";
  }
  .nf-fa-gg:before {
    content: "\f260";
  }
  .nf-fa-gg_circle:before {
    content: "\f261";
  }
  .nf-fa-gift:before {
    content: "\f06b";
  }
  .nf-fa-git:before {
    content: "\f1d3";
  }
  .nf-fa-git_square:before {
    content: "\f1d2";
  }
  .nf-fa-github:before {
    content: "\f09b";
  }
  .nf-fa-github_alt:before {
    content: "\f113";
  }
  .nf-fa-github_square:before {
    content: "\f092";
  }
  .nf-fa-gitlab:before {
    content: "\f296";
  }
  .nf-fa-gittip:before {
    content: "\f184";
  }
  .nf-fa-glass:before {
    content: "\f000";
  }
  .nf-fa-glide:before {
    content: "\f2a5";
  }
  .nf-fa-glide_g:before {
    content: "\f2a6";
  }
  .nf-fa-globe:before {
    content: "\f0ac";
  }
  .nf-fa-google:before {
    content: "\f1a0";
  }
  .nf-fa-google_plus:before {
    content: "\f0d5";
  }
  .nf-fa-google_plus_circle:before {
    content: "\f2b3";
  }
  .nf-fa-google_plus_official:before {
    content: "\f2b3";
  }
  .nf-fa-google_plus_square:before {
    content: "\f0d4";
  }
  .nf-fa-google_wallet:before {
    content: "\f1ee";
  }
  .nf-fa-graduation_cap:before {
    content: "\f19d";
  }
  .nf-fa-gratipay:before {
    content: "\f184";
  }
  .nf-fa-grav:before {
    content: "\f2d6";
  }
  .nf-fa-group:before {
    content: "\f0c0";
  }
  .nf-fa-h_square:before {
    content: "\f0fd";
  }
  .nf-fa-hacker_news:before {
    content: "\f1d4";
  }
  .nf-fa-hand_grab_o:before {
    content: "\f255";
  }
  .nf-fa-hand_lizard_o:before {
    content: "\f258";
  }
  .nf-fa-hand_o_down:before {
    content: "\f0a7";
  }
  .nf-fa-hand_o_left:before {
    content: "\f0a5";
  }
  .nf-fa-hand_o_right:before {
    content: "\f0a4";
  }
  .nf-fa-hand_o_up:before {
    content: "\f0a6";
  }
  .nf-fa-hand_paper_o:before {
    content: "\f256";
  }
  .nf-fa-hand_peace_o:before {
    content: "\f25b";
  }
  .nf-fa-hand_pointer_o:before {
    content: "\f25a";
  }
  .nf-fa-hand_rock_o:before {
    content: "\f255";
  }
  .nf-fa-hand_scissors_o:before {
    content: "\f257";
  }
  .nf-fa-hand_spock_o:before {
    content: "\f259";
  }
  .nf-fa-hand_stop_o:before {
    content: "\f256";
  }
  .nf-fa-handshake_o:before {
    content: "\f2b5";
  }
  .nf-fa-hard_of_hearing:before {
    content: "\f2a4";
  }
  .nf-fa-hashtag:before {
    content: "\f292";
  }
  .nf-fa-hdd_o:before {
    content: "\f0a0";
  }
  .nf-fa-header:before {
    content: "\f1dc";
  }
  .nf-fa-headphones:before {
    content: "\f025";
  }
  .nf-fa-heart:before {
    content: "\f004";
  }
  .nf-fa-heart_o:before {
    content: "\f08a";
  }
  .nf-fa-heartbeat:before {
    content: "\f21e";
  }
  .nf-fa-history:before {
    content: "\f1da";
  }
  .nf-fa-home:before {
    content: "\f015";
  }
  .nf-fa-hospital_o:before {
    content: "\f0f8";
  }
  .nf-fa-hotel:before {
    content: "\f236";
  }
  .nf-fa-hourglass:before {
    content: "\f254";
  }
  .nf-fa-hourglass_1:before {
    content: "\f251";
  }
  .nf-fa-hourglass_2:before {
    content: "\f252";
  }
  .nf-fa-hourglass_3:before {
    content: "\f253";
  }
  .nf-fa-hourglass_end:before {
    content: "\f253";
  }
  .nf-fa-hourglass_half:before {
    content: "\f252";
  }
  .nf-fa-hourglass_o:before {
    content: "\f250";
  }
  .nf-fa-hourglass_start:before {
    content: "\f251";
  }
  .nf-fa-houzz:before {
    content: "\f27c";
  }
  .nf-fa-html5:before {
    content: "\f13b";
  }
  .nf-fa-i_cursor:before {
    content: "\f246";
  }
  .nf-fa-id_badge:before {
    content: "\f2c1";
  }
  .nf-fa-id_card:before {
    content: "\f2c2";
  }
  .nf-fa-id_card_o:before {
    content: "\f2c3";
  }
  .nf-fa-ils:before {
    content: "\f20b";
  }
  .nf-fa-image:before {
    content: "\f03e";
  }
  .nf-fa-imdb:before {
    content: "\f2d8";
  }
  .nf-fa-inbox:before {
    content: "\f01c";
  }
  .nf-fa-indent:before {
    content: "\f03c";
  }
  .nf-fa-industry:before {
    content: "\f275";
  }
  .nf-fa-info:before {
    content: "\f129";
  }
  .nf-fa-info_circle:before {
    content: "\f05a";
  }
  .nf-fa-inr:before {
    content: "\f156";
  }
  .nf-fa-instagram:before {
    content: "\f16d";
  }
  .nf-fa-institution:before {
    content: "\f19c";
  }
  .nf-fa-internet_explorer:before {
    content: "\f26b";
  }
  .nf-fa-intersex:before {
    content: "\f224";
  }
  .nf-fa-ioxhost:before {
    content: "\f208";
  }
  .nf-fa-italic:before {
    content: "\f033";
  }
  .nf-fa-joomla:before {
    content: "\f1aa";
  }
  .nf-fa-jpy:before {
    content: "\f157";
  }
  .nf-fa-jsfiddle:before {
    content: "\f1cc";
  }
  .nf-fa-key:before {
    content: "\f084";
  }
  .nf-fa-keyboard_o:before {
    content: "\f11c";
  }
  .nf-fa-krw:before {
    content: "\f159";
  }
  .nf-fa-language:before {
    content: "\f1ab";
  }
  .nf-fa-laptop:before {
    content: "\f109";
  }
  .nf-fa-lastfm:before {
    content: "\f202";
  }
  .nf-fa-lastfm_square:before {
    content: "\f203";
  }
  .nf-fa-leaf:before {
    content: "\f06c";
  }
  .nf-fa-leanpub:before {
    content: "\f212";
  }
  .nf-fa-legal:before {
    content: "\f0e3";
  }
  .nf-fa-lemon_o:before {
    content: "\f094";
  }
  .nf-fa-level_down:before {
    content: "\f149";
  }
  .nf-fa-level_up:before {
    content: "\f148";
  }
  .nf-fa-life_bouy:before {
    content: "\f1cd";
  }
  .nf-fa-life_buoy:before {
    content: "\f1cd";
  }
  .nf-fa-life_ring:before {
    content: "\f1cd";
  }
  .nf-fa-life_saver:before {
    content: "\f1cd";
  }
  .nf-fa-lightbulb_o:before {
    content: "\f0eb";
  }
  .nf-fa-line_chart:before {
    content: "\f201";
  }
  .nf-fa-link:before {
    content: "\f0c1";
  }
  .nf-fa-linkedin:before {
    content: "\f0e1";
  }
  .nf-fa-linkedin_square:before {
    content: "\f08c";
  }
  .nf-fa-linode:before {
    content: "\f2b8";
  }
  .nf-fa-linux:before {
    content: "\f17c";
  }
  .nf-fa-list:before {
    content: "\f03a";
  }
  .nf-fa-list_alt:before {
    content: "\f022";
  }
  .nf-fa-list_ol:before {
    content: "\f0cb";
  }
  .nf-fa-list_ul:before {
    content: "\f0ca";
  }
  .nf-fa-location_arrow:before {
    content: "\f124";
  }
  .nf-fa-lock:before {
    content: "\f023";
  }
  .nf-fa-long_arrow_down:before {
    content: "\f175";
  }
  .nf-fa-long_arrow_left:before {
    content: "\f177";
  }
  .nf-fa-long_arrow_right:before {
    content: "\f178";
  }
  .nf-fa-long_arrow_up:before {
    content: "\f176";
  }
  .nf-fa-low_vision:before {
    content: "\f2a8";
  }
  .nf-fa-magic:before {
    content: "\f0d0";
  }
  .nf-fa-magnet:before {
    content: "\f076";
  }
  .nf-fa-mail_forward:before {
    content: "\f064";
  }
  .nf-fa-mail_reply:before {
    content: "\f112";
  }
  .nf-fa-mail_reply_all:before {
    content: "\f122";
  }
  .nf-fa-male:before {
    content: "\f183";
  }
  .nf-fa-map:before {
    content: "\f279";
  }
  .nf-fa-map_marker:before {
    content: "\f041";
  }
  .nf-fa-map_o:before {
    content: "\f278";
  }
  .nf-fa-map_pin:before {
    content: "\f276";
  }
  .nf-fa-map_signs:before {
    content: "\f277";
  }
  .nf-fa-mars:before {
    content: "\f222";
  }
  .nf-fa-mars_double:before {
    content: "\f227";
  }
  .nf-fa-mars_stroke:before {
    content: "\f229";
  }
  .nf-fa-mars_stroke_h:before {
    content: "\f22b";
  }
  .nf-fa-mars_stroke_v:before {
    content: "\f22a";
  }
  .nf-fa-maxcdn:before {
    content: "\f136";
  }
  .nf-fa-meanpath:before {
    content: "\f20c";
  }
  .nf-fa-medium:before {
    content: "\f23a";
  }
  .nf-fa-medkit:before {
    content: "\f0fa";
  }
  .nf-fa-meetup:before {
    content: "\f2e0";
  }
  .nf-fa-meh_o:before {
    content: "\f11a";
  }
  .nf-fa-mercury:before {
    content: "\f223";
  }
  .nf-fa-microchip:before {
    content: "\f2db";
  }
  .nf-fa-microphone:before {
    content: "\f130";
  }
  .nf-fa-microphone_slash:before {
    content: "\f131";
  }
  .nf-fa-minus:before {
    content: "\f068";
  }
  .nf-fa-minus_circle:before {
    content: "\f056";
  }
  .nf-fa-minus_square:before {
    content: "\f146";
  }
  .nf-fa-minus_square_o:before {
    content: "\f147";
  }
  .nf-fa-mixcloud:before {
    content: "\f289";
  }
  .nf-fa-mobile:before {
    content: "\f10b";
  }
  .nf-fa-mobile_phone:before {
    content: "\f10b";
  }
  .nf-fa-modx:before {
    content: "\f285";
  }
  .nf-fa-money:before {
    content: "\f0d6";
  }
  .nf-fa-moon_o:before {
    content: "\f186";
  }
  .nf-fa-mortar_board:before {
    content: "\f19d";
  }
  .nf-fa-motorcycle:before {
    content: "\f21c";
  }
  .nf-fa-mouse_pointer:before {
    content: "\f245";
  }
  .nf-fa-music:before {
    content: "\f001";
  }
  .nf-fa-navicon:before {
    content: "\f0c9";
  }
  .nf-fa-neuter:before {
    content: "\f22c";
  }
  .nf-fa-newspaper_o:before {
    content: "\f1ea";
  }
  .nf-fa-object_group:before {
    content: "\f247";
  }
  .nf-fa-object_ungroup:before {
    content: "\f248";
  }
  .nf-fa-odnoklassniki:before {
    content: "\f263";
  }
  .nf-fa-odnoklassniki_square:before {
    content: "\f264";
  }
  .nf-fa-opencart:before {
    content: "\f23d";
  }
  .nf-fa-openid:before {
    content: "\f19b";
  }
  .nf-fa-opera:before {
    content: "\f26a";
  }
  .nf-fa-optin_monster:before {
    content: "\f23c";
  }
  .nf-fa-outdent:before {
    content: "\f03b";
  }
  .nf-fa-pagelines:before {
    content: "\f18c";
  }
  .nf-fa-paint_brush:before {
    content: "\f1fc";
  }
  .nf-fa-paper_plane:before {
    content: "\f1d8";
  }
  .nf-fa-paper_plane_o:before {
    content: "\f1d9";
  }
  .nf-fa-paperclip:before {
    content: "\f0c6";
  }
  .nf-fa-paragraph:before {
    content: "\f1dd";
  }
  .nf-fa-paste:before {
    content: "\f0ea";
  }
  .nf-fa-pause:before {
    content: "\f04c";
  }
  .nf-fa-pause_circle:before {
    content: "\f28b";
  }
  .nf-fa-pause_circle_o:before {
    content: "\f28c";
  }
  .nf-fa-paw:before {
    content: "\f1b0";
  }
  .nf-fa-paypal:before {
    content: "\f1ed";
  }
  .nf-fa-pencil:before {
    content: "\f040";
  }
  .nf-fa-pencil_square:before {
    content: "\f14b";
  }
  .nf-fa-pencil_square_o:before {
    content: "\f044";
  }
  .nf-fa-percent:before {
    content: "\f295";
  }
  .nf-fa-phone:before {
    content: "\f095";
  }
  .nf-fa-phone_square:before {
    content: "\f098";
  }
  .nf-fa-photo:before {
    content: "\f03e";
  }
  .nf-fa-picture_o:before {
    content: "\f03e";
  }
  .nf-fa-pie_chart:before {
    content: "\f200";
  }
  .nf-fa-pied_piper:before {
    content: "\f2ae";
  }
  .nf-fa-pied_piper_alt:before {
    content: "\f1a8";
  }
  .nf-fa-pied_piper_pp:before {
    content: "\f1a7";
  }
  .nf-fa-pinterest:before {
    content: "\f0d2";
  }
  .nf-fa-pinterest_p:before {
    content: "\f231";
  }
  .nf-fa-pinterest_square:before {
    content: "\f0d3";
  }
  .nf-fa-plane:before {
    content: "\f072";
  }
  .nf-fa-play:before {
    content: "\f04b";
  }
  .nf-fa-play_circle:before {
    content: "\f144";
  }
  .nf-fa-play_circle_o:before {
    content: "\f01d";
  }
  .nf-fa-plug:before {
    content: "\f1e6";
  }
  .nf-fa-plus:before {
    content: "\f067";
  }
  .nf-fa-plus_circle:before {
    content: "\f055";
  }
  .nf-fa-plus_square:before {
    content: "\f0fe";
  }
  .nf-fa-plus_square_o:before {
    content: "\f196";
  }
  .nf-fa-podcast:before {
    content: "\f2ce";
  }
  .nf-fa-power_off:before {
    content: "\f011";
  }
  .nf-fa-print:before {
    content: "\f02f";
  }
  .nf-fa-product_hunt:before {
    content: "\f288";
  }
  .nf-fa-puzzle_piece:before {
    content: "\f12e";
  }
  .nf-fa-qq:before {
    content: "\f1d6";
  }
  .nf-fa-qrcode:before {
    content: "\f029";
  }
  .nf-fa-question:before {
    content: "\f128";
  }
  .nf-fa-question_circle:before {
    content: "\f059";
  }
  .nf-fa-question_circle_o:before {
    content: "\f29c";
  }
  .nf-fa-quora:before {
    content: "\f2c4";
  }
  .nf-fa-quote_left:before {
    content: "\f10d";
  }
  .nf-fa-quote_right:before {
    content: "\f10e";
  }
  .nf-fa-ra:before {
    content: "\f1d0";
  }
  .nf-fa-random:before {
    content: "\f074";
  }
  .nf-fa-ravelry:before {
    content: "\f2d9";
  }
  .nf-fa-rebel:before {
    content: "\f1d0";
  }
  .nf-fa-recycle:before {
    content: "\f1b8";
  }
  .nf-fa-reddit:before {
    content: "\f1a1";
  }
  .nf-fa-reddit_alien:before {
    content: "\f281";
  }
  .nf-fa-reddit_square:before {
    content: "\f1a2";
  }
  .nf-fa-refresh:before {
    content: "\f021";
  }
  .nf-fa-registered:before {
    content: "\f25d";
  }
  .nf-fa-remove:before {
    content: "\f00d";
  }
  .nf-fa-renren:before {
    content: "\f18b";
  }
  .nf-fa-reorder:before {
    content: "\f0c9";
  }
  .nf-fa-repeat:before {
    content: "\f01e";
  }
  .nf-fa-reply:before {
    content: "\f112";
  }
  .nf-fa-reply_all:before {
    content: "\f122";
  }
  .nf-fa-resistance:before {
    content: "\f1d0";
  }
  .nf-fa-retweet:before {
    content: "\f079";
  }
  .nf-fa-rmb:before {
    content: "\f157";
  }
  .nf-fa-road:before {
    content: "\f018";
  }
  .nf-fa-rocket:before {
    content: "\f135";
  }
  .nf-fa-rotate_left:before {
    content: "\f0e2";
  }
  .nf-fa-rotate_right:before {
    content: "\f01e";
  }
  .nf-fa-rouble:before {
    content: "\f158";
  }
  .nf-fa-rss:before {
    content: "\f09e";
  }
  .nf-fa-rss_square:before {
    content: "\f143";
  }
  .nf-fa-rub:before {
    content: "\f158";
  }
  .nf-fa-ruble:before {
    content: "\f158";
  }
  .nf-fa-rupee:before {
    content: "\f156";
  }
  .nf-fa-s15:before {
    content: "\f2cd";
  }
  .nf-fa-safari:before {
    content: "\f267";
  }
  .nf-fa-save:before {
    content: "\f0c7";
  }
  .nf-fa-scissors:before {
    content: "\f0c4";
  }
  .nf-fa-scribd:before {
    content: "\f28a";
  }
  .nf-fa-search:before {
    content: "\f002";
  }
  .nf-fa-search_minus:before {
    content: "\f010";
  }
  .nf-fa-search_plus:before {
    content: "\f00e";
  }
  .nf-fa-sellsy:before {
    content: "\f213";
  }
  .nf-fa-send:before {
    content: "\f1d8";
  }
  .nf-fa-send_o:before {
    content: "\f1d9";
  }
  .nf-fa-server:before {
    content: "\f233";
  }
  .nf-fa-share:before {
    content: "\f064";
  }
  .nf-fa-share_alt:before {
    content: "\f1e0";
  }
  .nf-fa-share_alt_square:before {
    content: "\f1e1";
  }
  .nf-fa-share_square:before {
    content: "\f14d";
  }
  .nf-fa-share_square_o:before {
    content: "\f045";
  }
  .nf-fa-shekel:before {
    content: "\f20b";
  }
  .nf-fa-sheqel:before {
    content: "\f20b";
  }
  .nf-fa-shield:before {
    content: "\f132";
  }
  .nf-fa-ship:before {
    content: "\f21a";
  }
  .nf-fa-shirtsinbulk:before {
    content: "\f214";
  }
  .nf-fa-shopping_bag:before {
    content: "\f290";
  }
  .nf-fa-shopping_basket:before {
    content: "\f291";
  }
  .nf-fa-shopping_cart:before {
    content: "\f07a";
  }
  .nf-fa-shower:before {
    content: "\f2cc";
  }
  .nf-fa-sign_in:before {
    content: "\f090";
  }
  .nf-fa-sign_language:before {
    content: "\f2a7";
  }
  .nf-fa-sign_out:before {
    content: "\f08b";
  }
  .nf-fa-signal:before {
    content: "\f012";
  }
  .nf-fa-signing:before {
    content: "\f2a7";
  }
  .nf-fa-simplybuilt:before {
    content: "\f215";
  }
  .nf-fa-sitemap:before {
    content: "\f0e8";
  }
  .nf-fa-skyatlas:before {
    content: "\f216";
  }
  .nf-fa-skype:before {
    content: "\f17e";
  }
  .nf-fa-slack:before {
    content: "\f198";
  }
  .nf-fa-sliders:before {
    content: "\f1de";
  }
  .nf-fa-slideshare:before {
    content: "\f1e7";
  }
  .nf-fa-smile_o:before {
    content: "\f118";
  }
  .nf-fa-snapchat:before {
    content: "\f2ab";
  }
  .nf-fa-snapchat_ghost:before {
    content: "\f2ac";
  }
  .nf-fa-snapchat_square:before {
    content: "\f2ad";
  }
  .nf-fa-snowflake_o:before {
    content: "\f2dc";
  }
  .nf-fa-soccer_ball_o:before {
    content: "\f1e3";
  }
  .nf-fa-sort:before {
    content: "\f0dc";
  }
  .nf-fa-sort_alpha_asc:before {
    content: "\f15d";
  }
  .nf-fa-sort_alpha_desc:before {
    content: "\f15e";
  }
  .nf-fa-sort_amount_asc:before {
    content: "\f160";
  }
  .nf-fa-sort_amount_desc:before {
    content: "\f161";
  }
  .nf-fa-sort_asc:before {
    content: "\f0de";
  }
  .nf-fa-sort_desc:before {
    content: "\f0dd";
  }
  .nf-fa-sort_down:before {
    content: "\f0dd";
  }
  .nf-fa-sort_numeric_asc:before {
    content: "\f162";
  }
  .nf-fa-sort_numeric_desc:before {
    content: "\f163";
  }
  .nf-fa-sort_up:before {
    content: "\f0de";
  }
  .nf-fa-soundcloud:before {
    content: "\f1be";
  }
  .nf-fa-space_shuttle:before {
    content: "\f197";
  }
  .nf-fa-spinner:before {
    content: "\f110";
  }
  .nf-fa-spoon:before {
    content: "\f1b1";
  }
  .nf-fa-spotify:before {
    content: "\f1bc";
  }
  .nf-fa-square:before {
    content: "\f0c8";
  }
  .nf-fa-square_o:before {
    content: "\f096";
  }
  .nf-fa-stack_exchange:before {
    content: "\f18d";
  }
  .nf-fa-stack_overflow:before {
    content: "\f16c";
  }
  .nf-fa-star:before {
    content: "\f005";
  }
  .nf-fa-star_half:before {
    content: "\f089";
  }
  .nf-fa-star_half_empty:before {
    content: "\f123";
  }
  .nf-fa-star_half_full:before {
    content: "\f123";
  }
  .nf-fa-star_half_o:before {
    content: "\f123";
  }
  .nf-fa-star_o:before {
    content: "\f006";
  }
  .nf-fa-steam:before {
    content: "\f1b6";
  }
  .nf-fa-steam_square:before {
    content: "\f1b7";
  }
  .nf-fa-step_backward:before {
    content: "\f048";
  }
  .nf-fa-step_forward:before {
    content: "\f051";
  }
  .nf-fa-stethoscope:before {
    content: "\f0f1";
  }
  .nf-fa-sticky_note:before {
    content: "\f249";
  }
  .nf-fa-sticky_note_o:before {
    content: "\f24a";
  }
  .nf-fa-stop:before {
    content: "\f04d";
  }
  .nf-fa-stop_circle:before {
    content: "\f28d";
  }
  .nf-fa-stop_circle_o:before {
    content: "\f28e";
  }
  .nf-fa-street_view:before {
    content: "\f21d";
  }
  .nf-fa-strikethrough:before {
    content: "\f0cc";
  }
  .nf-fa-stumbleupon:before {
    content: "\f1a4";
  }
  .nf-fa-stumbleupon_circle:before {
    content: "\f1a3";
  }
  .nf-fa-subscript:before {
    content: "\f12c";
  }
  .nf-fa-subway:before {
    content: "\f239";
  }
  .nf-fa-suitcase:before {
    content: "\f0f2";
  }
  .nf-fa-sun_o:before {
    content: "\f185";
  }
  .nf-fa-superpowers:before {
    content: "\f2dd";
  }
  .nf-fa-superscript:before {
    content: "\f12b";
  }
  .nf-fa-support:before {
    content: "\f1cd";
  }
  .nf-fa-table:before {
    content: "\f0ce";
  }
  .nf-fa-tablet:before {
    content: "\f10a";
  }
  .nf-fa-tachometer:before {
    content: "\f0e4";
  }
  .nf-fa-tag:before {
    content: "\f02b";
  }
  .nf-fa-tags:before {
    content: "\f02c";
  }
  .nf-fa-tasks:before {
    content: "\f0ae";
  }
  .nf-fa-taxi:before {
    content: "\f1ba";
  }
  .nf-fa-telegram:before {
    content: "\f2c6";
  }
  .nf-fa-television:before {
    content: "\f26c";
  }
  .nf-fa-tencent_weibo:before {
    content: "\f1d5";
  }
  .nf-fa-terminal:before {
    content: "\f120";
  }
  .nf-fa-text_height:before {
    content: "\f034";
  }
  .nf-fa-text_width:before {
    content: "\f035";
  }
  .nf-fa-th:before {
    content: "\f00a";
  }
  .nf-fa-th_large:before {
    content: "\f009";
  }
  .nf-fa-th_list:before {
    content: "\f00b";
  }
  .nf-fa-themeisle:before {
    content: "\f2b2";
  }
  .nf-fa-thermometer:before {
    content: "\f2c7";
  }
  .nf-fa-thermometer_0:before {
    content: "\f2cb";
  }
  .nf-fa-thermometer_1:before {
    content: "\f2ca";
  }
  .nf-fa-thermometer_2:before {
    content: "\f2c9";
  }
  .nf-fa-thermometer_3:before {
    content: "\f2c8";
  }
  .nf-fa-thermometer_4:before {
    content: "\f2c7";
  }
  .nf-fa-thermometer_empty:before {
    content: "\f2cb";
  }
  .nf-fa-thermometer_full:before {
    content: "\f2c7";
  }
  .nf-fa-thermometer_half:before {
    content: "\f2c9";
  }
  .nf-fa-thermometer_quarter:before {
    content: "\f2ca";
  }
  .nf-fa-thermometer_three_quarters:before {
    content: "\f2c8";
  }
  .nf-fa-thumb_tack:before {
    content: "\f08d";
  }
  .nf-fa-thumbs_down:before {
    content: "\f165";
  }
  .nf-fa-thumbs_o_down:before {
    content: "\f088";
  }
  .nf-fa-thumbs_o_up:before {
    content: "\f087";
  }
  .nf-fa-thumbs_up:before {
    content: "\f164";
  }
  .nf-fa-ticket:before {
    content: "\f145";
  }
  .nf-fa-times:before {
    content: "\f00d";
  }
  .nf-fa-times_circle:before {
    content: "\f057";
  }
  .nf-fa-times_circle_o:before {
    content: "\f05c";
  }
  .nf-fa-times_rectangle:before {
    content: "\f2d3";
  }
  .nf-fa-times_rectangle_o:before {
    content: "\f2d4";
  }
  .nf-fa-tint:before {
    content: "\f043";
  }
  .nf-fa-toggle_down:before {
    content: "\f150";
  }
  .nf-fa-toggle_left:before {
    content: "\f191";
  }
  .nf-fa-toggle_off:before {
    content: "\f204";
  }
  .nf-fa-toggle_on:before {
    content: "\f205";
  }
  .nf-fa-toggle_right:before {
    content: "\f152";
  }
  .nf-fa-toggle_up:before {
    content: "\f151";
  }
  .nf-fa-trademark:before {
    content: "\f25c";
  }
  .nf-fa-train:before {
    content: "\f238";
  }
  .nf-fa-transgender:before {
    content: "\f224";
  }
  .nf-fa-transgender_alt:before {
    content: "\f225";
  }
  .nf-fa-trash:before {
    content: "\f1f8";
  }
  .nf-fa-trash_o:before {
    content: "\f014";
  }
  .nf-fa-tree:before {
    content: "\f1bb";
  }
  .nf-fa-trello:before {
    content: "\f181";
  }
  .nf-fa-tripadvisor:before {
    content: "\f262";
  }
  .nf-fa-trophy:before {
    content: "\f091";
  }
  .nf-fa-truck:before {
    content: "\f0d1";
  }
  .nf-fa-try:before {
    content: "\f195";
  }
  .nf-fa-tty:before {
    content: "\f1e4";
  }
  .nf-fa-tumblr:before {
    content: "\f173";
  }
  .nf-fa-tumblr_square:before {
    content: "\f174";
  }
  .nf-fa-turkish_lira:before {
    content: "\f195";
  }
  .nf-fa-tv:before {
    content: "\f26c";
  }
  .nf-fa-twitch:before {
    content: "\f1e8";
  }
  .nf-fa-twitter:before {
    content: "\f099";
  }
  .nf-fa-twitter_square:before {
    content: "\f081";
  }
  .nf-fa-umbrella:before {
    content: "\f0e9";
  }
  .nf-fa-underline:before {
    content: "\f0cd";
  }
  .nf-fa-undo:before {
    content: "\f0e2";
  }
  .nf-fa-universal_access:before {
    content: "\f29a";
  }
  .nf-fa-university:before {
    content: "\f19c";
  }
  .nf-fa-unlink:before {
    content: "\f127";
  }
  .nf-fa-unlock:before {
    content: "\f09c";
  }
  .nf-fa-unlock_alt:before {
    content: "\f13e";
  }
  .nf-fa-unsorted:before {
    content: "\f0dc";
  }
  .nf-fa-upload:before {
    content: "\f093";
  }
  .nf-fa-usb:before {
    content: "\f287";
  }
  .nf-fa-usd:before {
    content: "\f155";
  }
  .nf-fa-user:before {
    content: "\f007";
  }
  .nf-fa-user_circle:before {
    content: "\f2bd";
  }
  .nf-fa-user_circle_o:before {
    content: "\f2be";
  }
  .nf-fa-user_md:before {
    content: "\f0f0";
  }
  .nf-fa-user_o:before {
    content: "\f2c0";
  }
  .nf-fa-user_plus:before {
    content: "\f234";
  }
  .nf-fa-user_secret:before {
    content: "\f21b";
  }
  .nf-fa-user_times:before {
    content: "\f235";
  }
  .nf-fa-users:before {
    content: "\f0c0";
  }
  .nf-fa-vcard:before {
    content: "\f2bb";
  }
  .nf-fa-vcard_o:before {
    content: "\f2bc";
  }
  .nf-fa-venus:before {
    content: "\f221";
  }
  .nf-fa-venus_double:before {
    content: "\f226";
  }
  .nf-fa-venus_mars:before {
    content: "\f228";
  }
  .nf-fa-viacoin:before {
    content: "\f237";
  }
  .nf-fa-viadeo:before {
    content: "\f2a9";
  }
  .nf-fa-viadeo_square:before {
    content: "\f2aa";
  }
  .nf-fa-video_camera:before {
    content: "\f03d";
  }
  .nf-fa-vimeo:before {
    content: "\f27d";
  }
  .nf-fa-vimeo_square:before {
    content: "\f194";
  }
  .nf-fa-vine:before {
    content: "\f1ca";
  }
  .nf-fa-vk:before {
    content: "\f189";
  }
  .nf-fa-volume_control_phone:before {
    content: "\f2a0";
  }
  .nf-fa-volume_down:before {
    content: "\f027";
  }
  .nf-fa-volume_off:before {
    content: "\f026";
  }
  .nf-fa-volume_up:before {
    content: "\f028";
  }
  .nf-fa-warning:before {
    content: "\f071";
  }
  .nf-fa-wechat:before {
    content: "\f1d7";
  }
  .nf-fa-weibo:before {
    content: "\f18a";
  }
  .nf-fa-weixin:before {
    content: "\f1d7";
  }
  .nf-fa-whatsapp:before {
    content: "\f232";
  }
  .nf-fa-wheelchair:before {
    content: "\f193";
  }
  .nf-fa-wheelchair_alt:before {
    content: "\f29b";
  }
  .nf-fa-wifi:before {
    content: "\f1eb";
  }
  .nf-fa-wikipedia_w:before {
    content: "\f266";
  }
  .nf-fa-window_close:before {
    content: "\f2d3";
  }
  .nf-fa-window_close_o:before {
    content: "\f2d4";
  }
  .nf-fa-window_maximize:before {
    content: "\f2d0";
  }
  .nf-fa-window_minimize:before {
    content: "\f2d1";
  }
  .nf-fa-window_restore:before {
    content: "\f2d2";
  }
  .nf-fa-windows:before {
    content: "\f17a";
  }
  .nf-fa-won:before {
    content: "\f159";
  }
  .nf-fa-wordpress:before {
    content: "\f19a";
  }
  .nf-fa-wpbeginner:before {
    content: "\f297";
  }
  .nf-fa-wpexplorer:before {
    content: "\f2de";
  }
  .nf-fa-wpforms:before {
    content: "\f298";
  }
  .nf-fa-wrench:before {
    content: "\f0ad";
  }
  .nf-fa-xing:before {
    content: "\f168";
  }
  .nf-fa-xing_square:before {
    content: "\f169";
  }
  .nf-fa-y_combinator:before {
    content: "\f23b";
  }
  .nf-fa-y_combinator_square:before {
    content: "\f1d4";
  }
  .nf-fa-yahoo:before {
    content: "\f19e";
  }
  .nf-fa-yc:before {
    content: "\f23b";
  }
  .nf-fa-yc_square:before {
    content: "\f1d4";
  }
  .nf-fa-yelp:before {
    content: "\f1e9";
  }
  .nf-fa-yen:before {
    content: "\f157";
  }
  .nf-fa-yoast:before {
    content: "\f2b1";
  }
  .nf-fa-youtube:before {
    content: "\f167";
  }
  .nf-fa-youtube_play:before {
    content: "\f16a";
  }
  .nf-fa-youtube_square:before {
    content: "\f166";
  }
  .nf-fae-apple_fruit:before {
    content: "\e29e";
  }
  .nf-fae-atom:before {
    content: "\e27f";
  }
  .nf-fae-bacteria:before {
    content: "\e280";
  }
  .nf-fae-banana:before {
    content: "\e281";
  }
  .nf-fae-bath:before {
    content: "\e282";
  }
  .nf-fae-bed:before {
    content: "\e283";
  }
  .nf-fae-benzene:before {
    content: "\e284";
  }
  .nf-fae-bigger:before {
    content: "\e285";
  }
  .nf-fae-biohazard:before {
    content: "\e286";
  }
  .nf-fae-blogger_circle:before {
    content: "\e287";
  }
  .nf-fae-blogger_square:before {
    content: "\e288";
  }
  .nf-fae-bones:before {
    content: "\e289";
  }
  .nf-fae-book_open:before {
    content: "\e28a";
  }
  .nf-fae-book_open_o:before {
    content: "\e28b";
  }
  .nf-fae-brain:before {
    content: "\e28c";
  }
  .nf-fae-bread:before {
    content: "\e28d";
  }
  .nf-fae-butterfly:before {
    content: "\e28e";
  }
  .nf-fae-carot:before {
    content: "\e28f";
  }
  .nf-fae-cc_by:before {
    content: "\e290";
  }
  .nf-fae-cc_cc:before {
    content: "\e291";
  }
  .nf-fae-cc_nc:before {
    content: "\e292";
  }
  .nf-fae-cc_nc_eu:before {
    content: "\e293";
  }
  .nf-fae-cc_nc_jp:before {
    content: "\e294";
  }
  .nf-fae-cc_nd:before {
    content: "\e295";
  }
  .nf-fae-cc_remix:before {
    content: "\e296";
  }
  .nf-fae-cc_sa:before {
    content: "\e297";
  }
  .nf-fae-cc_share:before {
    content: "\e298";
  }
  .nf-fae-cc_zero:before {
    content: "\e299";
  }
  .nf-fae-checklist_o:before {
    content: "\e29a";
  }
  .nf-fae-cherry:before {
    content: "\e29b";
  }
  .nf-fae-chess_bishop:before {
    content: "\e29c";
  }
  .nf-fae-chess_horse:before {
    content: "\e25f";
  }
  .nf-fae-chess_king:before {
    content: "\e260";
  }
  .nf-fae-chess_pawn:before {
    content: "\e261";
  }
  .nf-fae-chess_queen:before {
    content: "\e262";
  }
  .nf-fae-chess_tower:before {
    content: "\e263";
  }
  .nf-fae-cheese:before {
    content: "\e264";
  }
  .nf-fae-chicken_thigh:before {
    content: "\e29f";
  }
  .nf-fae-chilli:before {
    content: "\e265";
  }
  .nf-fae-chip:before {
    content: "\e266";
  }
  .nf-fae-cicling:before {
    content: "\e267";
  }
  .nf-fae-cloud:before {
    content: "\e268";
  }
  .nf-fae-cockroach:before {
    content: "\e269";
  }
  .nf-fae-coffe_beans:before {
    content: "\e26a";
  }
  .nf-fae-coins:before {
    content: "\e26b";
  }
  .nf-fae-comb:before {
    content: "\e26c";
  }
  .nf-fae-comet:before {
    content: "\e26d";
  }
  .nf-fae-crown:before {
    content: "\e26e";
  }
  .nf-fae-cup_coffe:before {
    content: "\e26f";
  }
  .nf-fae-dice:before {
    content: "\e270";
  }
  .nf-fae-disco:before {
    content: "\e271";
  }
  .nf-fae-dna:before {
    content: "\e272";
  }
  .nf-fae-donut:before {
    content: "\e273";
  }
  .nf-fae-dress:before {
    content: "\e274";
  }
  .nf-fae-drop:before {
    content: "\e275";
  }
  .nf-fae-ello:before {
    content: "\e276";
  }
  .nf-fae-envelope_open:before {
    content: "\e277";
  }
  .nf-fae-envelope_open_o:before {
    content: "\e278";
  }
  .nf-fae-equal:before {
    content: "\e279";
  }
  .nf-fae-equal_bigger:before {
    content: "\e27a";
  }
  .nf-fae-feedly:before {
    content: "\e27b";
  }
  .nf-fae-file_export:before {
    content: "\e27c";
  }
  .nf-fae-file_import:before {
    content: "\e27d";
  }
  .nf-fae-fingerprint:before {
    content: "\e23f";
  }
  .nf-fae-floppy:before {
    content: "\e240";
  }
  .nf-fae-footprint:before {
    content: "\e241";
  }
  .nf-fae-freecodecamp:before {
    content: "\e242";
  }
  .nf-fae-galaxy:before {
    content: "\e243";
  }
  .nf-fae-galery:before {
    content: "\e244";
  }
  .nf-fae-gift_card:before {
    content: "\e2a0";
  }
  .nf-fae-glass:before {
    content: "\e245";
  }
  .nf-fae-google_drive:before {
    content: "\e246";
  }
  .nf-fae-google_play:before {
    content: "\e247";
  }
  .nf-fae-gps:before {
    content: "\e248";
  }
  .nf-fae-grav:before {
    content: "\e249";
  }
  .nf-fae-guitar:before {
    content: "\e24a";
  }
  .nf-fae-gut:before {
    content: "\e24b";
  }
  .nf-fae-halter:before {
    content: "\e24c";
  }
  .nf-fae-hamburger:before {
    content: "\e24d";
  }
  .nf-fae-hat:before {
    content: "\e24e";
  }
  .nf-fae-hexagon:before {
    content: "\e24f";
  }
  .nf-fae-high_heel:before {
    content: "\e250";
  }
  .nf-fae-hotdog:before {
    content: "\e251";
  }
  .nf-fae-ice_cream:before {
    content: "\e252";
  }
  .nf-fae-id_card:before {
    content: "\e253";
  }
  .nf-fae-imdb:before {
    content: "\e254";
  }
  .nf-fae-infinity:before {
    content: "\e255";
  }
  .nf-fae-injection:before {
    content: "\e2a1";
  }
  .nf-fae-isle:before {
    content: "\e2a2";
  }
  .nf-fae-java:before {
    content: "\e256";
  }
  .nf-fae-layers:before {
    content: "\e257";
  }
  .nf-fae-lips:before {
    content: "\e258";
  }
  .nf-fae-lipstick:before {
    content: "\e259";
  }
  .nf-fae-liver:before {
    content: "\e25a";
  }
  .nf-fae-lollipop:before {
    content: "\e2a3";
  }
  .nf-fae-loyalty_card:before {
    content: "\e2a4";
  }
  .nf-fae-lung:before {
    content: "\e25b";
  }
  .nf-fae-makeup_brushes:before {
    content: "\e25c";
  }
  .nf-fae-maximize:before {
    content: "\e25d";
  }
  .nf-fae-meat:before {
    content: "\e2a5";
  }
  .nf-fae-medicine:before {
    content: "\e221";
  }
  .nf-fae-microscope:before {
    content: "\e222";
  }
  .nf-fae-milk_bottle:before {
    content: "\e223";
  }
  .nf-fae-minimize:before {
    content: "\e224";
  }
  .nf-fae-molecule:before {
    content: "\e225";
  }
  .nf-fae-moon_cloud:before {
    content: "\e226";
  }
  .nf-fae-mountains:before {
    content: "\e2a6";
  }
  .nf-fae-mushroom:before {
    content: "\e227";
  }
  .nf-fae-mustache:before {
    content: "\e228";
  }
  .nf-fae-mysql:before {
    content: "\e229";
  }
  .nf-fae-nintendo:before {
    content: "\e22a";
  }
  .nf-fae-orange:before {
    content: "\e2a7";
  }
  .nf-fae-palette_color:before {
    content: "\e22b";
  }
  .nf-fae-peach:before {
    content: "\e2a8";
  }
  .nf-fae-pear:before {
    content: "\e2a9";
  }
  .nf-fae-pi:before {
    content: "\e22c";
  }
  .nf-fae-pizza:before {
    content: "\e22d";
  }
  .nf-fae-planet:before {
    content: "\e22e";
  }
  .nf-fae-plant:before {
    content: "\e22f";
  }
  .nf-fae-playstation:before {
    content: "\e230";
  }
  .nf-fae-poison:before {
    content: "\e231";
  }
  .nf-fae-popcorn:before {
    content: "\e232";
  }
  .nf-fae-popsicle:before {
    content: "\e233";
  }
  .nf-fae-pulse:before {
    content: "\e234";
  }
  .nf-fae-python:before {
    content: "\e235";
  }
  .nf-fae-quora_circle:before {
    content: "\e236";
  }
  .nf-fae-quora_square:before {
    content: "\e237";
  }
  .nf-fae-radioactive:before {
    content: "\e238";
  }
  .nf-fae-raining:before {
    content: "\e239";
  }
  .nf-fae-real_heart:before {
    content: "\e23a";
  }
  .nf-fae-refrigerator:before {
    content: "\e23b";
  }
  .nf-fae-restore:before {
    content: "\e23c";
  }
  .nf-fae-ring:before {
    content: "\e23d";
  }
  .nf-fae-ruby:before {
    content: "\e23e";
  }
  .nf-fae-ruby_o:before {
    content: "\e21e";
  }
  .nf-fae-ruler:before {
    content: "\e21f";
  }
  .nf-fae-shirt:before {
    content: "\e218";
  }
  .nf-fae-slash:before {
    content: "\e216";
  }
  .nf-fae-smaller:before {
    content: "\e200";
  }
  .nf-fae-snowing:before {
    content: "\e201";
  }
  .nf-fae-soda:before {
    content: "\e202";
  }
  .nf-fae-sofa:before {
    content: "\e203";
  }
  .nf-fae-soup:before {
    content: "\e204";
  }
  .nf-fae-spermatozoon:before {
    content: "\e205";
  }
  .nf-fae-spin_double:before {
    content: "\e206";
  }
  .nf-fae-stomach:before {
    content: "\e207";
  }
  .nf-fae-storm:before {
    content: "\e208";
  }
  .nf-fae-sun_cloud:before {
    content: "\e21d";
  }
  .nf-fae-sushi:before {
    content: "\e21a";
  }
  .nf-fae-tacos:before {
    content: "\e219";
  }
  .nf-fae-telegram:before {
    content: "\e217";
  }
  .nf-fae-telegram_circle:before {
    content: "\e215";
  }
  .nf-fae-telescope:before {
    content: "\e209";
  }
  .nf-fae-thermometer:before {
    content: "\e20a";
  }
  .nf-fae-thermometer_high:before {
    content: "\e20b";
  }
  .nf-fae-thermometer_low:before {
    content: "\e20c";
  }
  .nf-fae-thin_close:before {
    content: "\e20d";
  }
  .nf-fae-toilet:before {
    content: "\e20e";
  }
  .nf-fae-tools:before {
    content: "\e20f";
  }
  .nf-fae-tooth:before {
    content: "\e210";
  }
  .nf-fae-tree:before {
    content: "\e21c";
  }
  .nf-fae-triangle_ruler:before {
    content: "\e21b";
  }
  .nf-fae-umbrella:before {
    content: "\e220";
  }
  .nf-fae-uterus:before {
    content: "\e211";
  }
  .nf-fae-virus:before {
    content: "\e214";
  }
  .nf-fae-w3c:before {
    content: "\e212";
  }
  .nf-fae-walking:before {
    content: "\e213";
  }
  .nf-fae-wallet:before {
    content: "\e25e";
  }
  .nf-fae-wind:before {
    content: "\e27e";
  }
  .nf-fae-xbox:before {
    content: "\e29d";
  }
  .nf-iec-power:before {
    content: "\23fb";
  }
  .nf-iec-power_off:before {
    content: "\2b58";
  }
  .nf-iec-power_on:before {
    content: "\23fd";
  }
  .nf-iec-sleep_mode:before {
    content: "\23fe";
  }
  .nf-iec-toggle_power:before {
    content: "\23fc";
  }
  .nf-indent-dotted_guide:before {
    content: "\e621";
  }
  .nf-indent-line:before {
    content: "\e621";
  }
  .nf-indentation-line:before {
    content: "\e621";
  }
  .nf-linux-alpine:before {
    content: "\f300";
  }
  .nf-linux-aosc:before {
    content: "\f301";
  }
  .nf-linux-apple:before {
    content: "\f302";
  }
  .nf-linux-archlinux:before {
    content: "\f303";
  }
  .nf-linux-centos:before {
    content: "\f304";
  }
  .nf-linux-coreos:before {
    content: "\f305";
  }
  .nf-linux-debian:before {
    content: "\f306";
  }
  .nf-linux-devuan:before {
    content: "\f307";
  }
  .nf-linux-docker:before {
    content: "\f308";
  }
  .nf-linux-elementary:before {
    content: "\f309";
  }
  .nf-linux-fedora:before {
    content: "\f30a";
  }
  .nf-linux-fedora_inverse:before {
    content: "\f30b";
  }
  .nf-linux-freebsd:before {
    content: "\f30c";
  }
  .nf-linux-gentoo:before {
    content: "\f30d";
  }
  .nf-linux-linuxmint:before {
    content: "\f30e";
  }
  .nf-linux-linuxmint_inverse:before {
    content: "\f30f";
  }
  .nf-linux-mageia:before {
    content: "\f310";
  }
  .nf-linux-mandriva:before {
    content: "\f311";
  }
  .nf-linux-manjaro:before {
    content: "\f312";
  }
  .nf-linux-nixos:before {
    content: "\f313";
  }
  .nf-linux-opensuse:before {
    content: "\f314";
  }
  .nf-linux-raspberry_pi:before {
    content: "\f315";
  }
  .nf-linux-redhat:before {
    content: "\f316";
  }
  .nf-linux-sabayon:before {
    content: "\f317";
  }
  .nf-linux-slackware:before {
    content: "\f318";
  }
  .nf-linux-slackware_inverse:before {
    content: "\f319";
  }
  .nf-linux-tux:before {
    content: "\f31a";
  }
  .nf-linux-ubuntu:before {
    content: "\f31b";
  }
  .nf-linux-ubuntu_inverse:before {
    content: "\f31c";
  }
  .nf-mdi-access_point:before {
    content: "\f501";
  }
  .nf-mdi-access_point_network:before {
    content: "\f502";
  }
  .nf-mdi-account:before {
    content: "\f503";
  }
  .nf-mdi-account_alert:before {
    content: "\f504";
  }
  .nf-mdi-account_box:before {
    content: "\f505";
  }
  .nf-mdi-account_box_outline:before {
    content: "\f506";
  }
  .nf-mdi-account_card_details:before {
    content: "\fad1";
  }
  .nf-mdi-account_check:before {
    content: "\f507";
  }
  .nf-mdi-account_circle:before {
    content: "\f508";
  }
  .nf-mdi-account_convert:before {
    content: "\f509";
  }
  .nf-mdi-account_edit:before {
    content: "\fbba";
  }
  .nf-mdi-account_key:before {
    content: "\f50a";
  }
  .nf-mdi-account_location:before {
    content: "\f50b";
  }
  .nf-mdi-account_minus:before {
    content: "\f50c";
  }
  .nf-mdi-account_multiple:before {
    content: "\f50d";
  }
  .nf-mdi-account_multiple_minus:before {
    content: "\fad2";
  }
  .nf-mdi-account_multiple_outline:before {
    content: "\f50e";
  }
  .nf-mdi-account_multiple_plus:before {
    content: "\f50f";
  }
  .nf-mdi-account_multiple_plus_outline:before {
    content: "\fcfe";
  }
  .nf-mdi-account_network:before {
    content: "\f510";
  }
  .nf-mdi-account_off:before {
    content: "\f511";
  }
  .nf-mdi-account_outline:before {
    content: "\f512";
  }
  .nf-mdi-account_plus:before {
    content: "\f513";
  }
  .nf-mdi-account_plus_outline:before {
    content: "\fcff";
  }
  .nf-mdi-account_remove:before {
    content: "\f514";
  }
  .nf-mdi-account_search:before {
    content: "\f515";
  }
  .nf-mdi-account_settings:before {
    content: "\fb2f";
  }
  .nf-mdi-account_settings_variant:before {
    content: "\fb30";
  }
  .nf-mdi-account_star:before {
    content: "\f516";
  }
  .nf-mdi-account_switch:before {
    content: "\f518";
  }
  .nf-mdi-adjust:before {
    content: "\f519";
  }
  .nf-mdi-air_conditioner:before {
    content: "\f51a";
  }
  .nf-mdi-airballoon:before {
    content: "\f51b";
  }
  .nf-mdi-airplane:before {
    content: "\f51c";
  }
  .nf-mdi-airplane_landing:before {
    content: "\fad3";
  }
  .nf-mdi-airplane_off:before {
    content: "\f51d";
  }
  .nf-mdi-airplane_takeoff:before {
    content: "\fad4";
  }
  .nf-mdi-airplay:before {
    content: "\f51e";
  }
  .nf-mdi-alarm:before {
    content: "\f51f";
  }
  .nf-mdi-alarm_bell:before {
    content: "\fc8c";
  }
  .nf-mdi-alarm_check:before {
    content: "\f520";
  }
  .nf-mdi-alarm_light:before {
    content: "\fc8d";
  }
  .nf-mdi-alarm_multiple:before {
    content: "\f521";
  }
  .nf-mdi-alarm_off:before {
    content: "\f522";
  }
  .nf-mdi-alarm_plus:before {
    content: "\f523";
  }
  .nf-mdi-alarm_snooze:before {
    content: "\fb8c";
  }
  .nf-mdi-album:before {
    content: "\f524";
  }
  .nf-mdi-alert:before {
    content: "\f525";
  }
  .nf-mdi-alert_box:before {
    content: "\f526";
  }
  .nf-mdi-alert_circle:before {
    content: "\f527";
  }
  .nf-mdi-alert_circle_outline:before {
    content: "\fad5";
  }
  .nf-mdi-alert_decagram:before {
    content: "\fbbb";
  }
  .nf-mdi-alert_octagon:before {
    content: "\f528";
  }
  .nf-mdi-alert_octagram:before {
    content: "\fc65";
  }
  .nf-mdi-alert_outline:before {
    content: "\f529";
  }
  .nf-mdi-all_inclusive:before {
    content: "\fbbc";
  }
  .nf-mdi-allo:before {
    content: "\fd00";
  }
  .nf-mdi-alpha:before {
    content: "\f52a";
  }
  .nf-mdi-alphabetical:before {
    content: "\f52b";
  }
  .nf-mdi-altimeter:before {
    content: "\fad6";
  }
  .nf-mdi-amazon:before {
    content: "\f52c";
  }
  .nf-mdi-amazon_clouddrive:before {
    content: "\f52d";
  }
  .nf-mdi-ambulance:before {
    content: "\f52e";
  }
  .nf-mdi-amplifier:before {
    content: "\f52f";
  }
  .nf-mdi-anchor:before {
    content: "\f530";
  }
  .nf-mdi-android:before {
    content: "\f531";
  }
  .nf-mdi-android_debug_bridge:before {
    content: "\f532";
  }
  .nf-mdi-android_head:before {
    content: "\fc8e";
  }
  .nf-mdi-android_studio:before {
    content: "\f533";
  }
  .nf-mdi-angular:before {
    content: "\fbb0";
  }
  .nf-mdi-angularjs:before {
    content: "\fbbd";
  }
  .nf-mdi-animation:before {
    content: "\fad7";
  }
  .nf-mdi-apple:before {
    content: "\f534";
  }
  .nf-mdi-apple_finder:before {
    content: "\f535";
  }
  .nf-mdi-apple_ios:before {
    content: "\f536";
  }
  .nf-mdi-apple_keyboard_caps:before {
    content: "\fb31";
  }
  .nf-mdi-apple_keyboard_command:before {
    content: "\fb32";
  }
  .nf-mdi-apple_keyboard_control:before {
    content: "\fb33";
  }
  .nf-mdi-apple_keyboard_option:before {
    content: "\fb34";
  }
  .nf-mdi-apple_keyboard_shift:before {
    content: "\fb35";
  }
  .nf-mdi-apple_mobileme:before {
    content: "\f537";
  }
  .nf-mdi-apple_safari:before {
    content: "\f538";
  }
  .nf-mdi-application:before {
    content: "\fb13";
  }
  .nf-mdi-approval:before {
    content: "\fc8f";
  }
  .nf-mdi-apps:before {
    content: "\f53a";
  }
  .nf-mdi-archive:before {
    content: "\f53b";
  }
  .nf-mdi-arrange_bring_forward:before {
    content: "\f53c";
  }
  .nf-mdi-arrange_bring_to_front:before {
    content: "\f53d";
  }
  .nf-mdi-arrange_send_backward:before {
    content: "\f53e";
  }
  .nf-mdi-arrange_send_to_back:before {
    content: "\f53f";
  }
  .nf-mdi-arrow_all:before {
    content: "\f540";
  }
  .nf-mdi-arrow_bottom_left:before {
    content: "\f541";
  }
  .nf-mdi-arrow_bottom_right:before {
    content: "\f542";
  }
  .nf-mdi-arrow_collapse:before {
    content: "\fb14";
  }
  .nf-mdi-arrow_collapse_all:before {
    content: "\f543";
  }
  .nf-mdi-arrow_collapse_down:before {
    content: "\fc90";
  }
  .nf-mdi-arrow_collapse_left:before {
    content: "\fc91";
  }
  .nf-mdi-arrow_collapse_right:before {
    content: "\fc92";
  }
  .nf-mdi-arrow_collapse_up:before {
    content: "\fc93";
  }
  .nf-mdi-arrow_down:before {
    content: "\f544";
  }
  .nf-mdi-arrow_down_bold:before {
    content: "\fc2c";
  }
  .nf-mdi-arrow_down_bold_box:before {
    content: "\fc2d";
  }
  .nf-mdi-arrow_down_bold_box_outline:before {
    content: "\fc2e";
  }
  .nf-mdi-arrow_down_bold_circle:before {
    content: "\f546";
  }
  .nf-mdi-arrow_down_bold_circle_outline:before {
    content: "\f547";
  }
  .nf-mdi-arrow_down_bold_hexagon_outline:before {
    content: "\f548";
  }
  .nf-mdi-arrow_down_box:before {
    content: "\fbbe";
  }
  .nf-mdi-arrow_down_drop_circle:before {
    content: "\f549";
  }
  .nf-mdi-arrow_down_drop_circle_outline:before {
    content: "\f54a";
  }
  .nf-mdi-arrow_down_thick:before {
    content: "\f545";
  }
  .nf-mdi-arrow_expand:before {
    content: "\fb15";
  }
  .nf-mdi-arrow_expand_all:before {
    content: "\f54b";
  }
  .nf-mdi-arrow_expand_down:before {
    content: "\fc94";
  }
  .nf-mdi-arrow_expand_left:before {
    content: "\fc95";
  }
  .nf-mdi-arrow_expand_right:before {
    content: "\fc96";
  }
  .nf-mdi-arrow_expand_up:before {
    content: "\fc97";
  }
  .nf-mdi-arrow_left:before {
    content: "\f54c";
  }
  .nf-mdi-arrow_left_bold:before {
    content: "\fc2f";
  }
  .nf-mdi-arrow_left_bold_box:before {
    content: "\fc30";
  }
  .nf-mdi-arrow_left_bold_box_outline:before {
    content: "\fc31";
  }
  .nf-mdi-arrow_left_bold_circle:before {
    content: "\f54e";
  }
  .nf-mdi-arrow_left_bold_circle_outline:before {
    content: "\f54f";
  }
  .nf-mdi-arrow_left_bold_hexagon_outline:before {
    content: "\f550";
  }
  .nf-mdi-arrow_left_box:before {
    content: "\fbbf";
  }
  .nf-mdi-arrow_left_drop_circle:before {
    content: "\f551";
  }
  .nf-mdi-arrow_left_drop_circle_outline:before {
    content: "\f552";
  }
  .nf-mdi-arrow_left_thick:before {
    content: "\f54d";
  }
  .nf-mdi-arrow_right:before {
    content: "\f553";
  }
  .nf-mdi-arrow_right_bold:before {
    content: "\fc32";
  }
  .nf-mdi-arrow_right_bold_box:before {
    content: "\fc33";
  }
  .nf-mdi-arrow_right_bold_box_outline:before {
    content: "\fc34";
  }
  .nf-mdi-arrow_right_bold_circle:before {
    content: "\f555";
  }
  .nf-mdi-arrow_right_bold_circle_outline:before {
    content: "\f556";
  }
  .nf-mdi-arrow_right_bold_hexagon_outline:before {
    content: "\f557";
  }
  .nf-mdi-arrow_right_box:before {
    content: "\fbc0";
  }
  .nf-mdi-arrow_right_drop_circle:before {
    content: "\f558";
  }
  .nf-mdi-arrow_right_drop_circle_outline:before {
    content: "\f559";
  }
  .nf-mdi-arrow_right_thick:before {
    content: "\f554";
  }
  .nf-mdi-arrow_top_left:before {
    content: "\f55a";
  }
  .nf-mdi-arrow_top_right:before {
    content: "\f55b";
  }
  .nf-mdi-arrow_up:before {
    content: "\f55c";
  }
  .nf-mdi-arrow_up_bold:before {
    content: "\fc35";
  }
  .nf-mdi-arrow_up_bold_box:before {
    content: "\fc36";
  }
  .nf-mdi-arrow_up_bold_box_outline:before {
    content: "\fc37";
  }
  .nf-mdi-arrow_up_bold_circle:before {
    content: "\f55e";
  }
  .nf-mdi-arrow_up_bold_circle_outline:before {
    content: "\f55f";
  }
  .nf-mdi-arrow_up_bold_hexagon_outline:before {
    content: "\f560";
  }
  .nf-mdi-arrow_up_box:before {
    content: "\fbc1";
  }
  .nf-mdi-arrow_up_drop_circle:before {
    content: "\f561";
  }
  .nf-mdi-arrow_up_drop_circle_outline:before {
    content: "\f562";
  }
  .nf-mdi-arrow_up_thick:before {
    content: "\f55d";
  }
  .nf-mdi-artist:before {
    content: "\fd01";
  }
  .nf-mdi-assistant:before {
    content: "\f563";
  }
  .nf-mdi-asterisk:before {
    content: "\fbc2";
  }
  .nf-mdi-at:before {
    content: "\f564";
  }
  .nf-mdi-atlassian:before {
    content: "\fd02";
  }
  .nf-mdi-atom:before {
    content: "\fc66";
  }
  .nf-mdi-attachment:before {
    content: "\f565";
  }
  .nf-mdi-audiobook:before {
    content: "\f566";
  }
  .nf-mdi-auto_fix:before {
    content: "\f567";
  }
  .nf-mdi-auto_upload:before {
    content: "\f568";
  }
  .nf-mdi-autorenew:before {
    content: "\f569";
  }
  .nf-mdi-av_timer:before {
    content: "\f56a";
  }
  .nf-mdi-azure:before {
    content: "\fd03";
  }
  .nf-mdi-baby:before {
    content: "\f56b";
  }
  .nf-mdi-baby_buggy:before {
    content: "\fb8d";
  }
  .nf-mdi-backburger:before {
    content: "\f56c";
  }
  .nf-mdi-backspace:before {
    content: "\f56d";
  }
  .nf-mdi-backup_restore:before {
    content: "\f56e";
  }
  .nf-mdi-bandcamp:before {
    content: "\fb73";
  }
  .nf-mdi-bank:before {
    content: "\f56f";
  }
  .nf-mdi-barcode:before {
    content: "\f570";
  }
  .nf-mdi-barcode_scan:before {
    content: "\f571";
  }
  .nf-mdi-barley:before {
    content: "\f572";
  }
  .nf-mdi-barrel:before {
    content: "\f573";
  }
  .nf-mdi-basecamp:before {
    content: "\f574";
  }
  .nf-mdi-basket:before {
    content: "\f575";
  }
  .nf-mdi-basket_fill:before {
    content: "\f576";
  }
  .nf-mdi-basket_unfill:before {
    content: "\f577";
  }
  .nf-mdi-basketball:before {
    content: "\fd04";
  }
  .nf-mdi-battery:before {
    content: "\f578";
  }
  .nf-mdi-battery_10:before {
    content: "\f579";
  }
  .nf-mdi-battery_20:before {
    content: "\f57a";
  }
  .nf-mdi-battery_30:before {
    content: "\f57b";
  }
  .nf-mdi-battery_40:before {
    content: "\f57c";
  }
  .nf-mdi-battery_50:before {
    content: "\f57d";
  }
  .nf-mdi-battery_60:before {
    content: "\f57e";
  }
  .nf-mdi-battery_70:before {
    content: "\f57f";
  }
  .nf-mdi-battery_80:before {
    content: "\f580";
  }
  .nf-mdi-battery_90:before {
    content: "\f581";
  }
  .nf-mdi-battery_alert:before {
    content: "\f582";
  }
  .nf-mdi-battery_charging:before {
    content: "\f583";
  }
  .nf-mdi-battery_charging_100:before {
    content: "\f584";
  }
  .nf-mdi-battery_charging_20:before {
    content: "\f585";
  }
  .nf-mdi-battery_charging_30:before {
    content: "\f586";
  }
  .nf-mdi-battery_charging_40:before {
    content: "\f587";
  }
  .nf-mdi-battery_charging_60:before {
    content: "\f588";
  }
  .nf-mdi-battery_charging_80:before {
    content: "\f589";
  }
  .nf-mdi-battery_charging_90:before {
    content: "\f58a";
  }
  .nf-mdi-battery_charging_wireless:before {
    content: "\fd05";
  }
  .nf-mdi-battery_charging_wireless_10:before {
    content: "\fd06";
  }
  .nf-mdi-battery_charging_wireless_20:before {
    content: "\fd07";
  }
  .nf-mdi-battery_charging_wireless_30:before {
    content: "\fd08";
  }
  .nf-mdi-battery_charging_wireless_40:before {
    content: "\fd09";
  }
  .nf-mdi-battery_charging_wireless_50:before {
    content: "\fd0a";
  }
  .nf-mdi-battery_charging_wireless_60:before {
    content: "\fd0b";
  }
  .nf-mdi-battery_charging_wireless_70:before {
    content: "\fd0c";
  }
  .nf-mdi-battery_charging_wireless_80:before {
    content: "\fd0d";
  }
  .nf-mdi-battery_charging_wireless_90:before {
    content: "\fd0e";
  }
  .nf-mdi-battery_charging_wireless_alert:before {
    content: "\fd0f";
  }
  .nf-mdi-battery_charging_wireless_outline:before {
    content: "\fd10";
  }
  .nf-mdi-battery_minus:before {
    content: "\f58b";
  }
  .nf-mdi-battery_negative:before {
    content: "\f58c";
  }
  .nf-mdi-battery_outline:before {
    content: "\f58d";
  }
  .nf-mdi-battery_plus:before {
    content: "\f58e";
  }
  .nf-mdi-battery_positive:before {
    content: "\f58f";
  }
  .nf-mdi-battery_unknown:before {
    content: "\f590";
  }
  .nf-mdi-beach:before {
    content: "\f591";
  }
  .nf-mdi-beaker:before {
    content: "\fb8e";
  }
  .nf-mdi-beats:before {
    content: "\f596";
  }
  .nf-mdi-beer:before {
    content: "\f597";
  }
  .nf-mdi-behance:before {
    content: "\f598";
  }
  .nf-mdi-bell:before {
    content: "\f599";
  }
  .nf-mdi-bell_off:before {
    content: "\f59a";
  }
  .nf-mdi-bell_outline:before {
    content: "\f59b";
  }
  .nf-mdi-bell_plus:before {
    content: "\f59c";
  }
  .nf-mdi-bell_ring:before {
    content: "\f59d";
  }
  .nf-mdi-bell_ring_outline:before {
    content: "\f59e";
  }
  .nf-mdi-bell_sleep:before {
    content: "\f59f";
  }
  .nf-mdi-beta:before {
    content: "\f5a0";
  }
  .nf-mdi-bible:before {
    content: "\f5a1";
  }
  .nf-mdi-bike:before {
    content: "\f5a2";
  }
  .nf-mdi-bing:before {
    content: "\f5a3";
  }
  .nf-mdi-binoculars:before {
    content: "\f5a4";
  }
  .nf-mdi-bio:before {
    content: "\f5a5";
  }
  .nf-mdi-biohazard:before {
    content: "\f5a6";
  }
  .nf-mdi-bitbucket:before {
    content: "\f5a7";
  }
  .nf-mdi-bitcoin:before {
    content: "\fd11";
  }
  .nf-mdi-black_mesa:before {
    content: "\f5a8";
  }
  .nf-mdi-blackberry:before {
    content: "\f5a9";
  }
  .nf-mdi-blender:before {
    content: "\f5aa";
  }
  .nf-mdi-blinds:before {
    content: "\f5ab";
  }
  .nf-mdi-block_helper:before {
    content: "\f5ac";
  }
  .nf-mdi-blogger:before {
    content: "\f5ad";
  }
  .nf-mdi-bluetooth:before {
    content: "\f5ae";
  }
  .nf-mdi-bluetooth_audio:before {
    content: "\f5af";
  }
  .nf-mdi-bluetooth_connect:before {
    content: "\f5b0";
  }
  .nf-mdi-bluetooth_off:before {
    content: "\f5b1";
  }
  .nf-mdi-bluetooth_settings:before {
    content: "\f5b2";
  }
  .nf-mdi-bluetooth_transfer:before {
    content: "\f5b3";
  }
  .nf-mdi-blur:before {
    content: "\f5b4";
  }
  .nf-mdi-blur_linear:before {
    content: "\f5b5";
  }
  .nf-mdi-blur_off:before {
    content: "\f5b6";
  }
  .nf-mdi-blur_radial:before {
    content: "\f5b7";
  }
  .nf-mdi-bomb:before {
    content: "\fb8f";
  }
  .nf-mdi-bomb_off:before {
    content: "\fbc3";
  }
  .nf-mdi-bone:before {
    content: "\f5b8";
  }
  .nf-mdi-book:before {
    content: "\f5b9";
  }
  .nf-mdi-book_minus:before {
    content: "\fad8";
  }
  .nf-mdi-book_multiple:before {
    content: "\f5ba";
  }
  .nf-mdi-book_multiple_variant:before {
    content: "\f5bb";
  }
  .nf-mdi-book_open:before {
    content: "\f5bc";
  }
  .nf-mdi-book_open_page_variant:before {
    content: "\fad9";
  }
  .nf-mdi-book_open_variant:before {
    content: "\f5bd";
  }
  .nf-mdi-book_plus:before {
    content: "\fada";
  }
  .nf-mdi-book_secure:before {
    content: "\fc98";
  }
  .nf-mdi-book_unsecure:before {
    content: "\fc99";
  }
  .nf-mdi-book_variant:before {
    content: "\f5be";
  }
  .nf-mdi-bookmark:before {
    content: "\f5bf";
  }
  .nf-mdi-bookmark_check:before {
    content: "\f5c0";
  }
  .nf-mdi-bookmark_music:before {
    content: "\f5c1";
  }
  .nf-mdi-bookmark_outline:before {
    content: "\f5c2";
  }
  .nf-mdi-bookmark_plus:before {
    content: "\f5c4";
  }
  .nf-mdi-bookmark_plus_outline:before {
    content: "\f5c3";
  }
  .nf-mdi-bookmark_remove:before {
    content: "\f5c5";
  }
  .nf-mdi-boombox:before {
    content: "\fadb";
  }
  .nf-mdi-bootstrap:before {
    content: "\fbc4";
  }
  .nf-mdi-border_all:before {
    content: "\f5c6";
  }
  .nf-mdi-border_bottom:before {
    content: "\f5c7";
  }
  .nf-mdi-border_color:before {
    content: "\f5c8";
  }
  .nf-mdi-border_horizontal:before {
    content: "\f5c9";
  }
  .nf-mdi-border_inside:before {
    content: "\f5ca";
  }
  .nf-mdi-border_left:before {
    content: "\f5cb";
  }
  .nf-mdi-border_none:before {
    content: "\f5cc";
  }
  .nf-mdi-border_outside:before {
    content: "\f5cd";
  }
  .nf-mdi-border_right:before {
    content: "\f5ce";
  }
  .nf-mdi-border_style:before {
    content: "\f5cf";
  }
  .nf-mdi-border_top:before {
    content: "\f5d0";
  }
  .nf-mdi-border_vertical:before {
    content: "\f5d1";
  }
  .nf-mdi-bow_tie:before {
    content: "\fb76";
  }
  .nf-mdi-bowl:before {
    content: "\fb16";
  }
  .nf-mdi-bowling:before {
    content: "\f5d2";
  }
  .nf-mdi-box:before {
    content: "\f5d3";
  }
  .nf-mdi-box_cutter:before {
    content: "\f5d4";
  }
  .nf-mdi-box_shadow:before {
    content: "\fb36";
  }
  .nf-mdi-bridge:before {
    content: "\fb17";
  }
  .nf-mdi-briefcase:before {
    content: "\f5d5";
  }
  .nf-mdi-briefcase_check:before {
    content: "\f5d6";
  }
  .nf-mdi-briefcase_download:before {
    content: "\f5d7";
  }
  .nf-mdi-briefcase_outline:before {
    content: "\fd12";
  }
  .nf-mdi-briefcase_upload:before {
    content: "\f5d8";
  }
  .nf-mdi-brightness_1:before {
    content: "\f5d9";
  }
  .nf-mdi-brightness_2:before {
    content: "\f5da";
  }
  .nf-mdi-brightness_3:before {
    content: "\f5db";
  }
  .nf-mdi-brightness_4:before {
    content: "\f5dc";
  }
  .nf-mdi-brightness_5:before {
    content: "\f5dd";
  }
  .nf-mdi-brightness_6:before {
    content: "\f5de";
  }
  .nf-mdi-brightness_7:before {
    content: "\f5df";
  }
  .nf-mdi-brightness_auto:before {
    content: "\f5e0";
  }
  .nf-mdi-broom:before {
    content: "\f5e1";
  }
  .nf-mdi-brush:before {
    content: "\f5e2";
  }
  .nf-mdi-buffer:before {
    content: "\fb18";
  }
  .nf-mdi-bug:before {
    content: "\f5e3";
  }
  .nf-mdi-bulletin_board:before {
    content: "\f5e4";
  }
  .nf-mdi-bullhorn:before {
    content: "\f5e5";
  }
  .nf-mdi-bullseye:before {
    content: "\fadc";
  }
  .nf-mdi-bus:before {
    content: "\f5e6";
  }
  .nf-mdi-bus_articulated_end:before {
    content: "\fc9a";
  }
  .nf-mdi-bus_articulated_front:before {
    content: "\fc9b";
  }
  .nf-mdi-bus_double_decker:before {
    content: "\fc9c";
  }
  .nf-mdi-bus_school:before {
    content: "\fc9d";
  }
  .nf-mdi-bus_side:before {
    content: "\fc9e";
  }
  .nf-mdi-cached:before {
    content: "\f5e7";
  }
  .nf-mdi-cake:before {
    content: "\f5e8";
  }
  .nf-mdi-cake_layered:before {
    content: "\f5e9";
  }
  .nf-mdi-cake_variant:before {
    content: "\f5ea";
  }
  .nf-mdi-calculator:before {
    content: "\f5eb";
  }
  .nf-mdi-calendar:before {
    content: "\f5ec";
  }
  .nf-mdi-calendar_blank:before {
    content: "\f5ed";
  }
  .nf-mdi-calendar_check:before {
    content: "\f5ee";
  }
  .nf-mdi-calendar_clock:before {
    content: "\f5ef";
  }
  .nf-mdi-calendar_multiple:before {
    content: "\f5f0";
  }
  .nf-mdi-calendar_multiple_check:before {
    content: "\f5f1";
  }
  .nf-mdi-calendar_plus:before {
    content: "\f5f2";
  }
  .nf-mdi-calendar_question:before {
    content: "\fb90";
  }
  .nf-mdi-calendar_range:before {
    content: "\fb77";
  }
  .nf-mdi-calendar_remove:before {
    content: "\f5f3";
  }
  .nf-mdi-calendar_text:before {
    content: "\f5f4";
  }
  .nf-mdi-calendar_today:before {
    content: "\f5f5";
  }
  .nf-mdi-call_made:before {
    content: "\f5f6";
  }
  .nf-mdi-call_merge:before {
    content: "\f5f7";
  }
  .nf-mdi-call_missed:before {
    content: "\f5f8";
  }
  .nf-mdi-call_received:before {
    content: "\f5f9";
  }
  .nf-mdi-call_split:before {
    content: "\f5fa";
  }
  .nf-mdi-camcorder:before {
    content: "\f5fb";
  }
  .nf-mdi-camcorder_box:before {
    content: "\f5fc";
  }
  .nf-mdi-camcorder_box_off:before {
    content: "\f5fd";
  }
  .nf-mdi-camcorder_off:before {
    content: "\f5fe";
  }
  .nf-mdi-camera:before {
    content: "\f5ff";
  }
  .nf-mdi-camera_burst:before {
    content: "\fb91";
  }
  .nf-mdi-camera_enhance:before {
    content: "\f600";
  }
  .nf-mdi-camera_front:before {
    content: "\f601";
  }
  .nf-mdi-camera_front_variant:before {
    content: "\f602";
  }
  .nf-mdi-camera_gopro:before {
    content: "\fc9f";
  }
  .nf-mdi-camera_iris:before {
    content: "\f603";
  }
  .nf-mdi-camera_metering_center:before {
    content: "\fca0";
  }
  .nf-mdi-camera_metering_matrix:before {
    content: "\fca1";
  }
  .nf-mdi-camera_metering_partial:before {
    content: "\fca2";
  }
  .nf-mdi-camera_metering_spot:before {
    content: "\fca3";
  }
  .nf-mdi-camera_off:before {
    content: "\fade";
  }
  .nf-mdi-camera_party_mode:before {
    content: "\f604";
  }
  .nf-mdi-camera_rear:before {
    content: "\f605";
  }
  .nf-mdi-camera_rear_variant:before {
    content: "\f606";
  }
  .nf-mdi-camera_switch:before {
    content: "\f607";
  }
  .nf-mdi-camera_timer:before {
    content: "\f608";
  }
  .nf-mdi-cancel:before {
    content: "\fc38";
  }
  .nf-mdi-candle:before {
    content: "\fae1";
  }
  .nf-mdi-candycane:before {
    content: "\f609";
  }
  .nf-mdi-cannabis:before {
    content: "\fca4";
  }
  .nf-mdi-car:before {
    content: "\f60a";
  }
  .nf-mdi-car_battery:before {
    content: "\f60b";
  }
  .nf-mdi-car_connected:before {
    content: "\f60c";
  }
  .nf-mdi-car_convertible:before {
    content: "\fca5";
  }
  .nf-mdi-car_estate:before {
    content: "\fca6";
  }
  .nf-mdi-car_hatchback:before {
    content: "\fca7";
  }
  .nf-mdi-car_pickup:before {
    content: "\fca8";
  }
  .nf-mdi-car_side:before {
    content: "\fca9";
  }
  .nf-mdi-car_sports:before {
    content: "\fcaa";
  }
  .nf-mdi-car_wash:before {
    content: "\f60d";
  }
  .nf-mdi-caravan:before {
    content: "\fcab";
  }
  .nf-mdi-cards:before {
    content: "\fb37";
  }
  .nf-mdi-cards_outline:before {
    content: "\fb38";
  }
  .nf-mdi-cards_playing_outline:before {
    content: "\fb39";
  }
  .nf-mdi-cards_variant:before {
    content: "\fbc5";
  }
  .nf-mdi-carrot:before {
    content: "\f60e";
  }
  .nf-mdi-cart:before {
    content: "\f60f";
  }
  .nf-mdi-cart_off:before {
    content: "\fb6a";
  }
  .nf-mdi-cart_outline:before {
    content: "\f610";
  }
  .nf-mdi-cart_plus:before {
    content: "\f611";
  }
  .nf-mdi-case_sensitive_alt:before {
    content: "\f612";
  }
  .nf-mdi-cash:before {
    content: "\f613";
  }
  .nf-mdi-cash_100:before {
    content: "\f614";
  }
  .nf-mdi-cash_multiple:before {
    content: "\f615";
  }
  .nf-mdi-cash_usd:before {
    content: "\f616";
  }
  .nf-mdi-cast:before {
    content: "\f617";
  }
  .nf-mdi-cast_connected:before {
    content: "\f618";
  }
  .nf-mdi-cast_off:before {
    content: "\fc88";
  }
  .nf-mdi-castle:before {
    content: "\f619";
  }
  .nf-mdi-cat:before {
    content: "\f61a";
  }
  .nf-mdi-cctv:before {
    content: "\fcac";
  }
  .nf-mdi-ceiling_light:before {
    content: "\fc67";
  }
  .nf-mdi-cellphone:before {
    content: "\f61b";
  }
  .nf-mdi-cellphone_android:before {
    content: "\f61c";
  }
  .nf-mdi-cellphone_basic:before {
    content: "\f61d";
  }
  .nf-mdi-cellphone_dock:before {
    content: "\f61e";
  }
  .nf-mdi-cellphone_iphone:before {
    content: "\f61f";
  }
  .nf-mdi-cellphone_link:before {
    content: "\f620";
  }
  .nf-mdi-cellphone_link_off:before {
    content: "\f621";
  }
  .nf-mdi-cellphone_settings:before {
    content: "\f622";
  }
  .nf-mdi-cellphone_wireless:before {
    content: "\fd13";
  }
  .nf-mdi-certificate:before {
    content: "\f623";
  }
  .nf-mdi-chair_school:before {
    content: "\f624";
  }
  .nf-mdi-chart_arc:before {
    content: "\f625";
  }
  .nf-mdi-chart_areaspline:before {
    content: "\f626";
  }
  .nf-mdi-chart_bar:before {
    content: "\f627";
  }
  .nf-mdi-chart_bar_stacked:before {
    content: "\fc68";
  }
  .nf-mdi-chart_bubble:before {
    content: "\fae2";
  }
  .nf-mdi-chart_donut:before {
    content: "\fcad";
  }
  .nf-mdi-chart_donut_variant:before {
    content: "\fcae";
  }
  .nf-mdi-chart_gantt:before {
    content: "\fb6b";
  }
  .nf-mdi-chart_histogram:before {
    content: "\f628";
  }
  .nf-mdi-chart_line:before {
    content: "\f629";
  }
  .nf-mdi-chart_line_stacked:before {
    content: "\fc69";
  }
  .nf-mdi-chart_line_variant:before {
    content: "\fcaf";
  }
  .nf-mdi-chart_pie:before {
    content: "\f62a";
  }
  .nf-mdi-chart_scatterplot_hexbin:before {
    content: "\fb6c";
  }
  .nf-mdi-chart_timeline:before {
    content: "\fb6d";
  }
  .nf-mdi-check:before {
    content: "\f62b";
  }
  .nf-mdi-check_all:before {
    content: "\f62c";
  }
  .nf-mdi-check_circle:before {
    content: "\fadf";
  }
  .nf-mdi-check_circle_outline:before {
    content: "\fae0";
  }
  .nf-mdi-checkbox_blank:before {
    content: "\f62d";
  }
  .nf-mdi-checkbox_blank_circle:before {
    content: "\f62e";
  }
  .nf-mdi-checkbox_blank_circle_outline:before {
    content: "\f62f";
  }
  .nf-mdi-checkbox_blank_outline:before {
    content: "\f630";
  }
  .nf-mdi-checkbox_marked:before {
    content: "\f631";
  }
  .nf-mdi-checkbox_marked_circle:before {
    content: "\f632";
  }
  .nf-mdi-checkbox_marked_circle_outline:before {
    content: "\f633";
  }
  .nf-mdi-checkbox_marked_outline:before {
    content: "\f634";
  }
  .nf-mdi-checkbox_multiple_blank:before {
    content: "\f635";
  }
  .nf-mdi-checkbox_multiple_blank_circle:before {
    content: "\fb3a";
  }
  .nf-mdi-checkbox_multiple_blank_circle_outline:before {
    content: "\fb3b";
  }
  .nf-mdi-checkbox_multiple_blank_outline:before {
    content: "\f636";
  }
  .nf-mdi-checkbox_multiple_marked:before {
    content: "\f637";
  }
  .nf-mdi-checkbox_multiple_marked_circle:before {
    content: "\fb3c";
  }
  .nf-mdi-checkbox_multiple_marked_circle_outline:before {
    content: "\fb3d";
  }
  .nf-mdi-checkbox_multiple_marked_outline:before {
    content: "\f638";
  }
  .nf-mdi-checkerboard:before {
    content: "\f639";
  }
  .nf-mdi-chemical_weapon:before {
    content: "\f63a";
  }
  .nf-mdi-chevron_double_down:before {
    content: "\f63b";
  }
  .nf-mdi-chevron_double_left:before {
    content: "\f63c";
  }
  .nf-mdi-chevron_double_right:before {
    content: "\f63d";
  }
  .nf-mdi-chevron_double_up:before {
    content: "\f63e";
  }
  .nf-mdi-chevron_down:before {
    content: "\f63f";
  }
  .nf-mdi-chevron_left:before {
    content: "\f640";
  }
  .nf-mdi-chevron_right:before {
    content: "\f641";
  }
  .nf-mdi-chevron_up:before {
    content: "\f642";
  }
  .nf-mdi-chili_hot:before {
    content: "\fcb0";
  }
  .nf-mdi-chili_medium:before {
    content: "\fcb1";
  }
  .nf-mdi-chili_mild:before {
    content: "\fcb2";
  }
  .nf-mdi-chip:before {
    content: "\fb19";
  }
  .nf-mdi-church:before {
    content: "\f643";
  }
  .nf-mdi-circle:before {
    content: "\fc63";
  }
  .nf-mdi-circle_outline:before {
    content: "\fc64";
  }
  .nf-mdi-cisco_webex:before {
    content: "\f644";
  }
  .nf-mdi-city:before {
    content: "\f645";
  }
  .nf-mdi-clipboard:before {
    content: "\f646";
  }
  .nf-mdi-clipboard_account:before {
    content: "\f647";
  }
  .nf-mdi-clipboard_alert:before {
    content: "\f648";
  }
  .nf-mdi-clipboard_arrow_down:before {
    content: "\f649";
  }
  .nf-mdi-clipboard_arrow_left:before {
    content: "\f64a";
  }
  .nf-mdi-clipboard_check:before {
    content: "\f64b";
  }
  .nf-mdi-clipboard_flow:before {
    content: "\fbc6";
  }
  .nf-mdi-clipboard_outline:before {
    content: "\f64c";
  }
  .nf-mdi-clipboard_plus:before {
    content: "\fc4f";
  }
  .nf-mdi-clipboard_text:before {
    content: "\f64d";
  }
  .nf-mdi-clippy:before {
    content: "\f64e";
  }
  .nf-mdi-clock:before {
    content: "\f64f";
  }
  .nf-mdi-clock_alert:before {
    content: "\facd";
  }
  .nf-mdi-clock_end:before {
    content: "\f650";
  }
  .nf-mdi-clock_fast:before {
    content: "\f651";
  }
  .nf-mdi-clock_in:before {
    content: "\f652";
  }
  .nf-mdi-clock_out:before {
    content: "\f653";
  }
  .nf-mdi-clock_start:before {
    content: "\f654";
  }
  .nf-mdi-close:before {
    content: "\f655";
  }
  .nf-mdi-close_box:before {
    content: "\f656";
  }
  .nf-mdi-close_box_outline:before {
    content: "\f657";
  }
  .nf-mdi-close_circle:before {
    content: "\f658";
  }
  .nf-mdi-close_circle_outline:before {
    content: "\f659";
  }
  .nf-mdi-close_network:before {
    content: "\f65a";
  }
  .nf-mdi-close_octagon:before {
    content: "\f65b";
  }
  .nf-mdi-close_octagon_outline:before {
    content: "\f65c";
  }
  .nf-mdi-close_outline:before {
    content: "\fbc7";
  }
  .nf-mdi-closed_caption:before {
    content: "\f65d";
  }
  .nf-mdi-cloud:before {
    content: "\f65e";
  }
  .nf-mdi-cloud_braces:before {
    content: "\fcb3";
  }
  .nf-mdi-cloud_check:before {
    content: "\f65f";
  }
  .nf-mdi-cloud_circle:before {
    content: "\f660";
  }
  .nf-mdi-cloud_download:before {
    content: "\f661";
  }
  .nf-mdi-cloud_off_outline:before {
    content: "\f663";
  }
  .nf-mdi-cloud_outline:before {
    content: "\f662";
  }
  .nf-mdi-cloud_print:before {
    content: "\f664";
  }
  .nf-mdi-cloud_print_outline:before {
    content: "\f665";
  }
  .nf-mdi-cloud_sync:before {
    content: "\fb3e";
  }
  .nf-mdi-cloud_tags:before {
    content: "\fcb4";
  }
  .nf-mdi-cloud_upload:before {
    content: "\f666";
  }
  .nf-mdi-clover:before {
    content: "\fd14";
  }
  .nf-mdi-code_array:before {
    content: "\f667";
  }
  .nf-mdi-code_braces:before {
    content: "\f668";
  }
  .nf-mdi-code_brackets:before {
    content: "\f669";
  }
  .nf-mdi-code_equal:before {
    content: "\f66a";
  }
  .nf-mdi-code_greater_than:before {
    content: "\f66b";
  }
  .nf-mdi-code_greater_than_or_equal:before {
    content: "\f66c";
  }
  .nf-mdi-code_less_than:before {
    content: "\f66d";
  }
  .nf-mdi-code_less_than_or_equal:before {
    content: "\f66e";
  }
  .nf-mdi-code_not_equal:before {
    content: "\f66f";
  }
  .nf-mdi-code_not_equal_variant:before {
    content: "\f670";
  }
  .nf-mdi-code_parentheses:before {
    content: "\f671";
  }
  .nf-mdi-code_string:before {
    content: "\f672";
  }
  .nf-mdi-code_tags:before {
    content: "\f673";
  }
  .nf-mdi-code_tags_check:before {
    content: "\fb92";
  }
  .nf-mdi-codepen:before {
    content: "\f674";
  }
  .nf-mdi-coffee:before {
    content: "\f675";
  }
  .nf-mdi-coffee_outline:before {
    content: "\fbc8";
  }
  .nf-mdi-coffee_to_go:before {
    content: "\f676";
  }
  .nf-mdi-coin:before {
    content: "\f677";
  }
  .nf-mdi-coins:before {
    content: "\fb93";
  }
  .nf-mdi-collage:before {
    content: "\fb3f";
  }
  .nf-mdi-color_helper:before {
    content: "\f678";
  }
  .nf-mdi-comment:before {
    content: "\f679";
  }
  .nf-mdi-comment_account:before {
    content: "\f67a";
  }
  .nf-mdi-comment_account_outline:before {
    content: "\f67b";
  }
  .nf-mdi-comment_alert:before {
    content: "\f67c";
  }
  .nf-mdi-comment_alert_outline:before {
    content: "\f67d";
  }
  .nf-mdi-comment_check:before {
    content: "\f67e";
  }
  .nf-mdi-comment_check_outline:before {
    content: "\f67f";
  }
  .nf-mdi-comment_multiple_outline:before {
    content: "\f680";
  }
  .nf-mdi-comment_outline:before {
    content: "\f681";
  }
  .nf-mdi-comment_plus_outline:before {
    content: "\f682";
  }
  .nf-mdi-comment_processing:before {
    content: "\f683";
  }
  .nf-mdi-comment_processing_outline:before {
    content: "\f684";
  }
  .nf-mdi-comment_question:before {
    content: "\fd15";
  }
  .nf-mdi-comment_question_outline:before {
    content: "\f685";
  }
  .nf-mdi-comment_remove:before {
    content: "\fadd";
  }
  .nf-mdi-comment_remove_outline:before {
    content: "\f686";
  }
  .nf-mdi-comment_text:before {
    content: "\f687";
  }
  .nf-mdi-comment_text_outline:before {
    content: "\f688";
  }
  .nf-mdi-compare:before {
    content: "\f689";
  }
  .nf-mdi-compass:before {
    content: "\f68a";
  }
  .nf-mdi-compass_outline:before {
    content: "\f68b";
  }
  .nf-mdi-console:before {
    content: "\f68c";
  }
  .nf-mdi-console_line:before {
    content: "\fcb5";
  }
  .nf-mdi-contact_mail:before {
    content: "\f68d";
  }
  .nf-mdi-contacts:before {
    content: "\fbc9";
  }
  .nf-mdi-content_copy:before {
    content: "\f68e";
  }
  .nf-mdi-content_cut:before {
    content: "\f68f";
  }
  .nf-mdi-content_duplicate:before {
    content: "\f690";
  }
  .nf-mdi-content_paste:before {
    content: "\f691";
  }
  .nf-mdi-content_save:before {
    content: "\f692";
  }
  .nf-mdi-content_save_all:before {
    content: "\f693";
  }
  .nf-mdi-content_save_outline:before {
    content: "\fd16";
  }
  .nf-mdi-content_save_settings:before {
    content: "\fb1a";
  }
  .nf-mdi-contrast:before {
    content: "\f694";
  }
  .nf-mdi-contrast_box:before {
    content: "\f695";
  }
  .nf-mdi-contrast_circle:before {
    content: "\f696";
  }
  .nf-mdi-cookie:before {
    content: "\f697";
  }
  .nf-mdi-copyright:before {
    content: "\fae5";
  }
  .nf-mdi-corn:before {
    content: "\fcb6";
  }
  .nf-mdi-counter:before {
    content: "\f698";
  }
  .nf-mdi-cow:before {
    content: "\f699";
  }
  .nf-mdi-creation:before {
    content: "\f6c8";
  }
  .nf-mdi-credit_card:before {
    content: "\f69a";
  }
  .nf-mdi-credit_card_multiple:before {
    content: "\f69b";
  }
  .nf-mdi-credit_card_off:before {
    content: "\fae3";
  }
  .nf-mdi-credit_card_plus:before {
    content: "\fb74";
  }
  .nf-mdi-credit_card_scan:before {
    content: "\f69c";
  }
  .nf-mdi-crop:before {
    content: "\f69d";
  }
  .nf-mdi-crop_free:before {
    content: "\f69e";
  }
  .nf-mdi-crop_landscape:before {
    content: "\f69f";
  }
  .nf-mdi-crop_portrait:before {
    content: "\f6a0";
  }
  .nf-mdi-crop_rotate:before {
    content: "\fb94";
  }
  .nf-mdi-crop_square:before {
    content: "\f6a1";
  }
  .nf-mdi-crosshairs:before {
    content: "\f6a2";
  }
  .nf-mdi-crosshairs_gps:before {
    content: "\f6a3";
  }
  .nf-mdi-crown:before {
    content: "\f6a4";
  }
  .nf-mdi-cube:before {
    content: "\f6a5";
  }
  .nf-mdi-cube_outline:before {
    content: "\f6a6";
  }
  .nf-mdi-cube_send:before {
    content: "\f6a7";
  }
  .nf-mdi-cube_unfolded:before {
    content: "\f6a8";
  }
  .nf-mdi-cup:before {
    content: "\f6a9";
  }
  .nf-mdi-cup_off:before {
    content: "\fae4";
  }
  .nf-mdi-cup_water:before {
    content: "\f6aa";
  }
  .nf-mdi-currency_btc:before {
    content: "\f6ab";
  }
  .nf-mdi-currency_chf:before {
    content: "\fcb7";
  }
  .nf-mdi-currency_cny:before {
    content: "\fcb8";
  }
  .nf-mdi-currency_eth:before {
    content: "\fcb9";
  }
  .nf-mdi-currency_eur:before {
    content: "\f6ac";
  }
  .nf-mdi-currency_gbp:before {
    content: "\f6ad";
  }
  .nf-mdi-currency_inr:before {
    content: "\f6ae";
  }
  .nf-mdi-currency_jpy:before {
    content: "\fcba";
  }
  .nf-mdi-currency_krw:before {
    content: "\fcbb";
  }
  .nf-mdi-currency_ngn:before {
    content: "\f6af";
  }
  .nf-mdi-currency_rub:before {
    content: "\f6b0";
  }
  .nf-mdi-currency_sign:before {
    content: "\fcbc";
  }
  .nf-mdi-currency_try:before {
    content: "\f6b1";
  }
  .nf-mdi-currency_twd:before {
    content: "\fcbd";
  }
  .nf-mdi-currency_usd:before {
    content: "\f6b2";
  }
  .nf-mdi-currency_usd_off:before {
    content: "\fb78";
  }
  .nf-mdi-cursor_default:before {
    content: "\f6b3";
  }
  .nf-mdi-cursor_default_outline:before {
    content: "\f6b4";
  }
  .nf-mdi-cursor_move:before {
    content: "\f6b5";
  }
  .nf-mdi-cursor_pointer:before {
    content: "\f6b6";
  }
  .nf-mdi-cursor_text:before {
    content: "\fae6";
  }
  .nf-mdi-database:before {
    content: "\f6b7";
  }
  .nf-mdi-database_minus:before {
    content: "\f6b8";
  }
  .nf-mdi-database_plus:before {
    content: "\f6b9";
  }
  .nf-mdi-debug_step_into:before {
    content: "\f6ba";
  }
  .nf-mdi-debug_step_out:before {
    content: "\f6bb";
  }
  .nf-mdi-debug_step_over:before {
    content: "\f6bc";
  }
  .nf-mdi-decagram:before {
    content: "\fc6a";
  }
  .nf-mdi-decagram_outline:before {
    content: "\fc6b";
  }
  .nf-mdi-decimal_decrease:before {
    content: "\f6bd";
  }
  .nf-mdi-decimal_increase:before {
    content: "\f6be";
  }
  .nf-mdi-delete:before {
    content: "\f6bf";
  }
  .nf-mdi-delete_circle:before {
    content: "\fb81";
  }
  .nf-mdi-delete_empty:before {
    content: "\fbca";
  }
  .nf-mdi-delete_forever:before {
    content: "\fae7";
  }
  .nf-mdi-delete_restore:before {
    content: "\fd17";
  }
  .nf-mdi-delete_sweep:before {
    content: "\fae8";
  }
  .nf-mdi-delete_variant:before {
    content: "\f6c0";
  }
  .nf-mdi-delta:before {
    content: "\f6c1";
  }
  .nf-mdi-deskphone:before {
    content: "\f6c2";
  }
  .nf-mdi-desktop_classic:before {
    content: "\fcbe";
  }
  .nf-mdi-desktop_mac:before {
    content: "\f6c3";
  }
  .nf-mdi-desktop_tower:before {
    content: "\f6c4";
  }
  .nf-mdi-details:before {
    content: "\f6c5";
  }
  .nf-mdi-developer_board:before {
    content: "\fb95";
  }
  .nf-mdi-deviantart:before {
    content: "\f6c6";
  }
  .nf-mdi-dialpad:before {
    content: "\fb1b";
  }
  .nf-mdi-diamond:before {
    content: "\f6c7";
  }
  .nf-mdi-dice_1:before {
    content: "\f6c9";
  }
  .nf-mdi-dice_2:before {
    content: "\f6ca";
  }
  .nf-mdi-dice_3:before {
    content: "\f6cb";
  }
  .nf-mdi-dice_4:before {
    content: "\f6cc";
  }
  .nf-mdi-dice_5:before {
    content: "\f6cd";
  }
  .nf-mdi-dice_6:before {
    content: "\f6ce";
  }
  .nf-mdi-dice_d10:before {
    content: "\fc6d";
  }
  .nf-mdi-dice_d20:before {
    content: "\fae9";
  }
  .nf-mdi-dice_d4:before {
    content: "\faea";
  }
  .nf-mdi-dice_d6:before {
    content: "\faeb";
  }
  .nf-mdi-dice_d8:before {
    content: "\faec";
  }
  .nf-mdi-dice_multiple:before {
    content: "\fc6c";
  }
  .nf-mdi-dictionary:before {
    content: "\fb1c";
  }
  .nf-mdi-dip_switch:before {
    content: "\fcbf";
  }
  .nf-mdi-directions:before {
    content: "\f6cf";
  }
  .nf-mdi-directions_fork:before {
    content: "\fb40";
  }
  .nf-mdi-discord:before {
    content: "\fb6e";
  }
  .nf-mdi-disk:before {
    content: "\faed";
  }
  .nf-mdi-disk_alert:before {
    content: "\f6d0";
  }
  .nf-mdi-disqus:before {
    content: "\f6d1";
  }
  .nf-mdi-disqus_outline:before {
    content: "\f6d2";
  }
  .nf-mdi-division:before {
    content: "\f6d3";
  }
  .nf-mdi-division_box:before {
    content: "\f6d4";
  }
  .nf-mdi-dna:before {
    content: "\fb82";
  }
  .nf-mdi-dns:before {
    content: "\f6d5";
  }
  .nf-mdi-do_not_disturb:before {
    content: "\fb96";
  }
  .nf-mdi-do_not_disturb_off:before {
    content: "\fb97";
  }
  .nf-mdi-dolby:before {
    content: "\fbb1";
  }
  .nf-mdi-domain:before {
    content: "\f6d6";
  }
  .nf-mdi-donkey:before {
    content: "\fcc0";
  }
  .nf-mdi-door:before {
    content: "\fd18";
  }
  .nf-mdi-door_closed:before {
    content: "\fd19";
  }
  .nf-mdi-door_open:before {
    content: "\fd1a";
  }
  .nf-mdi-dots_horizontal:before {
    content: "\f6d7";
  }
  .nf-mdi-dots_horizontal_circle:before {
    content: "\fcc1";
  }
  .nf-mdi-dots_vertical:before {
    content: "\f6d8";
  }
  .nf-mdi-dots_vertical_circle:before {
    content: "\fcc2";
  }
  .nf-mdi-douban:before {
    content: "\fb98";
  }
  .nf-mdi-download:before {
    content: "\f6d9";
  }
  .nf-mdi-download_network:before {
    content: "\fbf2";
  }
  .nf-mdi-drag:before {
    content: "\f6da";
  }
  .nf-mdi-drag_horizontal:before {
    content: "\f6db";
  }
  .nf-mdi-drag_vertical:before {
    content: "\f6dc";
  }
  .nf-mdi-drawing:before {
    content: "\f6dd";
  }
  .nf-mdi-drawing_box:before {
    content: "\f6de";
  }
  .nf-mdi-dribbble:before {
    content: "\f6df";
  }
  .nf-mdi-dribbble_box:before {
    content: "\f6e0";
  }
  .nf-mdi-drone:before {
    content: "\f6e1";
  }
  .nf-mdi-dropbox:before {
    content: "\f6e2";
  }
  .nf-mdi-drupal:before {
    content: "\f6e3";
  }
  .nf-mdi-duck:before {
    content: "\f6e4";
  }
  .nf-mdi-dumbbell:before {
    content: "\f6e5";
  }
  .nf-mdi-ear_hearing:before {
    content: "\fcc3";
  }
  .nf-mdi-earth:before {
    content: "\f6e6";
  }
  .nf-mdi-earth_box:before {
    content: "\fbcb";
  }
  .nf-mdi-earth_box_off:before {
    content: "\fbcc";
  }
  .nf-mdi-earth_off:before {
    content: "\f6e7";
  }
  .nf-mdi-edge:before {
    content: "\f6e8";
  }
  .nf-mdi-eject:before {
    content: "\f6e9";
  }
  .nf-mdi-elephant:before {
    content: "\fcc4";
  }
  .nf-mdi-elevation_decline:before {
    content: "\f6ea";
  }
  .nf-mdi-elevation_rise:before {
    content: "\f6eb";
  }
  .nf-mdi-elevator:before {
    content: "\f6ec";
  }
  .nf-mdi-email:before {
    content: "\f6ed";
  }
  .nf-mdi-email_alert:before {
    content: "\fbcd";
  }
  .nf-mdi-email_open:before {
    content: "\f6ee";
  }
  .nf-mdi-email_open_outline:before {
    content: "\faee";
  }
  .nf-mdi-email_outline:before {
    content: "\f6ef";
  }
  .nf-mdi-email_secure:before {
    content: "\f6f0";
  }
  .nf-mdi-email_variant:before {
    content: "\faef";
  }
  .nf-mdi-emby:before {
    content: "\fbb2";
  }
  .nf-mdi-emoticon:before {
    content: "\f6f1";
  }
  .nf-mdi-emoticon_cool:before {
    content: "\f6f2";
  }
  .nf-mdi-emoticon_dead:before {
    content: "\fb99";
  }
  .nf-mdi-emoticon_devil:before {
    content: "\f6f3";
  }
  .nf-mdi-emoticon_excited:before {
    content: "\fb9a";
  }
  .nf-mdi-emoticon_happy:before {
    content: "\f6f4";
  }
  .nf-mdi-emoticon_neutral:before {
    content: "\f6f5";
  }
  .nf-mdi-emoticon_poop:before {
    content: "\f6f6";
  }
  .nf-mdi-emoticon_sad:before {
    content: "\f6f7";
  }
  .nf-mdi-emoticon_tongue:before {
    content: "\f6f8";
  }
  .nf-mdi-engine:before {
    content: "\f6f9";
  }
  .nf-mdi-engine_outline:before {
    content: "\f6fa";
  }
  .nf-mdi-equal:before {
    content: "\f6fb";
  }
  .nf-mdi-equal_box:before {
    content: "\f6fc";
  }
  .nf-mdi-eraser:before {
    content: "\f6fd";
  }
  .nf-mdi-eraser_variant:before {
    content: "\fb41";
  }
  .nf-mdi-escalator:before {
    content: "\f6fe";
  }
  .nf-mdi-ethernet:before {
    content: "\f6ff";
  }
  .nf-mdi-ethernet_cable:before {
    content: "\f700";
  }
  .nf-mdi-ethernet_cable_off:before {
    content: "\f701";
  }
  .nf-mdi-etsy:before {
    content: "\f702";
  }
  .nf-mdi-ev_station:before {
    content: "\faf0";
  }
  .nf-mdi-eventbrite:before {
    content: "\fcc5";
  }
  .nf-mdi-evernote:before {
    content: "\f703";
  }
  .nf-mdi-exclamation:before {
    content: "\f704";
  }
  .nf-mdi-exit_to_app:before {
    content: "\f705";
  }
  .nf-mdi-export:before {
    content: "\f706";
  }
  .nf-mdi-eye:before {
    content: "\f707";
  }
  .nf-mdi-eye_off:before {
    content: "\f708";
  }
  .nf-mdi-eye_off_outline:before {
    content: "\fbcf";
  }
  .nf-mdi-eye_outline:before {
    content: "\fbce";
  }
  .nf-mdi-eyedropper:before {
    content: "\f709";
  }
  .nf-mdi-eyedropper_variant:before {
    content: "\f70a";
  }
  .nf-mdi-face:before {
    content: "\fb42";
  }
  .nf-mdi-face_profile:before {
    content: "\fb43";
  }
  .nf-mdi-facebook:before {
    content: "\f70b";
  }
  .nf-mdi-facebook_box:before {
    content: "\f70c";
  }
  .nf-mdi-facebook_messenger:before {
    content: "\f70d";
  }
  .nf-mdi-factory:before {
    content: "\f70e";
  }
  .nf-mdi-fan:before {
    content: "\f70f";
  }
  .nf-mdi-fan_off:before {
    content: "\fd1b";
  }
  .nf-mdi-fast_forward:before {
    content: "\f710";
  }
  .nf-mdi-fast_forward_outline:before {
    content: "\fbd0";
  }
  .nf-mdi-fax:before {
    content: "\f711";
  }
  .nf-mdi-feather:before {
    content: "\fbd1";
  }
  .nf-mdi-ferry:before {
    content: "\f712";
  }
  .nf-mdi-file:before {
    content: "\f713";
  }
  .nf-mdi-file_account:before {
    content: "\fc39";
  }
  .nf-mdi-file_chart:before {
    content: "\f714";
  }
  .nf-mdi-file_check:before {
    content: "\f715";
  }
  .nf-mdi-file_cloud:before {
    content: "\f716";
  }
  .nf-mdi-file_delimited:before {
    content: "\f717";
  }
  .nf-mdi-file_document:before {
    content: "\f718";
  }
  .nf-mdi-file_document_box:before {
    content: "\f719";
  }
  .nf-mdi-file_excel:before {
    content: "\f71a";
  }
  .nf-mdi-file_excel_box:before {
    content: "\f71b";
  }
  .nf-mdi-file_export:before {
    content: "\f71c";
  }
  .nf-mdi-file_find:before {
    content: "\f71d";
  }
  .nf-mdi-file_hidden:before {
    content: "\fb12";
  }
  .nf-mdi-file_image:before {
    content: "\f71e";
  }
  .nf-mdi-file_import:before {
    content: "\f71f";
  }
  .nf-mdi-file_lock:before {
    content: "\f720";
  }
  .nf-mdi-file_multiple:before {
    content: "\f721";
  }
  .nf-mdi-file_music:before {
    content: "\f722";
  }
  .nf-mdi-file_outline:before {
    content: "\f723";
  }
  .nf-mdi-file_pdf:before {
    content: "\f724";
  }
  .nf-mdi-file_pdf_box:before {
    content: "\f725";
  }
  .nf-mdi-file_percent:before {
    content: "\fd1c";
  }
  .nf-mdi-file_plus:before {
    content: "\fc50";
  }
  .nf-mdi-file_powerpoint:before {
    content: "\f726";
  }
  .nf-mdi-file_powerpoint_box:before {
    content: "\f727";
  }
  .nf-mdi-file_presentation_box:before {
    content: "\f728";
  }
  .nf-mdi-file_restore:before {
    content: "\fb6f";
  }
  .nf-mdi-file_send:before {
    content: "\f729";
  }
  .nf-mdi-file_tree:before {
    content: "\fb44";
  }
  .nf-mdi-file_video:before {
    content: "\f72a";
  }
  .nf-mdi-file_word:before {
    content: "\f72b";
  }
  .nf-mdi-file_word_box:before {
    content: "\f72c";
  }
  .nf-mdi-file_xml:before {
    content: "\f72d";
  }
  .nf-mdi-film:before {
    content: "\f72e";
  }
  .nf-mdi-filmstrip:before {
    content: "\f72f";
  }
  .nf-mdi-filmstrip_off:before {
    content: "\f730";
  }
  .nf-mdi-filter:before {
    content: "\f731";
  }
  .nf-mdi-filter_outline:before {
    content: "\f732";
  }
  .nf-mdi-filter_remove:before {
    content: "\f733";
  }
  .nf-mdi-filter_remove_outline:before {
    content: "\f734";
  }
  .nf-mdi-filter_variant:before {
    content: "\f735";
  }
  .nf-mdi-finance:before {
    content: "\fd1d";
  }
  .nf-mdi-find_replace:before {
    content: "\fbd2";
  }
  .nf-mdi-fingerprint:before {
    content: "\f736";
  }
  .nf-mdi-fire:before {
    content: "\f737";
  }
  .nf-mdi-firefox:before {
    content: "\f738";
  }
  .nf-mdi-fish:before {
    content: "\f739";
  }
  .nf-mdi-flag:before {
    content: "\f73a";
  }
  .nf-mdi-flag_checkered:before {
    content: "\f73b";
  }
  .nf-mdi-flag_outline:before {
    content: "\f73c";
  }
  .nf-mdi-flag_triangle:before {
    content: "\f73e";
  }
  .nf-mdi-flag_variant:before {
    content: "\f73f";
  }
  .nf-mdi-flag_variant_outline:before {
    content: "\f73d";
  }
  .nf-mdi-flash:before {
    content: "\f740";
  }
  .nf-mdi-flash_auto:before {
    content: "\f741";
  }
  .nf-mdi-flash_circle:before {
    content: "\fd1e";
  }
  .nf-mdi-flash_off:before {
    content: "\f742";
  }
  .nf-mdi-flash_outline:before {
    content: "\fbd3";
  }
  .nf-mdi-flash_red_eye:before {
    content: "\fb79";
  }
  .nf-mdi-flashlight:before {
    content: "\f743";
  }
  .nf-mdi-flashlight_off:before {
    content: "\f744";
  }
  .nf-mdi-flask:before {
    content: "\f592";
  }
  .nf-mdi-flask_empty:before {
    content: "\f593";
  }
  .nf-mdi-flask_empty_outline:before {
    content: "\f594";
  }
  .nf-mdi-flask_outline:before {
    content: "\f595";
  }
  .nf-mdi-flattr:before {
    content: "\f745";
  }
  .nf-mdi-flip_to_back:before {
    content: "\f746";
  }
  .nf-mdi-flip_to_front:before {
    content: "\f747";
  }
  .nf-mdi-floor_plan:before {
    content: "\fd1f";
  }
  .nf-mdi-floppy:before {
    content: "\f748";
  }
  .nf-mdi-flower:before {
    content: "\f749";
  }
  .nf-mdi-folder:before {
    content: "\f74a";
  }
  .nf-mdi-folder_account:before {
    content: "\f74b";
  }
  .nf-mdi-folder_download:before {
    content: "\f74c";
  }
  .nf-mdi-folder_google_drive:before {
    content: "\f74d";
  }
  .nf-mdi-folder_image:before {
    content: "\f74e";
  }
  .nf-mdi-folder_lock:before {
    content: "\f74f";
  }
  .nf-mdi-folder_lock_open:before {
    content: "\f750";
  }
  .nf-mdi-folder_move:before {
    content: "\f751";
  }
  .nf-mdi-folder_multiple:before {
    content: "\f752";
  }
  .nf-mdi-folder_multiple_image:before {
    content: "\f753";
  }
  .nf-mdi-folder_multiple_outline:before {
    content: "\f754";
  }
  .nf-mdi-folder_open:before {
    content: "\fc6e";
  }
  .nf-mdi-folder_outline:before {
    content: "\f755";
  }
  .nf-mdi-folder_plus:before {
    content: "\f756";
  }
  .nf-mdi-folder_remove:before {
    content: "\f757";
  }
  .nf-mdi-folder_star:before {
    content: "\fb9b";
  }
  .nf-mdi-folder_upload:before {
    content: "\f758";
  }
  .nf-mdi-font_awesome:before {
    content: "\f539";
  }
  .nf-mdi-food:before {
    content: "\f759";
  }
  .nf-mdi-food_apple:before {
    content: "\f75a";
  }
  .nf-mdi-food_croissant:before {
    content: "\fcc6";
  }
  .nf-mdi-food_fork_drink:before {
    content: "\faf1";
  }
  .nf-mdi-food_off:before {
    content: "\faf2";
  }
  .nf-mdi-food_variant:before {
    content: "\f75b";
  }
  .nf-mdi-football:before {
    content: "\f75c";
  }
  .nf-mdi-football_australian:before {
    content: "\f75d";
  }
  .nf-mdi-football_helmet:before {
    content: "\f75e";
  }
  .nf-mdi-forklift:before {
    content: "\fcc7";
  }
  .nf-mdi-format_align_bottom:before {
    content: "\fc51";
  }
  .nf-mdi-format_align_center:before {
    content: "\f75f";
  }
  .nf-mdi-format_align_justify:before {
    content: "\f760";
  }
  .nf-mdi-format_align_left:before {
    content: "\f761";
  }
  .nf-mdi-format_align_middle:before {
    content: "\fc52";
  }
  .nf-mdi-format_align_right:before {
    content: "\f762";
  }
  .nf-mdi-format_align_top:before {
    content: "\fc53";
  }
  .nf-mdi-format_annotation_plus:before {
    content: "\fb45";
  }
  .nf-mdi-format_bold:before {
    content: "\f763";
  }
  .nf-mdi-format_clear:before {
    content: "\f764";
  }
  .nf-mdi-format_color_fill:before {
    content: "\f765";
  }
  .nf-mdi-format_color_text:before {
    content: "\fb9c";
  }
  .nf-mdi-format_float_center:before {
    content: "\f766";
  }
  .nf-mdi-format_float_left:before {
    content: "\f767";
  }
  .nf-mdi-format_float_none:before {
    content: "\f768";
  }
  .nf-mdi-format_float_right:before {
    content: "\f769";
  }
  .nf-mdi-format_font:before {
    content: "\fbd4";
  }
  .nf-mdi-format_header_1:before {
    content: "\f76a";
  }
  .nf-mdi-format_header_2:before {
    content: "\f76b";
  }
  .nf-mdi-format_header_3:before {
    content: "\f76c";
  }
  .nf-mdi-format_header_4:before {
    content: "\f76d";
  }
  .nf-mdi-format_header_5:before {
    content: "\f76e";
  }
  .nf-mdi-format_header_6:before {
    content: "\f76f";
  }
  .nf-mdi-format_header_decrease:before {
    content: "\f770";
  }
  .nf-mdi-format_header_equal:before {
    content: "\f771";
  }
  .nf-mdi-format_header_increase:before {
    content: "\f772";
  }
  .nf-mdi-format_header_pound:before {
    content: "\f773";
  }
  .nf-mdi-format_horizontal_align_center:before {
    content: "\fb1d";
  }
  .nf-mdi-format_horizontal_align_left:before {
    content: "\fb1e";
  }
  .nf-mdi-format_horizontal_align_right:before {
    content: "\fb1f";
  }
  .nf-mdi-format_indent_decrease:before {
    content: "\f774";
  }
  .nf-mdi-format_indent_increase:before {
    content: "\f775";
  }
  .nf-mdi-format_italic:before {
    content: "\f776";
  }
  .nf-mdi-format_line_spacing:before {
    content: "\f777";
  }
  .nf-mdi-format_line_style:before {
    content: "\fac7";
  }
  .nf-mdi-format_line_weight:before {
    content: "\fac8";
  }
  .nf-mdi-format_list_bulleted:before {
    content: "\f778";
  }
  .nf-mdi-format_list_bulleted_type:before {
    content: "\f779";
  }
  .nf-mdi-format_list_checks:before {
    content: "\fc54";
  }
  .nf-mdi-format_list_numbers:before {
    content: "\f77a";
  }
  .nf-mdi-format_page_break:before {
    content: "\fbd5";
  }
  .nf-mdi-format_paint:before {
    content: "\f77b";
  }
  .nf-mdi-format_paragraph:before {
    content: "\f77c";
  }
  .nf-mdi-format_pilcrow:before {
    content: "\fbd6";
  }
  .nf-mdi-format_quote_close:before {
    content: "\f77d";
  }
  .nf-mdi-format_quote_open:before {
    content: "\fc55";
  }
  .nf-mdi-format_rotate_90:before {
    content: "\fba8";
  }
  .nf-mdi-format_section:before {
    content: "\fb9d";
  }
  .nf-mdi-format_size:before {
    content: "\f77e";
  }
  .nf-mdi-format_strikethrough:before {
    content: "\f77f";
  }
  .nf-mdi-format_strikethrough_variant:before {
    content: "\f780";
  }
  .nf-mdi-format_subscript:before {
    content: "\f781";
  }
  .nf-mdi-format_superscript:before {
    content: "\f782";
  }
  .nf-mdi-format_text:before {
    content: "\f783";
  }
  .nf-mdi-format_textdirection_l_to_r:before {
    content: "\f784";
  }
  .nf-mdi-format_textdirection_r_to_l:before {
    content: "\f785";
  }
  .nf-mdi-format_title:before {
    content: "\faf3";
  }
  .nf-mdi-format_underline:before {
    content: "\f786";
  }
  .nf-mdi-format_vertical_align_bottom:before {
    content: "\fb20";
  }
  .nf-mdi-format_vertical_align_center:before {
    content: "\fb21";
  }
  .nf-mdi-format_vertical_align_top:before {
    content: "\fb22";
  }
  .nf-mdi-format_wrap_inline:before {
    content: "\f787";
  }
  .nf-mdi-format_wrap_square:before {
    content: "\f788";
  }
  .nf-mdi-format_wrap_tight:before {
    content: "\f789";
  }
  .nf-mdi-format_wrap_top_bottom:before {
    content: "\f78a";
  }
  .nf-mdi-forum:before {
    content: "\f78b";
  }
  .nf-mdi-forum_outline:before {
    content: "\fd20";
  }
  .nf-mdi-forward:before {
    content: "\f78c";
  }
  .nf-mdi-foursquare:before {
    content: "\f78d";
  }
  .nf-mdi-fridge:before {
    content: "\f78e";
  }
  .nf-mdi-fridge_filled:before {
    content: "\f78f";
  }
  .nf-mdi-fridge_filled_bottom:before {
    content: "\f790";
  }
  .nf-mdi-fridge_filled_top:before {
    content: "\f791";
  }
  .nf-mdi-fuel:before {
    content: "\fcc8";
  }
  .nf-mdi-fullscreen:before {
    content: "\f792";
  }
  .nf-mdi-fullscreen_exit:before {
    content: "\f793";
  }
  .nf-mdi-function:before {
    content: "\f794";
  }
  .nf-mdi-gamepad:before {
    content: "\f795";
  }
  .nf-mdi-gamepad_variant:before {
    content: "\f796";
  }
  .nf-mdi-garage:before {
    content: "\fbd7";
  }
  .nf-mdi-garage_open:before {
    content: "\fbd8";
  }
  .nf-mdi-gas_cylinder:before {
    content: "\fb46";
  }
  .nf-mdi-gas_station:before {
    content: "\f797";
  }
  .nf-mdi-gate:before {
    content: "\f798";
  }
  .nf-mdi-gauge:before {
    content: "\f799";
  }
  .nf-mdi-gavel:before {
    content: "\f79a";
  }
  .nf-mdi-gender_female:before {
    content: "\f79b";
  }
  .nf-mdi-gender_male:before {
    content: "\f79c";
  }
  .nf-mdi-gender_male_female:before {
    content: "\f79d";
  }
  .nf-mdi-gender_transgender:before {
    content: "\f79e";
  }
  .nf-mdi-gesture:before {
    content: "\fcc9";
  }
  .nf-mdi-gesture_double_tap:before {
    content: "\fc3a";
  }
  .nf-mdi-gesture_swipe_down:before {
    content: "\fc3b";
  }
  .nf-mdi-gesture_swipe_left:before {
    content: "\fc3c";
  }
  .nf-mdi-gesture_swipe_right:before {
    content: "\fc3d";
  }
  .nf-mdi-gesture_swipe_up:before {
    content: "\fc3e";
  }
  .nf-mdi-gesture_tap:before {
    content: "\fc3f";
  }
  .nf-mdi-gesture_two_double_tap:before {
    content: "\fc40";
  }
  .nf-mdi-gesture_two_tap:before {
    content: "\fc41";
  }
  .nf-mdi-ghost:before {
    content: "\f79f";
  }
  .nf-mdi-gift:before {
    content: "\f7a0";
  }
  .nf-mdi-git:before {
    content: "\f7a1";
  }
  .nf-mdi-github_box:before {
    content: "\f7a2";
  }
  .nf-mdi-github_circle:before {
    content: "\f7a3";
  }
  .nf-mdi-github_face:before {
    content: "\fbd9";
  }
  .nf-mdi-glass_flute:before {
    content: "\f7a4";
  }
  .nf-mdi-glass_mug:before {
    content: "\f7a5";
  }
  .nf-mdi-glass_stange:before {
    content: "\f7a6";
  }
  .nf-mdi-glass_tulip:before {
    content: "\f7a7";
  }
  .nf-mdi-glassdoor:before {
    content: "\f7a8";
  }
  .nf-mdi-glasses:before {
    content: "\f7a9";
  }
  .nf-mdi-gmail:before {
    content: "\f7aa";
  }
  .nf-mdi-gnome:before {
    content: "\f7ab";
  }
  .nf-mdi-golf:before {
    content: "\fd21";
  }
  .nf-mdi-gondola:before {
    content: "\fb84";
  }
  .nf-mdi-google:before {
    content: "\f7ac";
  }
  .nf-mdi-google_analytics:before {
    content: "\fcca";
  }
  .nf-mdi-google_assistant:before {
    content: "\fccb";
  }
  .nf-mdi-google_cardboard:before {
    content: "\f7ad";
  }
  .nf-mdi-google_chrome:before {
    content: "\f7ae";
  }
  .nf-mdi-google_circles:before {
    content: "\f7af";
  }
  .nf-mdi-google_circles_communities:before {
    content: "\f7b0";
  }
  .nf-mdi-google_circles_extended:before {
    content: "\f7b1";
  }
  .nf-mdi-google_circles_group:before {
    content: "\f7b2";
  }
  .nf-mdi-google_controller:before {
    content: "\f7b3";
  }
  .nf-mdi-google_controller_off:before {
    content: "\f7b4";
  }
  .nf-mdi-google_drive:before {
    content: "\f7b5";
  }
  .nf-mdi-google_earth:before {
    content: "\f7b6";
  }
  .nf-mdi-google_glass:before {
    content: "\f7b7";
  }
  .nf-mdi-google_home:before {
    content: "\fd22";
  }
  .nf-mdi-google_keep:before {
    content: "\fbda";
  }
  .nf-mdi-google_maps:before {
    content: "\faf4";
  }
  .nf-mdi-google_nearby:before {
    content: "\f7b8";
  }
  .nf-mdi-google_pages:before {
    content: "\f7b9";
  }
  .nf-mdi-google_photos:before {
    content: "\fbdb";
  }
  .nf-mdi-google_physical_web:before {
    content: "\f7ba";
  }
  .nf-mdi-google_play:before {
    content: "\f7bb";
  }
  .nf-mdi-google_plus:before {
    content: "\f7bc";
  }
  .nf-mdi-google_plus_box:before {
    content: "\f7bd";
  }
  .nf-mdi-google_translate:before {
    content: "\f7be";
  }
  .nf-mdi-google_wallet:before {
    content: "\f7bf";
  }
  .nf-mdi-gradient:before {
    content: "\fb9e";
  }
  .nf-mdi-grease_pencil:before {
    content: "\fb47";
  }
  .nf-mdi-grid:before {
    content: "\f7c0";
  }
  .nf-mdi-grid_large:before {
    content: "\fc56";
  }
  .nf-mdi-grid_off:before {
    content: "\f7c1";
  }
  .nf-mdi-group:before {
    content: "\f7c2";
  }
  .nf-mdi-guitar_acoustic:before {
    content: "\fc6f";
  }
  .nf-mdi-guitar_electric:before {
    content: "\f7c3";
  }
  .nf-mdi-guitar_pick:before {
    content: "\f7c4";
  }
  .nf-mdi-guitar_pick_outline:before {
    content: "\f7c5";
  }
  .nf-mdi-guy_fawkes_mask:before {
    content: "\fd23";
  }
  .nf-mdi-hackernews:before {
    content: "\fb23";
  }
  .nf-mdi-hamburger:before {
    content: "\fb83";
  }
  .nf-mdi-hand_pointing_right:before {
    content: "\f7c6";
  }
  .nf-mdi-hanger:before {
    content: "\f7c7";
  }
  .nf-mdi-hangouts:before {
    content: "\f7c8";
  }
  .nf-mdi-harddisk:before {
    content: "\f7c9";
  }
  .nf-mdi-headphones:before {
    content: "\f7ca";
  }
  .nf-mdi-headphones_box:before {
    content: "\f7cb";
  }
  .nf-mdi-headphones_off:before {
    content: "\fccc";
  }
  .nf-mdi-headphones_settings:before {
    content: "\f7cc";
  }
  .nf-mdi-headset:before {
    content: "\f7cd";
  }
  .nf-mdi-headset_dock:before {
    content: "\f7ce";
  }
  .nf-mdi-headset_off:before {
    content: "\f7cf";
  }
  .nf-mdi-heart:before {
    content: "\f7d0";
  }
  .nf-mdi-heart_box:before {
    content: "\f7d1";
  }
  .nf-mdi-heart_box_outline:before {
    content: "\f7d2";
  }
  .nf-mdi-heart_broken:before {
    content: "\f7d3";
  }
  .nf-mdi-heart_half:before {
    content: "\fbdd";
  }
  .nf-mdi-heart_half_full:before {
    content: "\fbdc";
  }
  .nf-mdi-heart_half_outline:before {
    content: "\fbde";
  }
  .nf-mdi-heart_off:before {
    content: "\fc57";
  }
  .nf-mdi-heart_outline:before {
    content: "\f7d4";
  }
  .nf-mdi-heart_pulse:before {
    content: "\faf5";
  }
  .nf-mdi-help:before {
    content: "\f7d5";
  }
  .nf-mdi-help_box:before {
    content: "\fc89";
  }
  .nf-mdi-help_circle:before {
    content: "\f7d6";
  }
  .nf-mdi-help_circle_outline:before {
    content: "\fb24";
  }
  .nf-mdi-help_network:before {
    content: "\fbf3";
  }
  .nf-mdi-hexagon:before {
    content: "\f7d7";
  }
  .nf-mdi-hexagon_multiple:before {
    content: "\fbdf";
  }
  .nf-mdi-hexagon_outline:before {
    content: "\f7d8";
  }
  .nf-mdi-high_definition:before {
    content: "\fccd";
  }
  .nf-mdi-highway:before {
    content: "\faf6";
  }
  .nf-mdi-history:before {
    content: "\f7d9";
  }
  .nf-mdi-hololens:before {
    content: "\f7da";
  }
  .nf-mdi-home:before {
    content: "\f7db";
  }
  .nf-mdi-home_account:before {
    content: "\fd24";
  }
  .nf-mdi-home_assistant:before {
    content: "\fcce";
  }
  .nf-mdi-home_automation:before {
    content: "\fccf";
  }
  .nf-mdi-home_circle:before {
    content: "\fcd0";
  }
  .nf-mdi-home_heart:before {
    content: "\fd25";
  }
  .nf-mdi-home_map_marker:before {
    content: "\faf7";
  }
  .nf-mdi-home_modern:before {
    content: "\f7dc";
  }
  .nf-mdi-home_outline:before {
    content: "\fb9f";
  }
  .nf-mdi-home_variant:before {
    content: "\f7dd";
  }
  .nf-mdi-hook:before {
    content: "\fbe0";
  }
  .nf-mdi-hook_off:before {
    content: "\fbe1";
  }
  .nf-mdi-hops:before {
    content: "\f7de";
  }
  .nf-mdi-hospital:before {
    content: "\f7df";
  }
  .nf-mdi-hospital_building:before {
    content: "\f7e0";
  }
  .nf-mdi-hospital_marker:before {
    content: "\f7e1";
  }
  .nf-mdi-hot_tub:before {
    content: "\fd26";
  }
  .nf-mdi-hotel:before {
    content: "\f7e2";
  }
  .nf-mdi-houzz:before {
    content: "\f7e3";
  }
  .nf-mdi-houzz_box:before {
    content: "\f7e4";
  }
  .nf-mdi-hulu:before {
    content: "\fd27";
  }
  .nf-mdi-human:before {
    content: "\f7e5";
  }
  .nf-mdi-human_child:before {
    content: "\f7e6";
  }
  .nf-mdi-human_female:before {
    content: "\fb48";
  }
  .nf-mdi-human_greeting:before {
    content: "\fb49";
  }
  .nf-mdi-human_handsdown:before {
    content: "\fb4a";
  }
  .nf-mdi-human_handsup:before {
    content: "\fb4b";
  }
  .nf-mdi-human_male:before {
    content: "\fb4c";
  }
  .nf-mdi-human_male_female:before {
    content: "\f7e7";
  }
  .nf-mdi-human_pregnant:before {
    content: "\face";
  }
  .nf-mdi-humble_bundle:before {
    content: "\fc42";
  }
  .nf-mdi-ice_cream:before {
    content: "\fd28";
  }
  .nf-mdi-image:before {
    content: "\f7e8";
  }
  .nf-mdi-image_album:before {
    content: "\f7e9";
  }
  .nf-mdi-image_area:before {
    content: "\f7ea";
  }
  .nf-mdi-image_area_close:before {
    content: "\f7eb";
  }
  .nf-mdi-image_broken:before {
    content: "\f7ec";
  }
  .nf-mdi-image_broken_variant:before {
    content: "\f7ed";
  }
  .nf-mdi-image_filter:before {
    content: "\f7ee";
  }
  .nf-mdi-image_filter_black_white:before {
    content: "\f7ef";
  }
  .nf-mdi-image_filter_center_focus:before {
    content: "\f7f0";
  }
  .nf-mdi-image_filter_center_focus_weak:before {
    content: "\f7f1";
  }
  .nf-mdi-image_filter_drama:before {
    content: "\f7f2";
  }
  .nf-mdi-image_filter_frames:before {
    content: "\f7f3";
  }
  .nf-mdi-image_filter_hdr:before {
    content: "\f7f4";
  }
  .nf-mdi-image_filter_none:before {
    content: "\f7f5";
  }
  .nf-mdi-image_filter_tilt_shift:before {
    content: "\f7f6";
  }
  .nf-mdi-image_filter_vintage:before {
    content: "\f7f7";
  }
  .nf-mdi-image_multiple:before {
    content: "\f7f8";
  }
  .nf-mdi-image_off:before {
    content: "\fd29";
  }
  .nf-mdi-import:before {
    content: "\f7f9";
  }
  .nf-mdi-inbox:before {
    content: "\fb85";
  }
  .nf-mdi-inbox_arrow_down:before {
    content: "\f7fa";
  }
  .nf-mdi-inbox_arrow_up:before {
    content: "\f8d0";
  }
  .nf-mdi-incognito:before {
    content: "\faf8";
  }
  .nf-mdi-infinity:before {
    content: "\fbe2";
  }
  .nf-mdi-information:before {
    content: "\f7fb";
  }
  .nf-mdi-information_outline:before {
    content: "\f7fc";
  }
  .nf-mdi-information_variant:before {
    content: "\fb4d";
  }
  .nf-mdi-instagram:before {
    content: "\f7fd";
  }
  .nf-mdi-instapaper:before {
    content: "\f7fe";
  }
  .nf-mdi-internet_explorer:before {
    content: "\f7ff";
  }
  .nf-mdi-invert_colors:before {
    content: "\f800";
  }
  .nf-mdi-itunes:before {
    content: "\fb75";
  }
  .nf-mdi-jeepney:before {
    content: "\f801";
  }
  .nf-mdi-jira:before {
    content: "\f802";
  }
  .nf-mdi-jsfiddle:before {
    content: "\f803";
  }
  .nf-mdi-json:before {
    content: "\fb25";
  }
  .nf-mdi-karate:before {
    content: "\fd2a";
  }
  .nf-mdi-keg:before {
    content: "\f804";
  }
  .nf-mdi-kettle:before {
    content: "\faf9";
  }
  .nf-mdi-key:before {
    content: "\f805";
  }
  .nf-mdi-key_change:before {
    content: "\f806";
  }
  .nf-mdi-key_minus:before {
    content: "\f807";
  }
  .nf-mdi-key_plus:before {
    content: "\f808";
  }
  .nf-mdi-key_remove:before {
    content: "\f809";
  }
  .nf-mdi-key_variant:before {
    content: "\f80a";
  }
  .nf-mdi-keyboard:before {
    content: "\f80b";
  }
  .nf-mdi-keyboard_backspace:before {
    content: "\f80c";
  }
  .nf-mdi-keyboard_caps:before {
    content: "\f80d";
  }
  .nf-mdi-keyboard_close:before {
    content: "\f80e";
  }
  .nf-mdi-keyboard_off:before {
    content: "\f80f";
  }
  .nf-mdi-keyboard_return:before {
    content: "\f810";
  }
  .nf-mdi-keyboard_tab:before {
    content: "\f811";
  }
  .nf-mdi-keyboard_variant:before {
    content: "\f812";
  }
  .nf-mdi-kickstarter:before {
    content: "\fc43";
  }
  .nf-mdi-kodi:before {
    content: "\f813";
  }
  .nf-mdi-label:before {
    content: "\f814";
  }
  .nf-mdi-label_outline:before {
    content: "\f815";
  }
  .nf-mdi-ladybug:before {
    content: "\fd2b";
  }
  .nf-mdi-lambda:before {
    content: "\fb26";
  }
  .nf-mdi-lamp:before {
    content: "\fbb3";
  }
  .nf-mdi-lan:before {
    content: "\f816";
  }
  .nf-mdi-lan_connect:before {
    content: "\f817";
  }
  .nf-mdi-lan_disconnect:before {
    content: "\f818";
  }
  .nf-mdi-lan_pending:before {
    content: "\f819";
  }
  .nf-mdi-language_c:before {
    content: "\fb70";
  }
  .nf-mdi-language_cpp:before {
    content: "\fb71";
  }
  .nf-mdi-language_csharp:before {
    content: "\f81a";
  }
  .nf-mdi-language_css3:before {
    content: "\f81b";
  }
  .nf-mdi-language_go:before {
    content: "\fcd1";
  }
  .nf-mdi-language_html5:before {
    content: "\f81c";
  }
  .nf-mdi-language_javascript:before {
    content: "\f81d";
  }
  .nf-mdi-language_php:before {
    content: "\f81e";
  }
  .nf-mdi-language_python:before {
    content: "\f81f";
  }
  .nf-mdi-language_python_text:before {
    content: "\f820";
  }
  .nf-mdi-language_r:before {
    content: "\fcd2";
  }
  .nf-mdi-language_swift:before {
    content: "\fbe3";
  }
  .nf-mdi-language_typescript:before {
    content: "\fbe4";
  }
  .nf-mdi-laptop:before {
    content: "\f821";
  }
  .nf-mdi-laptop_chromebook:before {
    content: "\f822";
  }
  .nf-mdi-laptop_mac:before {
    content: "\f823";
  }
  .nf-mdi-laptop_off:before {
    content: "\fbe5";
  }
  .nf-mdi-laptop_windows:before {
    content: "\f824";
  }
  .nf-mdi-lastfm:before {
    content: "\f825";
  }
  .nf-mdi-lastpass:before {
    content: "\f945";
  }
  .nf-mdi-launch:before {
    content: "\f826";
  }
  .nf-mdi-lava_lamp:before {
    content: "\fcd3";
  }
  .nf-mdi-layers:before {
    content: "\f827";
  }
  .nf-mdi-layers_off:before {
    content: "\f828";
  }
  .nf-mdi-lead_pencil:before {
    content: "\fb4e";
  }
  .nf-mdi-leaf:before {
    content: "\f829";
  }
  .nf-mdi-led_off:before {
    content: "\f82a";
  }
  .nf-mdi-led_on:before {
    content: "\f82b";
  }
  .nf-mdi-led_outline:before {
    content: "\f82c";
  }
  .nf-mdi-led_strip:before {
    content: "\fcd4";
  }
  .nf-mdi-led_variant_off:before {
    content: "\f82d";
  }
  .nf-mdi-led_variant_on:before {
    content: "\f82e";
  }
  .nf-mdi-led_variant_outline:before {
    content: "\f82f";
  }
  .nf-mdi-library:before {
    content: "\f830";
  }
  .nf-mdi-library_books:before {
    content: "\f831";
  }
  .nf-mdi-library_music:before {
    content: "\f832";
  }
  .nf-mdi-library_plus:before {
    content: "\f833";
  }
  .nf-mdi-lightbulb:before {
    content: "\f834";
  }
  .nf-mdi-lightbulb_on:before {
    content: "\fbe6";
  }
  .nf-mdi-lightbulb_on_outline:before {
    content: "\fbe7";
  }
  .nf-mdi-lightbulb_outline:before {
    content: "\f835";
  }
  .nf-mdi-link:before {
    content: "\f836";
  }
  .nf-mdi-link_off:before {
    content: "\f837";
  }
  .nf-mdi-link_variant:before {
    content: "\f838";
  }
  .nf-mdi-link_variant_off:before {
    content: "\f839";
  }
  .nf-mdi-linkedin:before {
    content: "\f83a";
  }
  .nf-mdi-linkedin_box:before {
    content: "\f83b";
  }
  .nf-mdi-linux:before {
    content: "\f83c";
  }
  .nf-mdi-loading:before {
    content: "\fc70";
  }
  .nf-mdi-lock:before {
    content: "\f83d";
  }
  .nf-mdi-lock_open:before {
    content: "\f83e";
  }
  .nf-mdi-lock_open_outline:before {
    content: "\f83f";
  }
  .nf-mdi-lock_outline:before {
    content: "\f840";
  }
  .nf-mdi-lock_pattern:before {
    content: "\fbe8";
  }
  .nf-mdi-lock_plus:before {
    content: "\fafa";
  }
  .nf-mdi-lock_reset:before {
    content: "\fc71";
  }
  .nf-mdi-locker:before {
    content: "\fcd5";
  }
  .nf-mdi-locker_multiple:before {
    content: "\fcd6";
  }
  .nf-mdi-login:before {
    content: "\f841";
  }
  .nf-mdi-login_variant:before {
    content: "\fafb";
  }
  .nf-mdi-logout:before {
    content: "\f842";
  }
  .nf-mdi-logout_variant:before {
    content: "\fafc";
  }
  .nf-mdi-looks:before {
    content: "\f843";
  }
  .nf-mdi-loop:before {
    content: "\fbe9";
  }
  .nf-mdi-loupe:before {
    content: "\f844";
  }
  .nf-mdi-lumx:before {
    content: "\f845";
  }
  .nf-mdi-magnet:before {
    content: "\f846";
  }
  .nf-mdi-magnet_on:before {
    content: "\f847";
  }
  .nf-mdi-magnify:before {
    content: "\f848";
  }
  .nf-mdi-magnify_minus:before {
    content: "\f849";
  }
  .nf-mdi-magnify_minus_outline:before {
    content: "\fbea";
  }
  .nf-mdi-magnify_plus:before {
    content: "\f84a";
  }
  .nf-mdi-magnify_plus_outline:before {
    content: "\fbeb";
  }
  .nf-mdi-mail_ru:before {
    content: "\f84b";
  }
  .nf-mdi-mailbox:before {
    content: "\fbec";
  }
  .nf-mdi-map:before {
    content: "\f84c";
  }
  .nf-mdi-map_marker:before {
    content: "\f84d";
  }
  .nf-mdi-map_marker_circle:before {
    content: "\f84e";
  }
  .nf-mdi-map_marker_minus:before {
    content: "\fb4f";
  }
  .nf-mdi-map_marker_multiple:before {
    content: "\f84f";
  }
  .nf-mdi-map_marker_off:before {
    content: "\f850";
  }
  .nf-mdi-map_marker_outline:before {
    content: "\fcd7";
  }
  .nf-mdi-map_marker_plus:before {
    content: "\fb50";
  }
  .nf-mdi-map_marker_radius:before {
    content: "\f851";
  }
  .nf-mdi-margin:before {
    content: "\f852";
  }
  .nf-mdi-markdown:before {
    content: "\f853";
  }
  .nf-mdi-marker:before {
    content: "\fb51";
  }
  .nf-mdi-marker_check:before {
    content: "\f854";
  }
  .nf-mdi-martini:before {
    content: "\f855";
  }
  .nf-mdi-material_ui:before {
    content: "\f856";
  }
  .nf-mdi-math_compass:before {
    content: "\f857";
  }
  .nf-mdi-matrix:before {
    content: "\fb27";
  }
  .nf-mdi-maxcdn:before {
    content: "\f858";
  }
  .nf-mdi-medical_bag:before {
    content: "\fbed";
  }
  .nf-mdi-medium:before {
    content: "\f859";
  }
  .nf-mdi-memory:before {
    content: "\f85a";
  }
  .nf-mdi-menu:before {
    content: "\f85b";
  }
  .nf-mdi-menu_down:before {
    content: "\f85c";
  }
  .nf-mdi-menu_down_outline:before {
    content: "\fbb4";
  }
  .nf-mdi-menu_left:before {
    content: "\f85d";
  }
  .nf-mdi-menu_right:before {
    content: "\f85e";
  }
  .nf-mdi-menu_up:before {
    content: "\f85f";
  }
  .nf-mdi-menu_up_outline:before {
    content: "\fbb5";
  }
  .nf-mdi-message:before {
    content: "\f860";
  }
  .nf-mdi-message_alert:before {
    content: "\f861";
  }
  .nf-mdi-message_bulleted:before {
    content: "\fba0";
  }
  .nf-mdi-message_bulleted_off:before {
    content: "\fba1";
  }
  .nf-mdi-message_draw:before {
    content: "\f862";
  }
  .nf-mdi-message_image:before {
    content: "\f863";
  }
  .nf-mdi-message_outline:before {
    content: "\f864";
  }
  .nf-mdi-message_plus:before {
    content: "\fb52";
  }
  .nf-mdi-message_processing:before {
    content: "\f865";
  }
  .nf-mdi-message_reply:before {
    content: "\f866";
  }
  .nf-mdi-message_reply_text:before {
    content: "\f867";
  }
  .nf-mdi-message_settings:before {
    content: "\fbee";
  }
  .nf-mdi-message_settings_variant:before {
    content: "\fbef";
  }
  .nf-mdi-message_text:before {
    content: "\f868";
  }
  .nf-mdi-message_text_outline:before {
    content: "\f869";
  }
  .nf-mdi-message_video:before {
    content: "\f86a";
  }
  .nf-mdi-meteor:before {
    content: "\fb28";
  }
  .nf-mdi-metronome:before {
    content: "\fcd8";
  }
  .nf-mdi-metronome_tick:before {
    content: "\fcd9";
  }
  .nf-mdi-micro_sd:before {
    content: "\fcda";
  }
  .nf-mdi-microphone:before {
    content: "\f86b";
  }
  .nf-mdi-microphone_off:before {
    content: "\f86c";
  }
  .nf-mdi-microphone_outline:before {
    content: "\f86d";
  }
  .nf-mdi-microphone_settings:before {
    content: "\f86e";
  }
  .nf-mdi-microphone_variant:before {
    content: "\f86f";
  }
  .nf-mdi-microphone_variant_off:before {
    content: "\f870";
  }
  .nf-mdi-microscope:before {
    content: "\fb53";
  }
  .nf-mdi-microsoft:before {
    content: "\f871";
  }
  .nf-mdi-minecraft:before {
    content: "\f872";
  }
  .nf-mdi-minus:before {
    content: "\f873";
  }
  .nf-mdi-minus_box:before {
    content: "\f874";
  }
  .nf-mdi-minus_box_outline:before {
    content: "\fbf0";
  }
  .nf-mdi-minus_circle:before {
    content: "\f875";
  }
  .nf-mdi-minus_circle_outline:before {
    content: "\f876";
  }
  .nf-mdi-minus_network:before {
    content: "\f877";
  }
  .nf-mdi-mixcloud:before {
    content: "\fb29";
  }
  .nf-mdi-mixer:before {
    content: "\fcdb";
  }
  .nf-mdi-monitor:before {
    content: "\f878";
  }
  .nf-mdi-monitor_multiple:before {
    content: "\f879";
  }
  .nf-mdi-more:before {
    content: "\f87a";
  }
  .nf-mdi-motorbike:before {
    content: "\f87b";
  }
  .nf-mdi-mouse:before {
    content: "\f87c";
  }
  .nf-mdi-mouse_off:before {
    content: "\f87d";
  }
  .nf-mdi-mouse_variant:before {
    content: "\f87e";
  }
  .nf-mdi-mouse_variant_off:before {
    content: "\f87f";
  }
  .nf-mdi-move_resize:before {
    content: "\fb54";
  }
  .nf-mdi-move_resize_variant:before {
    content: "\fb55";
  }
  .nf-mdi-movie:before {
    content: "\f880";
  }
  .nf-mdi-movie_roll:before {
    content: "\fcdc";
  }
  .nf-mdi-multiplication:before {
    content: "\f881";
  }
  .nf-mdi-multiplication_box:before {
    content: "\f882";
  }
  .nf-mdi-mushroom:before {
    content: "\fcdd";
  }
  .nf-mdi-mushroom_outline:before {
    content: "\fcde";
  }
  .nf-mdi-music:before {
    content: "\fc58";
  }
  .nf-mdi-music_box:before {
    content: "\f883";
  }
  .nf-mdi-music_box_outline:before {
    content: "\f884";
  }
  .nf-mdi-music_circle:before {
    content: "\f885";
  }
  .nf-mdi-music_note:before {
    content: "\f886";
  }
  .nf-mdi-music_note_bluetooth:before {
    content: "\fafd";
  }
  .nf-mdi-music_note_bluetooth_off:before {
    content: "\fafe";
  }
  .nf-mdi-music_note_eighth:before {
    content: "\f887";
  }
  .nf-mdi-music_note_half:before {
    content: "\f888";
  }
  .nf-mdi-music_note_off:before {
    content: "\f889";
  }
  .nf-mdi-music_note_quarter:before {
    content: "\f88a";
  }
  .nf-mdi-music_note_sixteenth:before {
    content: "\f88b";
  }
  .nf-mdi-music_note_whole:before {
    content: "\f88c";
  }
  .nf-mdi-music_off:before {
    content: "\fc59";
  }
  .nf-mdi-nature:before {
    content: "\f88d";
  }
  .nf-mdi-nature_people:before {
    content: "\f88e";
  }
  .nf-mdi-navigation:before {
    content: "\f88f";
  }
  .nf-mdi-near_me:before {
    content: "\facc";
  }
  .nf-mdi-needle:before {
    content: "\f890";
  }
  .nf-mdi-nest_protect:before {
    content: "\f891";
  }
  .nf-mdi-nest_thermostat:before {
    content: "\f892";
  }
  .nf-mdi-netflix:before {
    content: "\fc44";
  }
  .nf-mdi-network:before {
    content: "\fbf1";
  }
  .nf-mdi-new_box:before {
    content: "\f893";
  }
  .nf-mdi-newspaper:before {
    content: "\f894";
  }
  .nf-mdi-nfc:before {
    content: "\f895";
  }
  .nf-mdi-nfc_tap:before {
    content: "\f896";
  }
  .nf-mdi-nfc_variant:before {
    content: "\f897";
  }
  .nf-mdi-ninja:before {
    content: "\fc72";
  }
  .nf-mdi-nintendo_switch:before {
    content: "\fcdf";
  }
  .nf-mdi-nodejs:before {
    content: "\f898";
  }
  .nf-mdi-note:before {
    content: "\f899";
  }
  .nf-mdi-note_multiple:before {
    content: "\fbb6";
  }
  .nf-mdi-note_multiple_outline:before {
    content: "\fbb7";
  }
  .nf-mdi-note_outline:before {
    content: "\f89a";
  }
  .nf-mdi-note_plus:before {
    content: "\f89b";
  }
  .nf-mdi-note_plus_outline:before {
    content: "\f89c";
  }
  .nf-mdi-note_text:before {
    content: "\f89d";
  }
  .nf-mdi-notebook:before {
    content: "\fd2c";
  }
  .nf-mdi-notification_clear_all:before {
    content: "\f89e";
  }
  .nf-mdi-npm:before {
    content: "\fbf5";
  }
  .nf-mdi-nuke:before {
    content: "\fba2";
  }
  .nf-mdi-null:before {
    content: "\fce0";
  }
  .nf-mdi-numeric:before {
    content: "\f89f";
  }
  .nf-mdi-numeric_0_box:before {
    content: "\f8a0";
  }
  .nf-mdi-numeric_0_box_multiple_outline:before {
    content: "\f8a1";
  }
  .nf-mdi-numeric_0_box_outline:before {
    content: "\f8a2";
  }
  .nf-mdi-numeric_1_box:before {
    content: "\f8a3";
  }
  .nf-mdi-numeric_1_box_multiple_outline:before {
    content: "\f8a4";
  }
  .nf-mdi-numeric_1_box_outline:before {
    content: "\f8a5";
  }
  .nf-mdi-numeric_2_box:before {
    content: "\f8a6";
  }
  .nf-mdi-numeric_2_box_multiple_outline:before {
    content: "\f8a7";
  }
  .nf-mdi-numeric_2_box_outline:before {
    content: "\f8a8";
  }
  .nf-mdi-numeric_3_box:before {
    content: "\f8a9";
  }
  .nf-mdi-numeric_3_box_multiple_outline:before {
    content: "\f8aa";
  }
  .nf-mdi-numeric_3_box_outline:before {
    content: "\f8ab";
  }
  .nf-mdi-numeric_4_box:before {
    content: "\f8ac";
  }
  .nf-mdi-numeric_4_box_multiple_outline:before {
    content: "\f8ad";
  }
  .nf-mdi-numeric_4_box_outline:before {
    content: "\f8ae";
  }
  .nf-mdi-numeric_5_box:before {
    content: "\f8af";
  }
  .nf-mdi-numeric_5_box_multiple_outline:before {
    content: "\f8b0";
  }
  .nf-mdi-numeric_5_box_outline:before {
    content: "\f8b1";
  }
  .nf-mdi-numeric_6_box:before {
    content: "\f8b2";
  }
  .nf-mdi-numeric_6_box_multiple_outline:before {
    content: "\f8b3";
  }
  .nf-mdi-numeric_6_box_outline:before {
    content: "\f8b4";
  }
  .nf-mdi-numeric_7_box:before {
    content: "\f8b5";
  }
  .nf-mdi-numeric_7_box_multiple_outline:before {
    content: "\f8b6";
  }
  .nf-mdi-numeric_7_box_outline:before {
    content: "\f8b7";
  }
  .nf-mdi-numeric_8_box:before {
    content: "\f8b8";
  }
  .nf-mdi-numeric_8_box_multiple_outline:before {
    content: "\f8b9";
  }
  .nf-mdi-numeric_8_box_outline:before {
    content: "\f8ba";
  }
  .nf-mdi-numeric_9_box:before {
    content: "\f8bb";
  }
  .nf-mdi-numeric_9_box_multiple_outline:before {
    content: "\f8bc";
  }
  .nf-mdi-numeric_9_box_outline:before {
    content: "\f8bd";
  }
  .nf-mdi-numeric_9_plus_box:before {
    content: "\f8be";
  }
  .nf-mdi-numeric_9_plus_box_multiple_outline:before {
    content: "\f8bf";
  }
  .nf-mdi-numeric_9_plus_box_outline:before {
    content: "\f8c0";
  }
  .nf-mdi-nut:before {
    content: "\fbf6";
  }
  .nf-mdi-nutrition:before {
    content: "\f8c1";
  }
  .nf-mdi-oar:before {
    content: "\fb7a";
  }
  .nf-mdi-octagon:before {
    content: "\f8c2";
  }
  .nf-mdi-octagon_outline:before {
    content: "\f8c3";
  }
  .nf-mdi-octagram:before {
    content: "\fbf7";
  }
  .nf-mdi-octagram_outline:before {
    content: "\fc73";
  }
  .nf-mdi-odnoklassniki:before {
    content: "\f8c4";
  }
  .nf-mdi-office:before {
    content: "\f8c5";
  }
  .nf-mdi-oil:before {
    content: "\f8c6";
  }
  .nf-mdi-oil_temperature:before {
    content: "\f8c7";
  }
  .nf-mdi-omega:before {
    content: "\f8c8";
  }
  .nf-mdi-onedrive:before {
    content: "\f8c9";
  }
  .nf-mdi-onenote:before {
    content: "\fc45";
  }
  .nf-mdi-opacity:before {
    content: "\facb";
  }
  .nf-mdi-open_in_app:before {
    content: "\f8ca";
  }
  .nf-mdi-open_in_new:before {
    content: "\f8cb";
  }
  .nf-mdi-openid:before {
    content: "\f8cc";
  }
  .nf-mdi-opera:before {
    content: "\f8cd";
  }
  .nf-mdi-orbit:before {
    content: "\f517";
  }
  .nf-mdi-ornament:before {
    content: "\f8ce";
  }
  .nf-mdi-ornament_variant:before {
    content: "\f8cf";
  }
  .nf-mdi-owl:before {
    content: "\f8d1";
  }
  .nf-mdi-package:before {
    content: "\f8d2";
  }
  .nf-mdi-package_down:before {
    content: "\f8d3";
  }
  .nf-mdi-package_up:before {
    content: "\f8d4";
  }
  .nf-mdi-package_variant:before {
    content: "\f8d5";
  }
  .nf-mdi-package_variant_closed:before {
    content: "\f8d6";
  }
  .nf-mdi-page_first:before {
    content: "\faff";
  }
  .nf-mdi-page_last:before {
    content: "\fb00";
  }
  .nf-mdi-page_layout_body:before {
    content: "\fbf8";
  }
  .nf-mdi-page_layout_footer:before {
    content: "\fbf9";
  }
  .nf-mdi-page_layout_header:before {
    content: "\fbfa";
  }
  .nf-mdi-page_layout_sidebar_left:before {
    content: "\fbfb";
  }
  .nf-mdi-page_layout_sidebar_right:before {
    content: "\fbfc";
  }
  .nf-mdi-palette:before {
    content: "\f8d7";
  }
  .nf-mdi-palette_advanced:before {
    content: "\f8d8";
  }
  .nf-mdi-panda:before {
    content: "\f8d9";
  }
  .nf-mdi-pandora:before {
    content: "\f8da";
  }
  .nf-mdi-panorama:before {
    content: "\f8db";
  }
  .nf-mdi-panorama_fisheye:before {
    content: "\f8dc";
  }
  .nf-mdi-panorama_horizontal:before {
    content: "\f8dd";
  }
  .nf-mdi-panorama_vertical:before {
    content: "\f8de";
  }
  .nf-mdi-panorama_wide_angle:before {
    content: "\f8df";
  }
  .nf-mdi-paper_cut_vertical:before {
    content: "\f8e0";
  }
  .nf-mdi-paperclip:before {
    content: "\f8e1";
  }
  .nf-mdi-parking:before {
    content: "\f8e2";
  }
  .nf-mdi-passport:before {
    content: "\fce1";
  }
  .nf-mdi-pause:before {
    content: "\f8e3";
  }
  .nf-mdi-pause_circle:before {
    content: "\f8e4";
  }
  .nf-mdi-pause_circle_outline:before {
    content: "\f8e5";
  }
  .nf-mdi-pause_octagon:before {
    content: "\f8e6";
  }
  .nf-mdi-pause_octagon_outline:before {
    content: "\f8e7";
  }
  .nf-mdi-paw:before {
    content: "\f8e8";
  }
  .nf-mdi-paw_off:before {
    content: "\fb56";
  }
  .nf-mdi-pen:before {
    content: "\f8e9";
  }
  .nf-mdi-pencil:before {
    content: "\f8ea";
  }
  .nf-mdi-pencil_box:before {
    content: "\f8eb";
  }
  .nf-mdi-pencil_box_outline:before {
    content: "\f8ec";
  }
  .nf-mdi-pencil_circle:before {
    content: "\fbfd";
  }
  .nf-mdi-pencil_circle_outline:before {
    content: "\fc74";
  }
  .nf-mdi-pencil_lock:before {
    content: "\f8ed";
  }
  .nf-mdi-pencil_off:before {
    content: "\f8ee";
  }
  .nf-mdi-pentagon:before {
    content: "\fbfe";
  }
  .nf-mdi-pentagon_outline:before {
    content: "\fbff";
  }
  .nf-mdi-percent:before {
    content: "\f8ef";
  }
  .nf-mdi-periodic_table_co2:before {
    content: "\fce2";
  }
  .nf-mdi-periscope:before {
    content: "\fc46";
  }
  .nf-mdi-pharmacy:before {
    content: "\f8f0";
  }
  .nf-mdi-phone:before {
    content: "\f8f1";
  }
  .nf-mdi-phone_bluetooth:before {
    content: "\f8f2";
  }
  .nf-mdi-phone_classic:before {
    content: "\fb01";
  }
  .nf-mdi-phone_forward:before {
    content: "\f8f3";
  }
  .nf-mdi-phone_hangup:before {
    content: "\f8f4";
  }
  .nf-mdi-phone_in_talk:before {
    content: "\f8f5";
  }
  .nf-mdi-phone_incoming:before {
    content: "\f8f6";
  }
  .nf-mdi-phone_locked:before {
    content: "\f8f7";
  }
  .nf-mdi-phone_log:before {
    content: "\f8f8";
  }
  .nf-mdi-phone_minus:before {
    content: "\fb57";
  }
  .nf-mdi-phone_missed:before {
    content: "\f8f9";
  }
  .nf-mdi-phone_outgoing:before {
    content: "\f8fa";
  }
  .nf-mdi-phone_paused:before {
    content: "\f8fb";
  }
  .nf-mdi-phone_plus:before {
    content: "\fb58";
  }
  .nf-mdi-phone_return:before {
    content: "\fd2d";
  }
  .nf-mdi-phone_settings:before {
    content: "\f8fc";
  }
  .nf-mdi-phone_voip:before {
    content: "\f8fd";
  }
  .nf-mdi-pi:before {
    content: "\f8fe";
  }
  .nf-mdi-pi_box:before {
    content: "\f8ff";
  }
  .nf-mdi-piano:before {
    content: "\fb7b";
  }
  .nf-mdi-pig:before {
    content: "\f900";
  }
  .nf-mdi-pill:before {
    content: "\f901";
  }
  .nf-mdi-pillar:before {
    content: "\fc00";
  }
  .nf-mdi-pin:before {
    content: "\f902";
  }
  .nf-mdi-pin_off:before {
    content: "\f903";
  }
  .nf-mdi-pine_tree:before {
    content: "\f904";
  }
  .nf-mdi-pine_tree_box:before {
    content: "\f905";
  }
  .nf-mdi-pinterest:before {
    content: "\f906";
  }
  .nf-mdi-pinterest_box:before {
    content: "\f907";
  }
  .nf-mdi-pipe:before {
    content: "\fce3";
  }
  .nf-mdi-pipe_disconnected:before {
    content: "\fce4";
  }
  .nf-mdi-pistol:before {
    content: "\fc01";
  }
  .nf-mdi-pizza:before {
    content: "\f908";
  }
  .nf-mdi-plane_shield:before {
    content: "\fbb9";
  }
  .nf-mdi-play:before {
    content: "\f909";
  }
  .nf-mdi-play_box_outline:before {
    content: "\f90a";
  }
  .nf-mdi-play_circle:before {
    content: "\f90b";
  }
  .nf-mdi-play_circle_outline:before {
    content: "\f90c";
  }
  .nf-mdi-play_pause:before {
    content: "\f90d";
  }
  .nf-mdi-play_protected_content:before {
    content: "\f90e";
  }
  .nf-mdi-playlist_check:before {
    content: "\fac6";
  }
  .nf-mdi-playlist_minus:before {
    content: "\f90f";
  }
  .nf-mdi-playlist_play:before {
    content: "\f910";
  }
  .nf-mdi-playlist_plus:before {
    content: "\f911";
  }
  .nf-mdi-playlist_remove:before {
    content: "\f912";
  }
  .nf-mdi-playstation:before {
    content: "\f913";
  }
  .nf-mdi-plex:before {
    content: "\fbb8";
  }
  .nf-mdi-plus:before {
    content: "\f914";
  }
  .nf-mdi-plus_box:before {
    content: "\f915";
  }
  .nf-mdi-plus_box_outline:before {
    content: "\fc02";
  }
  .nf-mdi-plus_circle:before {
    content: "\f916";
  }
  .nf-mdi-plus_circle_multiple_outline:before {
    content: "\f917";
  }
  .nf-mdi-plus_circle_outline:before {
    content: "\f918";
  }
  .nf-mdi-plus_network:before {
    content: "\f919";
  }
  .nf-mdi-plus_one:before {
    content: "\f91a";
  }
  .nf-mdi-plus_outline:before {
    content: "\fc03";
  }
  .nf-mdi-pocket:before {
    content: "\f91b";
  }
  .nf-mdi-pokeball:before {
    content: "\f91c";
  }
  .nf-mdi-poker_chip:before {
    content: "\fd2e";
  }
  .nf-mdi-polaroid:before {
    content: "\f91d";
  }
  .nf-mdi-poll:before {
    content: "\f91e";
  }
  .nf-mdi-poll_box:before {
    content: "\f91f";
  }
  .nf-mdi-polymer:before {
    content: "\f920";
  }
  .nf-mdi-pool:before {
    content: "\fb05";
  }
  .nf-mdi-popcorn:before {
    content: "\f921";
  }
  .nf-mdi-pot:before {
    content: "\fb59";
  }
  .nf-mdi-pot_mix:before {
    content: "\fb5a";
  }
  .nf-mdi-pound:before {
    content: "\f922";
  }
  .nf-mdi-pound_box:before {
    content: "\f923";
  }
  .nf-mdi-power:before {
    content: "\f924";
  }
  .nf-mdi-power_plug:before {
    content: "\fba3";
  }
  .nf-mdi-power_plug_off:before {
    content: "\fba4";
  }
  .nf-mdi-power_settings:before {
    content: "\f925";
  }
  .nf-mdi-power_socket:before {
    content: "\f926";
  }
  .nf-mdi-power_socket_eu:before {
    content: "\fce5";
  }
  .nf-mdi-power_socket_uk:before {
    content: "\fce6";
  }
  .nf-mdi-power_socket_us:before {
    content: "\fce7";
  }
  .nf-mdi-prescription:before {
    content: "\fc04";
  }
  .nf-mdi-presentation:before {
    content: "\f927";
  }
  .nf-mdi-presentation_play:before {
    content: "\f928";
  }
  .nf-mdi-printer:before {
    content: "\f929";
  }
  .nf-mdi-printer_3d:before {
    content: "\f92a";
  }
  .nf-mdi-printer_alert:before {
    content: "\f92b";
  }
  .nf-mdi-printer_settings:before {
    content: "\fc05";
  }
  .nf-mdi-priority_high:before {
    content: "\fb02";
  }
  .nf-mdi-priority_low:before {
    content: "\fb03";
  }
  .nf-mdi-professional_hexagon:before {
    content: "\f92c";
  }
  .nf-mdi-projector:before {
    content: "\f92d";
  }
  .nf-mdi-projector_screen:before {
    content: "\f92e";
  }
  .nf-mdi-publish:before {
    content: "\fba5";
  }
  .nf-mdi-pulse:before {
    content: "\f92f";
  }
  .nf-mdi-puzzle:before {
    content: "\f930";
  }
  .nf-mdi-qqchat:before {
    content: "\fb04";
  }
  .nf-mdi-qrcode:before {
    content: "\f931";
  }
  .nf-mdi-qrcode_scan:before {
    content: "\f932";
  }
  .nf-mdi-quadcopter:before {
    content: "\f933";
  }
  .nf-mdi-quality_high:before {
    content: "\f934";
  }
  .nf-mdi-quicktime:before {
    content: "\f935";
  }
  .nf-mdi-radar:before {
    content: "\f936";
  }
  .nf-mdi-radiator:before {
    content: "\f937";
  }
  .nf-mdi-radio:before {
    content: "\f938";
  }
  .nf-mdi-radio_handheld:before {
    content: "\f939";
  }
  .nf-mdi-radio_tower:before {
    content: "\f93a";
  }
  .nf-mdi-radioactive:before {
    content: "\f93b";
  }
  .nf-mdi-radiobox_blank:before {
    content: "\f93c";
  }
  .nf-mdi-radiobox_marked:before {
    content: "\f93d";
  }
  .nf-mdi-raspberrypi:before {
    content: "\f93e";
  }
  .nf-mdi-ray_end:before {
    content: "\f93f";
  }
  .nf-mdi-ray_end_arrow:before {
    content: "\f940";
  }
  .nf-mdi-ray_start:before {
    content: "\f941";
  }
  .nf-mdi-ray_start_arrow:before {
    content: "\f942";
  }
  .nf-mdi-ray_start_end:before {
    content: "\f943";
  }
  .nf-mdi-ray_vertex:before {
    content: "\f944";
  }
  .nf-mdi-react:before {
    content: "\fc06";
  }
  .nf-mdi-read:before {
    content: "\f946";
  }
  .nf-mdi-receipt:before {
    content: "\f948";
  }
  .nf-mdi-record:before {
    content: "\f949";
  }
  .nf-mdi-record_rec:before {
    content: "\f94a";
  }
  .nf-mdi-recycle:before {
    content: "\f94b";
  }
  .nf-mdi-reddit:before {
    content: "\f94c";
  }
  .nf-mdi-redo:before {
    content: "\f94d";
  }
  .nf-mdi-redo_variant:before {
    content: "\f94e";
  }
  .nf-mdi-refresh:before {
    content: "\f94f";
  }
  .nf-mdi-regex:before {
    content: "\f950";
  }
  .nf-mdi-relative_scale:before {
    content: "\f951";
  }
  .nf-mdi-reload:before {
    content: "\f952";
  }
  .nf-mdi-remote:before {
    content: "\f953";
  }
  .nf-mdi-rename_box:before {
    content: "\f954";
  }
  .nf-mdi-reorder_horizontal:before {
    content: "\fb86";
  }
  .nf-mdi-reorder_vertical:before {
    content: "\fb87";
  }
  .nf-mdi-repeat:before {
    content: "\f955";
  }
  .nf-mdi-repeat_off:before {
    content: "\f956";
  }
  .nf-mdi-repeat_once:before {
    content: "\f957";
  }
  .nf-mdi-replay:before {
    content: "\f958";
  }
  .nf-mdi-reply:before {
    content: "\f959";
  }
  .nf-mdi-reply_all:before {
    content: "\f95a";
  }
  .nf-mdi-reproduction:before {
    content: "\f95b";
  }
  .nf-mdi-resize_bottom_right:before {
    content: "\f95c";
  }
  .nf-mdi-responsive:before {
    content: "\f95d";
  }
  .nf-mdi-restart:before {
    content: "\fc07";
  }
  .nf-mdi-restore:before {
    content: "\fba6";
  }
  .nf-mdi-rewind:before {
    content: "\f95e";
  }
  .nf-mdi-rewind_outline:before {
    content: "\fc08";
  }
  .nf-mdi-rhombus:before {
    content: "\fc09";
  }
  .nf-mdi-rhombus_outline:before {
    content: "\fc0a";
  }
  .nf-mdi-ribbon:before {
    content: "\f95f";
  }
  .nf-mdi-rice:before {
    content: "\fce8";
  }
  .nf-mdi-ring:before {
    content: "\fce9";
  }
  .nf-mdi-road:before {
    content: "\f960";
  }
  .nf-mdi-road_variant:before {
    content: "\f961";
  }
  .nf-mdi-robot:before {
    content: "\fba7";
  }
  .nf-mdi-rocket:before {
    content: "\f962";
  }
  .nf-mdi-roomba:before {
    content: "\fc0b";
  }
  .nf-mdi-rotate_3d:before {
    content: "\f963";
  }
  .nf-mdi-rotate_left:before {
    content: "\f964";
  }
  .nf-mdi-rotate_left_variant:before {
    content: "\f965";
  }
  .nf-mdi-rotate_right:before {
    content: "\f966";
  }
  .nf-mdi-rotate_right_variant:before {
    content: "\f967";
  }
  .nf-mdi-rounded_corner:before {
    content: "\fb06";
  }
  .nf-mdi-router_wireless:before {
    content: "\f968";
  }
  .nf-mdi-routes:before {
    content: "\f969";
  }
  .nf-mdi-rowing:before {
    content: "\fb07";
  }
  .nf-mdi-rss:before {
    content: "\f96a";
  }
  .nf-mdi-rss_box:before {
    content: "\f96b";
  }
  .nf-mdi-ruler:before {
    content: "\f96c";
  }
  .nf-mdi-run:before {
    content: "\fc0c";
  }
  .nf-mdi-run_fast:before {
    content: "\f96d";
  }
  .nf-mdi-sale:before {
    content: "\f96e";
  }
  .nf-mdi-sass:before {
    content: "\fcea";
  }
  .nf-mdi-satellite:before {
    content: "\f96f";
  }
  .nf-mdi-satellite_variant:before {
    content: "\f970";
  }
  .nf-mdi-saxophone:before {
    content: "\fb08";
  }
  .nf-mdi-scale:before {
    content: "\f971";
  }
  .nf-mdi-scale_balance:before {
    content: "\fad0";
  }
  .nf-mdi-scale_bathroom:before {
    content: "\f972";
  }
  .nf-mdi-scanner:before {
    content: "\fba9";
  }
  .nf-mdi-school:before {
    content: "\f973";
  }
  .nf-mdi-screen_rotation:before {
    content: "\f974";
  }
  .nf-mdi-screen_rotation_lock:before {
    content: "\f975";
  }
  .nf-mdi-screwdriver:before {
    content: "\f976";
  }
  .nf-mdi-script:before {
    content: "\f977";
  }
  .nf-mdi-sd:before {
    content: "\f978";
  }
  .nf-mdi-seal:before {
    content: "\f979";
  }
  .nf-mdi-search_web:before {
    content: "\fc0d";
  }
  .nf-mdi-seat_flat:before {
    content: "\f97a";
  }
  .nf-mdi-seat_flat_angled:before {
    content: "\f97b";
  }
  .nf-mdi-seat_individual_suite:before {
    content: "\f97c";
  }
  .nf-mdi-seat_legroom_extra:before {
    content: "\f97d";
  }
  .nf-mdi-seat_legroom_normal:before {
    content: "\f97e";
  }
  .nf-mdi-seat_legroom_reduced:before {
    content: "\f97f";
  }
  .nf-mdi-seat_recline_extra:before {
    content: "\f980";
  }
  .nf-mdi-seat_recline_normal:before {
    content: "\f981";
  }
  .nf-mdi-security:before {
    content: "\f982";
  }
  .nf-mdi-security_home:before {
    content: "\fb88";
  }
  .nf-mdi-security_network:before {
    content: "\f983";
  }
  .nf-mdi-select:before {
    content: "\f984";
  }
  .nf-mdi-select_all:before {
    content: "\f985";
  }
  .nf-mdi-select_inverse:before {
    content: "\f986";
  }
  .nf-mdi-select_off:before {
    content: "\f987";
  }
  .nf-mdi-selection:before {
    content: "\f988";
  }
  .nf-mdi-selection_off:before {
    content: "\fc75";
  }
  .nf-mdi-send:before {
    content: "\f989";
  }
  .nf-mdi-send_secure:before {
    content: "\fceb";
  }
  .nf-mdi-serial_port:before {
    content: "\fb5b";
  }
  .nf-mdi-server:before {
    content: "\f98a";
  }
  .nf-mdi-server_minus:before {
    content: "\f98b";
  }
  .nf-mdi-server_network:before {
    content: "\f98c";
  }
  .nf-mdi-server_network_off:before {
    content: "\f98d";
  }
  .nf-mdi-server_off:before {
    content: "\f98e";
  }
  .nf-mdi-server_plus:before {
    content: "\f98f";
  }
  .nf-mdi-server_remove:before {
    content: "\f990";
  }
  .nf-mdi-server_security:before {
    content: "\f991";
  }
  .nf-mdi-set_all:before {
    content: "\fc76";
  }
  .nf-mdi-set_center:before {
    content: "\fc77";
  }
  .nf-mdi-set_center_right:before {
    content: "\fc78";
  }
  .nf-mdi-set_left:before {
    content: "\fc79";
  }
  .nf-mdi-set_left_center:before {
    content: "\fc7a";
  }
  .nf-mdi-set_left_right:before {
    content: "\fc7b";
  }
  .nf-mdi-set_none:before {
    content: "\fc7c";
  }
  .nf-mdi-set_right:before {
    content: "\fc7d";
  }
  .nf-mdi-settings:before {
    content: "\f992";
  }
  .nf-mdi-settings_box:before {
    content: "\f993";
  }
  .nf-mdi-shape:before {
    content: "\fd2f";
  }
  .nf-mdi-shape_circle_plus:before {
    content: "\fb5c";
  }
  .nf-mdi-shape_outline:before {
    content: "\fd30";
  }
  .nf-mdi-shape_plus:before {
    content: "\f994";
  }
  .nf-mdi-shape_polygon_plus:before {
    content: "\fb5d";
  }
  .nf-mdi-shape_rectangle_plus:before {
    content: "\fb5e";
  }
  .nf-mdi-shape_square_plus:before {
    content: "\fb5f";
  }
  .nf-mdi-share:before {
    content: "\f995";
  }
  .nf-mdi-share_variant:before {
    content: "\f996";
  }
  .nf-mdi-shield:before {
    content: "\f997";
  }
  .nf-mdi-shield_half_full:before {
    content: "\fc7e";
  }
  .nf-mdi-shield_outline:before {
    content: "\f998";
  }
  .nf-mdi-ship_wheel:before {
    content: "\fd31";
  }
  .nf-mdi-shopping:before {
    content: "\f999";
  }
  .nf-mdi-shopping_music:before {
    content: "\f99a";
  }
  .nf-mdi-shovel:before {
    content: "\fc0e";
  }
  .nf-mdi-shovel_off:before {
    content: "\fc0f";
  }
  .nf-mdi-shredder:before {
    content: "\f99b";
  }
  .nf-mdi-shuffle:before {
    content: "\f99c";
  }
  .nf-mdi-shuffle_disabled:before {
    content: "\f99d";
  }
  .nf-mdi-shuffle_variant:before {
    content: "\f99e";
  }
  .nf-mdi-sigma:before {
    content: "\f99f";
  }
  .nf-mdi-sigma_lower:before {
    content: "\fb2a";
  }
  .nf-mdi-sign_caution:before {
    content: "\f9a0";
  }
  .nf-mdi-sign_direction:before {
    content: "\fc7f";
  }
  .nf-mdi-sign_text:before {
    content: "\fc80";
  }
  .nf-mdi-signal:before {
    content: "\f9a1";
  }
  .nf-mdi-signal_2g:before {
    content: "\fc10";
  }
  .nf-mdi-signal_3g:before {
    content: "\fc11";
  }
  .nf-mdi-signal_4g:before {
    content: "\fc12";
  }
  .nf-mdi-signal_hspa:before {
    content: "\fc13";
  }
  .nf-mdi-signal_hspa_plus:before {
    content: "\fc14";
  }
  .nf-mdi-signal_off:before {
    content: "\fc81";
  }
  .nf-mdi-signal_variant:before {
    content: "\fb09";
  }
  .nf-mdi-silverware:before {
    content: "\f9a2";
  }
  .nf-mdi-silverware_fork:before {
    content: "\f9a3";
  }
  .nf-mdi-silverware_spoon:before {
    content: "\f9a4";
  }
  .nf-mdi-silverware_variant:before {
    content: "\f9a5";
  }
  .nf-mdi-sim:before {
    content: "\f9a6";
  }
  .nf-mdi-sim_alert:before {
    content: "\f9a7";
  }
  .nf-mdi-sim_off:before {
    content: "\f9a8";
  }
  .nf-mdi-sitemap:before {
    content: "\f9a9";
  }
  .nf-mdi-skip_backward:before {
    content: "\f9aa";
  }
  .nf-mdi-skip_forward:before {
    content: "\f9ab";
  }
  .nf-mdi-skip_next:before {
    content: "\f9ac";
  }
  .nf-mdi-skip_next_circle:before {
    content: "\fb60";
  }
  .nf-mdi-skip_next_circle_outline:before {
    content: "\fb61";
  }
  .nf-mdi-skip_previous:before {
    content: "\f9ad";
  }
  .nf-mdi-skip_previous_circle:before {
    content: "\fb62";
  }
  .nf-mdi-skip_previous_circle_outline:before {
    content: "\fb63";
  }
  .nf-mdi-skull:before {
    content: "\fb8a";
  }
  .nf-mdi-skype:before {
    content: "\f9ae";
  }
  .nf-mdi-skype_business:before {
    content: "\f9af";
  }
  .nf-mdi-slack:before {
    content: "\f9b0";
  }
  .nf-mdi-sleep:before {
    content: "\f9b1";
  }
  .nf-mdi-sleep_off:before {
    content: "\f9b2";
  }
  .nf-mdi-smoking:before {
    content: "\f9b3";
  }
  .nf-mdi-smoking_off:before {
    content: "\f9b4";
  }
  .nf-mdi-snapchat:before {
    content: "\f9b5";
  }
  .nf-mdi-snowflake:before {
    content: "\fc15";
  }
  .nf-mdi-snowman:before {
    content: "\f9b6";
  }
  .nf-mdi-soccer:before {
    content: "\f9b7";
  }
  .nf-mdi-soccer_field:before {
    content: "\fd32";
  }
  .nf-mdi-sofa:before {
    content: "\f9b8";
  }
  .nf-mdi-solid:before {
    content: "\fb8b";
  }
  .nf-mdi-sort:before {
    content: "\f9b9";
  }
  .nf-mdi-sort_alphabetical:before {
    content: "\f9ba";
  }
  .nf-mdi-sort_ascending:before {
    content: "\f9bb";
  }
  .nf-mdi-sort_descending:before {
    content: "\f9bc";
  }
  .nf-mdi-sort_numeric:before {
    content: "\f9bd";
  }
  .nf-mdi-sort_variant:before {
    content: "\f9be";
  }
  .nf-mdi-soundcloud:before {
    content: "\f9bf";
  }
  .nf-mdi-source_branch:before {
    content: "\fb2b";
  }
  .nf-mdi-source_commit:before {
    content: "\fc16";
  }
  .nf-mdi-source_commit_end:before {
    content: "\fc17";
  }
  .nf-mdi-source_commit_end_local:before {
    content: "\fc18";
  }
  .nf-mdi-source_commit_local:before {
    content: "\fc19";
  }
  .nf-mdi-source_commit_next_local:before {
    content: "\fc1a";
  }
  .nf-mdi-source_commit_start:before {
    content: "\fc1b";
  }
  .nf-mdi-source_commit_start_next_local:before {
    content: "\fc1c";
  }
  .nf-mdi-source_fork:before {
    content: "\f9c0";
  }
  .nf-mdi-source_merge:before {
    content: "\fb2c";
  }
  .nf-mdi-source_pull:before {
    content: "\f9c1";
  }
  .nf-mdi-soy_sauce:before {
    content: "\fcec";
  }
  .nf-mdi-speaker:before {
    content: "\f9c2";
  }
  .nf-mdi-speaker_off:before {
    content: "\f9c3";
  }
  .nf-mdi-speaker_wireless:before {
    content: "\fc1d";
  }
  .nf-mdi-speedometer:before {
    content: "\f9c4";
  }
  .nf-mdi-spellcheck:before {
    content: "\f9c5";
  }
  .nf-mdi-spotify:before {
    content: "\f9c6";
  }
  .nf-mdi-spotlight:before {
    content: "\f9c7";
  }
  .nf-mdi-spotlight_beam:before {
    content: "\f9c8";
  }
  .nf-mdi-spray:before {
    content: "\fb64";
  }
  .nf-mdi-square:before {
    content: "\fc62";
  }
  .nf-mdi-square_inc:before {
    content: "\f9c9";
  }
  .nf-mdi-square_inc_cash:before {
    content: "\f9ca";
  }
  .nf-mdi-square_outline:before {
    content: "\fc61";
  }
  .nf-mdi-square_root:before {
    content: "\fc82";
  }
  .nf-mdi-stack_overflow:before {
    content: "\f9cb";
  }
  .nf-mdi-stackexchange:before {
    content: "\fb0a";
  }
  .nf-mdi-stadium:before {
    content: "\fc1e";
  }
  .nf-mdi-stairs:before {
    content: "\f9cc";
  }
  .nf-mdi-standard_definition:before {
    content: "\fced";
  }
  .nf-mdi-star:before {
    content: "\f9cd";
  }
  .nf-mdi-star_circle:before {
    content: "\f9ce";
  }
  .nf-mdi-star_half:before {
    content: "\f9cf";
  }
  .nf-mdi-star_off:before {
    content: "\f9d0";
  }
  .nf-mdi-star_outline:before {
    content: "\f9d1";
  }
  .nf-mdi-steam:before {
    content: "\f9d2";
  }
  .nf-mdi-steering:before {
    content: "\f9d3";
  }
  .nf-mdi-step_backward:before {
    content: "\f9d4";
  }
  .nf-mdi-step_backward_2:before {
    content: "\f9d5";
  }
  .nf-mdi-step_forward:before {
    content: "\f9d6";
  }
  .nf-mdi-step_forward_2:before {
    content: "\f9d7";
  }
  .nf-mdi-stethoscope:before {
    content: "\f9d8";
  }
  .nf-mdi-sticker:before {
    content: "\facf";
  }
  .nf-mdi-sticker_emoji:before {
    content: "\fc83";
  }
  .nf-mdi-stocking:before {
    content: "\f9d9";
  }
  .nf-mdi-stop:before {
    content: "\f9da";
  }
  .nf-mdi-stop_circle:before {
    content: "\fb65";
  }
  .nf-mdi-stop_circle_outline:before {
    content: "\fb66";
  }
  .nf-mdi-store:before {
    content: "\f9db";
  }
  .nf-mdi-store_24_hour:before {
    content: "\f9dc";
  }
  .nf-mdi-stove:before {
    content: "\f9dd";
  }
  .nf-mdi-subdirectory_arrow_left:before {
    content: "\fb0b";
  }
  .nf-mdi-subdirectory_arrow_right:before {
    content: "\fb0c";
  }
  .nf-mdi-subway:before {
    content: "\fbaa";
  }
  .nf-mdi-subway_variant:before {
    content: "\f9de";
  }
  .nf-mdi-summit:before {
    content: "\fc84";
  }
  .nf-mdi-sunglasses:before {
    content: "\f9df";
  }
  .nf-mdi-surround_sound:before {
    content: "\fac4";
  }
  .nf-mdi-surround_sound_2_0:before {
    content: "\fcee";
  }
  .nf-mdi-surround_sound_3_1:before {
    content: "\fcef";
  }
  .nf-mdi-surround_sound_5_1:before {
    content: "\fcf0";
  }
  .nf-mdi-surround_sound_7_1:before {
    content: "\fcf1";
  }
  .nf-mdi-svg:before {
    content: "\fc1f";
  }
  .nf-mdi-swap_horizontal:before {
    content: "\f9e0";
  }
  .nf-mdi-swap_vertical:before {
    content: "\f9e1";
  }
  .nf-mdi-swim:before {
    content: "\f9e2";
  }
  .nf-mdi-switch:before {
    content: "\f9e3";
  }
  .nf-mdi-sword:before {
    content: "\f9e4";
  }
  .nf-mdi-sword_cross:before {
    content: "\fc85";
  }
  .nf-mdi-sync:before {
    content: "\f9e5";
  }
  .nf-mdi-sync_alert:before {
    content: "\f9e6";
  }
  .nf-mdi-sync_off:before {
    content: "\f9e7";
  }
  .nf-mdi-tab:before {
    content: "\f9e8";
  }
  .nf-mdi-tab_plus:before {
    content: "\fc5a";
  }
  .nf-mdi-tab_unselected:before {
    content: "\f9e9";
  }
  .nf-mdi-table:before {
    content: "\f9ea";
  }
  .nf-mdi-table_column:before {
    content: "\fd33";
  }
  .nf-mdi-table_column_plus_after:before {
    content: "\f9eb";
  }
  .nf-mdi-table_column_plus_before:before {
    content: "\f9ec";
  }
  .nf-mdi-table_column_remove:before {
    content: "\f9ed";
  }
  .nf-mdi-table_column_width:before {
    content: "\f9ee";
  }
  .nf-mdi-table_edit:before {
    content: "\f9ef";
  }
  .nf-mdi-table_large:before {
    content: "\f9f0";
  }
  .nf-mdi-table_of_contents:before {
    content: "\fd34";
  }
  .nf-mdi-table_row:before {
    content: "\fd35";
  }
  .nf-mdi-table_row_height:before {
    content: "\f9f1";
  }
  .nf-mdi-table_row_plus_after:before {
    content: "\f9f2";
  }
  .nf-mdi-table_row_plus_before:before {
    content: "\f9f3";
  }
  .nf-mdi-table_row_remove:before {
    content: "\f9f4";
  }
  .nf-mdi-table_settings:before {
    content: "\fd36";
  }
  .nf-mdi-tablet:before {
    content: "\f9f5";
  }
  .nf-mdi-tablet_android:before {
    content: "\f9f6";
  }
  .nf-mdi-tablet_ipad:before {
    content: "\f9f7";
  }
  .nf-mdi-taco:before {
    content: "\fc60";
  }
  .nf-mdi-tag:before {
    content: "\f9f8";
  }
  .nf-mdi-tag_faces:before {
    content: "\f9f9";
  }
  .nf-mdi-tag_heart:before {
    content: "\fb89";
  }
  .nf-mdi-tag_multiple:before {
    content: "\f9fa";
  }
  .nf-mdi-tag_outline:before {
    content: "\f9fb";
  }
  .nf-mdi-tag_plus:before {
    content: "\fc20";
  }
  .nf-mdi-tag_remove:before {
    content: "\fc21";
  }
  .nf-mdi-tag_text_outline:before {
    content: "\f9fc";
  }
  .nf-mdi-target:before {
    content: "\f9fd";
  }
  .nf-mdi-taxi:before {
    content: "\f9fe";
  }
  .nf-mdi-teamviewer:before {
    content: "\f9ff";
  }
  .nf-mdi-telegram:before {
    content: "\fa00";
  }
  .nf-mdi-television:before {
    content: "\fa01";
  }
  .nf-mdi-television_box:before {
    content: "\fd37";
  }
  .nf-mdi-television_classic:before {
    content: "\fcf2";
  }
  .nf-mdi-television_classic_off:before {
    content: "\fd38";
  }
  .nf-mdi-television_guide:before {
    content: "\fa02";
  }
  .nf-mdi-television_off:before {
    content: "\fd39";
  }
  .nf-mdi-temperature_celsius:before {
    content: "\fa03";
  }
  .nf-mdi-temperature_fahrenheit:before {
    content: "\fa04";
  }
  .nf-mdi-temperature_kelvin:before {
    content: "\fa05";
  }
  .nf-mdi-tennis:before {
    content: "\fa06";
  }
  .nf-mdi-tent:before {
    content: "\fa07";
  }
  .nf-mdi-terrain:before {
    content: "\fa08";
  }
  .nf-mdi-test_tube:before {
    content: "\fb67";
  }
  .nf-mdi-text_shadow:before {
    content: "\fb68";
  }
  .nf-mdi-text_to_speech:before {
    content: "\fa09";
  }
  .nf-mdi-text_to_speech_off:before {
    content: "\fa0a";
  }
  .nf-mdi-textbox:before {
    content: "\fb0d";
  }
  .nf-mdi-textbox_password:before {
    content: "\fcf3";
  }
  .nf-mdi-texture:before {
    content: "\fa0b";
  }
  .nf-mdi-theater:before {
    content: "\fa0c";
  }
  .nf-mdi-theme_light_dark:before {
    content: "\fa0d";
  }
  .nf-mdi-thermometer:before {
    content: "\fa0e";
  }
  .nf-mdi-thermometer_lines:before {
    content: "\fa0f";
  }
  .nf-mdi-thought_bubble:before {
    content: "\fcf4";
  }
  .nf-mdi-thought_bubble_outline:before {
    content: "\fcf5";
  }
  .nf-mdi-thumb_down:before {
    content: "\fa10";
  }
  .nf-mdi-thumb_down_outline:before {
    content: "\fa11";
  }
  .nf-mdi-thumb_up:before {
    content: "\fa12";
  }
  .nf-mdi-thumb_up_outline:before {
    content: "\fa13";
  }
  .nf-mdi-thumbs_up_down:before {
    content: "\fa14";
  }
  .nf-mdi-ticket:before {
    content: "\fa15";
  }
  .nf-mdi-ticket_account:before {
    content: "\fa16";
  }
  .nf-mdi-ticket_confirmation:before {
    content: "\fa17";
  }
  .nf-mdi-ticket_percent:before {
    content: "\fc22";
  }
  .nf-mdi-tie:before {
    content: "\fa18";
  }
  .nf-mdi-tilde:before {
    content: "\fc23";
  }
  .nf-mdi-timelapse:before {
    content: "\fa19";
  }
  .nf-mdi-timer:before {
    content: "\fa1a";
  }
  .nf-mdi-timer_10:before {
    content: "\fa1b";
  }
  .nf-mdi-timer_3:before {
    content: "\fa1c";
  }
  .nf-mdi-timer_off:before {
    content: "\fa1d";
  }
  .nf-mdi-timer_sand:before {
    content: "\fa1e";
  }
  .nf-mdi-timer_sand_empty:before {
    content: "\fbab";
  }
  .nf-mdi-timer_sand_full:before {
    content: "\fc8a";
  }
  .nf-mdi-timetable:before {
    content: "\fa1f";
  }
  .nf-mdi-toggle_switch:before {
    content: "\fa20";
  }
  .nf-mdi-toggle_switch_off:before {
    content: "\fa21";
  }
  .nf-mdi-tooltip:before {
    content: "\fa22";
  }
  .nf-mdi-tooltip_edit:before {
    content: "\fa23";
  }
  .nf-mdi-tooltip_image:before {
    content: "\fa24";
  }
  .nf-mdi-tooltip_outline:before {
    content: "\fa25";
  }
  .nf-mdi-tooltip_outline_plus:before {
    content: "\fa26";
  }
  .nf-mdi-tooltip_text:before {
    content: "\fa27";
  }
  .nf-mdi-tooth:before {
    content: "\fa28";
  }
  .nf-mdi-tor:before {
    content: "\fa29";
  }
  .nf-mdi-tower_beach:before {
    content: "\fb7f";
  }
  .nf-mdi-tower_fire:before {
    content: "\fb80";
  }
  .nf-mdi-towing:before {
    content: "\fd3a";
  }
  .nf-mdi-trackpad:before {
    content: "\fcf6";
  }
  .nf-mdi-traffic_light:before {
    content: "\fa2a";
  }
  .nf-mdi-train:before {
    content: "\fa2b";
  }
  .nf-mdi-tram:before {
    content: "\fa2c";
  }
  .nf-mdi-transcribe:before {
    content: "\fa2d";
  }
  .nf-mdi-transcribe_close:before {
    content: "\fa2e";
  }
  .nf-mdi-transfer:before {
    content: "\fa2f";
  }
  .nf-mdi-transit_transfer:before {
    content: "\fbac";
  }
  .nf-mdi-translate:before {
    content: "\fac9";
  }
  .nf-mdi-treasure_chest:before {
    content: "\fc24";
  }
  .nf-mdi-tree:before {
    content: "\fa30";
  }
  .nf-mdi-trello:before {
    content: "\fa31";
  }
  .nf-mdi-trending_down:before {
    content: "\fa32";
  }
  .nf-mdi-trending_neutral:before {
    content: "\fa33";
  }
  .nf-mdi-trending_up:before {
    content: "\fa34";
  }
  .nf-mdi-triangle:before {
    content: "\fa35";
  }
  .nf-mdi-triangle_outline:before {
    content: "\fa36";
  }
  .nf-mdi-trophy:before {
    content: "\fa37";
  }
  .nf-mdi-trophy_award:before {
    content: "\fa38";
  }
  .nf-mdi-trophy_outline:before {
    content: "\fa39";
  }
  .nf-mdi-trophy_variant:before {
    content: "\fa3a";
  }
  .nf-mdi-trophy_variant_outline:before {
    content: "\fa3b";
  }
  .nf-mdi-truck:before {
    content: "\fa3c";
  }
  .nf-mdi-truck_delivery:before {
    content: "\fa3d";
  }
  .nf-mdi-truck_fast:before {
    content: "\fc86";
  }
  .nf-mdi-truck_trailer:before {
    content: "\fc25";
  }
  .nf-mdi-tshirt_crew:before {
    content: "\fa3e";
  }
  .nf-mdi-tshirt_v:before {
    content: "\fa3f";
  }
  .nf-mdi-tumblr:before {
    content: "\fa40";
  }
  .nf-mdi-tumblr_reblog:before {
    content: "\fa41";
  }
  .nf-mdi-tune:before {
    content: "\fb2d";
  }
  .nf-mdi-tune_vertical:before {
    content: "\fb69";
  }
  .nf-mdi-twitch:before {
    content: "\fa42";
  }
  .nf-mdi-twitter:before {
    content: "\fa43";
  }
  .nf-mdi-twitter_box:before {
    content: "\fa44";
  }
  .nf-mdi-twitter_circle:before {
    content: "\fa45";
  }
  .nf-mdi-twitter_retweet:before {
    content: "\fa46";
  }
  .nf-mdi-uber:before {
    content: "\fc47";
  }
  .nf-mdi-ubuntu:before {
    content: "\fa47";
  }
  .nf-mdi-ultra_high_definition:before {
    content: "\fcf7";
  }
  .nf-mdi-umbraco:before {
    content: "\fa48";
  }
  .nf-mdi-umbrella:before {
    content: "\fa49";
  }
  .nf-mdi-umbrella_outline:before {
    content: "\fa4a";
  }
  .nf-mdi-undo:before {
    content: "\fa4b";
  }
  .nf-mdi-undo_variant:before {
    content: "\fa4c";
  }
  .nf-mdi-unfold_less_horizontal:before {
    content: "\fa4d";
  }
  .nf-mdi-unfold_less_vertical:before {
    content: "\fc5e";
  }
  .nf-mdi-unfold_more_horizontal:before {
    content: "\fa4e";
  }
  .nf-mdi-unfold_more_vertical:before {
    content: "\fc5f";
  }
  .nf-mdi-ungroup:before {
    content: "\fa4f";
  }
  .nf-mdi-unity:before {
    content: "\fbad";
  }
  .nf-mdi-untappd:before {
    content: "\fa50";
  }
  .nf-mdi-update:before {
    content: "\fbae";
  }
  .nf-mdi-upload:before {
    content: "\fa51";
  }
  .nf-mdi-upload_multiple:before {
    content: "\fd3b";
  }
  .nf-mdi-upload_network:before {
    content: "\fbf4";
  }
  .nf-mdi-usb:before {
    content: "\fa52";
  }
  .nf-mdi-van_passenger:before {
    content: "\fcf8";
  }
  .nf-mdi-van_utility:before {
    content: "\fcf9";
  }
  .nf-mdi-vanish:before {
    content: "\fcfa";
  }
  .nf-mdi-vector_arrange_above:before {
    content: "\fa53";
  }
  .nf-mdi-vector_arrange_below:before {
    content: "\fa54";
  }
  .nf-mdi-vector_circle:before {
    content: "\fa55";
  }
  .nf-mdi-vector_circle_variant:before {
    content: "\fa56";
  }
  .nf-mdi-vector_combine:before {
    content: "\fa57";
  }
  .nf-mdi-vector_curve:before {
    content: "\fa58";
  }
  .nf-mdi-vector_difference:before {
    content: "\fa59";
  }
  .nf-mdi-vector_difference_ab:before {
    content: "\fa5a";
  }
  .nf-mdi-vector_difference_ba:before {
    content: "\fa5b";
  }
  .nf-mdi-vector_intersection:before {
    content: "\fa5c";
  }
  .nf-mdi-vector_line:before {
    content: "\fa5d";
  }
  .nf-mdi-vector_point:before {
    content: "\fa5e";
  }
  .nf-mdi-vector_polygon:before {
    content: "\fa5f";
  }
  .nf-mdi-vector_polyline:before {
    content: "\fa60";
  }
  .nf-mdi-vector_radius:before {
    content: "\fc48";
  }
  .nf-mdi-vector_rectangle:before {
    content: "\fac5";
  }
  .nf-mdi-vector_selection:before {
    content: "\fa61";
  }
  .nf-mdi-vector_square:before {
    content: "\f500";
  }
  .nf-mdi-vector_triangle:before {
    content: "\fa62";
  }
  .nf-mdi-vector_union:before {
    content: "\fa63";
  }
  .nf-mdi-venmo:before {
    content: "\fa77";
  }
  .nf-mdi-verified:before {
    content: "\fa64";
  }
  .nf-mdi-vibrate:before {
    content: "\fa65";
  }
  .nf-mdi-video:before {
    content: "\fa66";
  }
  .nf-mdi-video_3d:before {
    content: "\fcfb";
  }
  .nf-mdi-video_4k_box:before {
    content: "\fd3c";
  }
  .nf-mdi-video_input_antenna:before {
    content: "\fd3d";
  }
  .nf-mdi-video_input_component:before {
    content: "\fd3e";
  }
  .nf-mdi-video_input_hdmi:before {
    content: "\fd3f";
  }
  .nf-mdi-video_input_svideo:before {
    content: "\fd40";
  }
  .nf-mdi-video_off:before {
    content: "\fa67";
  }
  .nf-mdi-video_switch:before {
    content: "\fa68";
  }
  .nf-mdi-view_agenda:before {
    content: "\fa69";
  }
  .nf-mdi-view_array:before {
    content: "\fa6a";
  }
  .nf-mdi-view_carousel:before {
    content: "\fa6b";
  }
  .nf-mdi-view_column:before {
    content: "\fa6c";
  }
  .nf-mdi-view_dashboard:before {
    content: "\fa6d";
  }
  .nf-mdi-view_dashboard_variant:before {
    content: "\fd41";
  }
  .nf-mdi-view_day:before {
    content: "\fa6e";
  }
  .nf-mdi-view_grid:before {
    content: "\fa6f";
  }
  .nf-mdi-view_headline:before {
    content: "\fa70";
  }
  .nf-mdi-view_list:before {
    content: "\fa71";
  }
  .nf-mdi-view_module:before {
    content: "\fa72";
  }
  .nf-mdi-view_parallel:before {
    content: "\fc26";
  }
  .nf-mdi-view_quilt:before {
    content: "\fa73";
  }
  .nf-mdi-view_sequential:before {
    content: "\fc27";
  }
  .nf-mdi-view_stream:before {
    content: "\fa74";
  }
  .nf-mdi-view_week:before {
    content: "\fa75";
  }
  .nf-mdi-vimeo:before {
    content: "\fa76";
  }
  .nf-mdi-violin:before {
    content: "\fb0e";
  }
  .nf-mdi-visualstudio:before {
    content: "\fb0f";
  }
  .nf-mdi-vk:before {
    content: "\fa78";
  }
  .nf-mdi-vk_box:before {
    content: "\fa79";
  }
  .nf-mdi-vk_circle:before {
    content: "\fa7a";
  }
  .nf-mdi-vlc:before {
    content: "\fa7b";
  }
  .nf-mdi-voice:before {
    content: "\faca";
  }
  .nf-mdi-voicemail:before {
    content: "\fa7c";
  }
  .nf-mdi-volume_high:before {
    content: "\fa7d";
  }
  .nf-mdi-volume_low:before {
    content: "\fa7e";
  }
  .nf-mdi-volume_medium:before {
    content: "\fa7f";
  }
  .nf-mdi-volume_minus:before {
    content: "\fc5c";
  }
  .nf-mdi-volume_mute:before {
    content: "\fc5d";
  }
  .nf-mdi-volume_off:before {
    content: "\fa80";
  }
  .nf-mdi-volume_plus:before {
    content: "\fc5b";
  }
  .nf-mdi-vpn:before {
    content: "\fa81";
  }
  .nf-mdi-vuejs:before {
    content: "\fd42";
  }
  .nf-mdi-walk:before {
    content: "\fa82";
  }
  .nf-mdi-wall:before {
    content: "\fcfc";
  }
  .nf-mdi-wallet:before {
    content: "\fa83";
  }
  .nf-mdi-wallet_giftcard:before {
    content: "\fa84";
  }
  .nf-mdi-wallet_membership:before {
    content: "\fa85";
  }
  .nf-mdi-wallet_travel:before {
    content: "\fa86";
  }
  .nf-mdi-wan:before {
    content: "\fa87";
  }
  .nf-mdi-washing_machine:before {
    content: "\fc28";
  }
  .nf-mdi-watch:before {
    content: "\fa88";
  }
  .nf-mdi-watch_export:before {
    content: "\fa89";
  }
  .nf-mdi-watch_import:before {
    content: "\fa8a";
  }
  .nf-mdi-watch_vibrate:before {
    content: "\fbaf";
  }
  .nf-mdi-water:before {
    content: "\fa8b";
  }
  .nf-mdi-water_off:before {
    content: "\fa8c";
  }
  .nf-mdi-water_percent:before {
    content: "\fa8d";
  }
  .nf-mdi-water_pump:before {
    content: "\fa8e";
  }
  .nf-mdi-watermark:before {
    content: "\fb11";
  }
  .nf-mdi-waves:before {
    content: "\fc8b";
  }
  .nf-mdi-weather_cloudy:before {
    content: "\fa8f";
  }
  .nf-mdi-weather_fog:before {
    content: "\fa90";
  }
  .nf-mdi-weather_hail:before {
    content: "\fa91";
  }
  .nf-mdi-weather_lightning:before {
    content: "\fa92";
  }
  .nf-mdi-weather_lightning_rainy:before {
    content: "\fb7c";
  }
  .nf-mdi-weather_night:before {
    content: "\fa93";
  }
  .nf-mdi-weather_partlycloudy:before {
    content: "\fa94";
  }
  .nf-mdi-weather_pouring:before {
    content: "\fa95";
  }
  .nf-mdi-weather_rainy:before {
    content: "\fa96";
  }
  .nf-mdi-weather_snowy:before {
    content: "\fa97";
  }
  .nf-mdi-weather_snowy_rainy:before {
    content: "\fb7d";
  }
  .nf-mdi-weather_sunny:before {
    content: "\fa98";
  }
  .nf-mdi-weather_sunset:before {
    content: "\fa99";
  }
  .nf-mdi-weather_sunset_down:before {
    content: "\fa9a";
  }
  .nf-mdi-weather_sunset_up:before {
    content: "\fa9b";
  }
  .nf-mdi-weather_windy:before {
    content: "\fa9c";
  }
  .nf-mdi-weather_windy_variant:before {
    content: "\fa9d";
  }
  .nf-mdi-web:before {
    content: "\fa9e";
  }
  .nf-mdi-webcam:before {
    content: "\fa9f";
  }
  .nf-mdi-webhook:before {
    content: "\fb2e";
  }
  .nf-mdi-webpack:before {
    content: "\fc29";
  }
  .nf-mdi-wechat:before {
    content: "\fb10";
  }
  .nf-mdi-weight:before {
    content: "\faa0";
  }
  .nf-mdi-weight_kilogram:before {
    content: "\faa1";
  }
  .nf-mdi-whatsapp:before {
    content: "\faa2";
  }
  .nf-mdi-wheelchair_accessibility:before {
    content: "\faa3";
  }
  .nf-mdi-white_balance_auto:before {
    content: "\faa4";
  }
  .nf-mdi-white_balance_incandescent:before {
    content: "\faa5";
  }
  .nf-mdi-white_balance_iridescent:before {
    content: "\faa6";
  }
  .nf-mdi-white_balance_sunny:before {
    content: "\faa7";
  }
  .nf-mdi-widgets:before {
    content: "\fc2a";
  }
  .nf-mdi-wifi:before {
    content: "\faa8";
  }
  .nf-mdi-wifi_off:before {
    content: "\faa9";
  }
  .nf-mdi-wii:before {
    content: "\faaa";
  }
  .nf-mdi-wiiu:before {
    content: "\fc2b";
  }
  .nf-mdi-wikipedia:before {
    content: "\faab";
  }
  .nf-mdi-window_close:before {
    content: "\faac";
  }
  .nf-mdi-window_closed:before {
    content: "\faad";
  }
  .nf-mdi-window_maximize:before {
    content: "\faae";
  }
  .nf-mdi-window_minimize:before {
    content: "\faaf";
  }
  .nf-mdi-window_open:before {
    content: "\fab0";
  }
  .nf-mdi-window_restore:before {
    content: "\fab1";
  }
  .nf-mdi-windows:before {
    content: "\fab2";
  }
  .nf-mdi-wordpress:before {
    content: "\fab3";
  }
  .nf-mdi-worker:before {
    content: "\fab4";
  }
  .nf-mdi-wrap:before {
    content: "\fab5";
  }
  .nf-mdi-wrench:before {
    content: "\fab6";
  }
  .nf-mdi-wunderlist:before {
    content: "\fab7";
  }
  .nf-mdi-xamarin:before {
    content: "\fd43";
  }
  .nf-mdi-xamarin_outline:before {
    content: "\fd44";
  }
  .nf-mdi-xaml:before {
    content: "\fb72";
  }
  .nf-mdi-xbox:before {
    content: "\fab8";
  }
  .nf-mdi-xbox_controller:before {
    content: "\fab9";
  }
  .nf-mdi-xbox_controller_battery_alert:before {
    content: "\fc49";
  }
  .nf-mdi-xbox_controller_battery_empty:before {
    content: "\fc4a";
  }
  .nf-mdi-xbox_controller_battery_full:before {
    content: "\fc4b";
  }
  .nf-mdi-xbox_controller_battery_low:before {
    content: "\fc4c";
  }
  .nf-mdi-xbox_controller_battery_medium:before {
    content: "\fc4d";
  }
  .nf-mdi-xbox_controller_battery_unknown:before {
    content: "\fc4e";
  }
  .nf-mdi-xbox_controller_off:before {
    content: "\faba";
  }
  .nf-mdi-xda:before {
    content: "\fabb";
  }
  .nf-mdi-xing:before {
    content: "\fabc";
  }
  .nf-mdi-xing_box:before {
    content: "\fabd";
  }
  .nf-mdi-xing_circle:before {
    content: "\fabe";
  }
  .nf-mdi-xml:before {
    content: "\fabf";
  }
  .nf-mdi-xmpp:before {
    content: "\fcfd";
  }
  .nf-mdi-yammer:before {
    content: "\fc87";
  }
  .nf-mdi-yeast:before {
    content: "\fac0";
  }
  .nf-mdi-yelp:before {
    content: "\fac1";
  }
  .nf-mdi-yin_yang:before {
    content: "\fb7e";
  }
  .nf-mdi-youtube_creator_studio:before {
    content: "\fd45";
  }
  .nf-mdi-youtube_gaming:before {
    content: "\fd46";
  }
  .nf-mdi-youtube_play:before {
    content: "\fac2";
  }
  .nf-mdi-youtube_tv:before {
    content: "\f947";
  }
  .nf-mdi-zip_box:before {
    content: "\fac3";
  }
  .nf-oct-alert:before {
    content: "\f421";
  }
  .nf-oct-arrow_down:before {
    content: "\f433";
  }
  .nf-oct-arrow_left:before {
    content: "\f434";
  }
  .nf-oct-arrow_right:before {
    content: "\f432";
  }
  .nf-oct-arrow_small_down:before {
    content: "\f479";
  }
  .nf-oct-arrow_small_left:before {
    content: "\f47a";
  }
  .nf-oct-arrow_small_right:before {
    content: "\f45c";
  }
  .nf-oct-arrow_small_up:before {
    content: "\f478";
  }
  .nf-oct-arrow_up:before {
    content: "\f431";
  }
  .nf-oct-beaker:before {
    content: "\f499";
  }
  .nf-oct-bell:before {
    content: "\f49a";
  }
  .nf-oct-bold:before {
    content: "\f49d";
  }
  .nf-oct-book:before {
    content: "\f405";
  }
  .nf-oct-bookmark:before {
    content: "\f461";
  }
  .nf-oct-briefcase:before {
    content: "\f491";
  }
  .nf-oct-broadcast:before {
    content: "\f43c";
  }
  .nf-oct-browser:before {
    content: "\f488";
  }
  .nf-oct-bug:before {
    content: "\f46f";
  }
  .nf-oct-calendar:before {
    content: "\f455";
  }
  .nf-oct-check:before {
    content: "\f42e";
  }
  .nf-oct-checklist:before {
    content: "\f45e";
  }
  .nf-oct-chevron_down:before {
    content: "\f47c";
  }
  .nf-oct-chevron_left:before {
    content: "\f47d";
  }
  .nf-oct-chevron_right:before {
    content: "\f460";
  }
  .nf-oct-chevron_up:before {
    content: "\f47b";
  }
  .nf-oct-circle_slash:before {
    content: "\f468";
  }
  .nf-oct-circuit_board:before {
    content: "\f493";
  }
  .nf-oct-clippy:before {
    content: "\f429";
  }
  .nf-oct-clock:before {
    content: "\f43a";
  }
  .nf-oct-cloud_download:before {
    content: "\f409";
  }
  .nf-oct-cloud_upload:before {
    content: "\f40a";
  }
  .nf-oct-code:before {
    content: "\f44f";
  }
  .nf-oct-comment:before {
    content: "\f41f";
  }
  .nf-oct-comment_discussion:before {
    content: "\f442";
  }
  .nf-oct-credit_card:before {
    content: "\f439";
  }
  .nf-oct-dash:before {
    content: "\f48b";
  }
  .nf-oct-dashboard:before {
    content: "\f463";
  }
  .nf-oct-database:before {
    content: "\f472";
  }
  .nf-oct-desktop_download:before {
    content: "\f498";
  }
  .nf-oct-device_camera:before {
    content: "\f446";
  }
  .nf-oct-device_camera_video:before {
    content: "\f447";
  }
  .nf-oct-device_desktop:before {
    content: "\f67c";
  }
  .nf-oct-device_mobile:before {
    content: "\f42c";
  }
  .nf-oct-diff:before {
    content: "\f440";
  }
  .nf-oct-diff_added:before {
    content: "\f457";
  }
  .nf-oct-diff_ignored:before {
    content: "\f474";
  }
  .nf-oct-diff_modified:before {
    content: "\f459";
  }
  .nf-oct-diff_removed:before {
    content: "\f458";
  }
  .nf-oct-diff_renamed:before {
    content: "\f45a";
  }
  .nf-oct-ellipses:before {
    content: "\f4a4";
  }
  .nf-oct-ellipsis:before {
    content: "\f475";
  }
  .nf-oct-eye:before {
    content: "\f441";
  }
  .nf-oct-file:before {
    content: "\f4a5";
  }
  .nf-oct-file_binary:before {
    content: "\f471";
  }
  .nf-oct-file_code:before {
    content: "\f40d";
  }
  .nf-oct-file_directory:before {
    content: "\f413";
  }
  .nf-oct-file_media:before {
    content: "\f40f";
  }
  .nf-oct-file_pdf:before {
    content: "\f411";
  }
  .nf-oct-file_submodule:before {
    content: "\f414";
  }
  .nf-oct-file_symlink_directory:before {
    content: "\f482";
  }
  .nf-oct-file_symlink_file:before {
    content: "\f481";
  }
  .nf-oct-file_text:before {
    content: "\f40e";
  }
  .nf-oct-file_zip:before {
    content: "\f410";
  }
  .nf-oct-flame:before {
    content: "\f490";
  }
  .nf-oct-fold:before {
    content: "\f48c";
  }
  .nf-oct-gear:before {
    content: "\f423";
  }
  .nf-oct-gift:before {
    content: "\f436";
  }
  .nf-oct-gist:before {
    content: "\f40c";
  }
  .nf-oct-gist_secret:before {
    content: "\f46c";
  }
  .nf-oct-git_branch:before {
    content: "\f418";
  }
  .nf-oct-git_commit:before {
    content: "\f417";
  }
  .nf-oct-git_compare:before {
    content: "\f47f";
  }
  .nf-oct-git_merge:before {
    content: "\f419";
  }
  .nf-oct-git_pull_request:before {
    content: "\f407";
  }
  .nf-oct-globe:before {
    content: "\f484";
  }
  .nf-oct-grabber:before {
    content: "\f4a6";
  }
  .nf-oct-graph:before {
    content: "\f437";
  }
  .nf-oct-heart:before {
    content: "\2665";
  }
  .nf-oct-history:before {
    content: "\f464";
  }
  .nf-oct-home:before {
    content: "\f46d";
  }
  .nf-oct-horizontal_rule:before {
    content: "\f45b";
  }
  .nf-oct-hubot:before {
    content: "\f477";
  }
  .nf-oct-inbox:before {
    content: "\f48d";
  }
  .nf-oct-info:before {
    content: "\f449";
  }
  .nf-oct-issue_closed:before {
    content: "\f41d";
  }
  .nf-oct-issue_opened:before {
    content: "\f41b";
  }
  .nf-oct-issue_reopened:before {
    content: "\f41c";
  }
  .nf-oct-italic:before {
    content: "\f49f";
  }
  .nf-oct-jersey:before {
    content: "\f416";
  }
  .nf-oct-key:before {
    content: "\f43d";
  }
  .nf-oct-keyboard:before {
    content: "\f40b";
  }
  .nf-oct-law:before {
    content: "\f495";
  }
  .nf-oct-light_bulb:before {
    content: "\f400";
  }
  .nf-oct-link:before {
    content: "\f44c";
  }
  .nf-oct-link_external:before {
    content: "\f465";
  }
  .nf-oct-list_ordered:before {
    content: "\f452";
  }
  .nf-oct-list_unordered:before {
    content: "\f451";
  }
  .nf-oct-location:before {
    content: "\f450";
  }
  .nf-oct-lock:before {
    content: "\f456";
  }
  .nf-oct-logo_gist:before {
    content: "\f480";
  }
  .nf-oct-logo_github:before {
    content: "\f470";
  }
  .nf-oct-mail:before {
    content: "\f42f";
  }
  .nf-oct-mail_read:before {
    content: "\f430";
  }
  .nf-oct-mail_reply:before {
    content: "\f443";
  }
  .nf-oct-mark_github:before {
    content: "\f408";
  }
  .nf-oct-markdown:before {
    content: "\f48a";
  }
  .nf-oct-megaphone:before {
    content: "\f45f";
  }
  .nf-oct-mention:before {
    content: "\f486";
  }
  .nf-oct-milestone:before {
    content: "\f45d";
  }
  .nf-oct-mirror:before {
    content: "\f41a";
  }
  .nf-oct-mortar_board:before {
    content: "\f494";
  }
  .nf-oct-mute:before {
    content: "\f466";
  }
  .nf-oct-no_newline:before {
    content: "\f476";
  }
  .nf-oct-octoface:before {
    content: "\f406";
  }
  .nf-oct-organization:before {
    content: "\f42b";
  }
  .nf-oct-package:before {
    content: "\f487";
  }
  .nf-oct-paintcan:before {
    content: "\f48f";
  }
  .nf-oct-pencil:before {
    content: "\f448";
  }
  .nf-oct-person:before {
    content: "\f415";
  }
  .nf-oct-pin:before {
    content: "\f435";
  }
  .nf-oct-plug:before {
    content: "\f492";
  }
  .nf-oct-plus:before {
    content: "\f44d";
  }
  .nf-oct-plus_small:before {
    content: "\f4a7";
  }
  .nf-oct-primitive_dot:before {
    content: "\f444";
  }
  .nf-oct-primitive_square:before {
    content: "\f445";
  }
  .nf-oct-pulse:before {
    content: "\f469";
  }
  .nf-oct-question:before {
    content: "\f420";
  }
  .nf-oct-quote:before {
    content: "\f453";
  }
  .nf-oct-radio_tower:before {
    content: "\f424";
  }
  .nf-oct-reply:before {
    content: "\f4a8";
  }
  .nf-oct-repo:before {
    content: "\f401";
  }
  .nf-oct-repo_clone:before {
    content: "\f43f";
  }
  .nf-oct-repo_force_push:before {
    content: "\f43e";
  }
  .nf-oct-repo_forked:before {
    content: "\f402";
  }
  .nf-oct-repo_pull:before {
    content: "\f404";
  }
  .nf-oct-repo_push:before {
    content: "\f403";
  }
  .nf-oct-rocket:before {
    content: "\f427";
  }
  .nf-oct-rss:before {
    content: "\f428";
  }
  .nf-oct-ruby:before {
    content: "\f43b";
  }
  .nf-oct-search:before {
    content: "\f422";
  }
  .nf-oct-server:before {
    content: "\f473";
  }
  .nf-oct-settings:before {
    content: "\f462";
  }
  .nf-oct-shield:before {
    content: "\f49c";
  }
  .nf-oct-sign_in:before {
    content: "\f42a";
  }
  .nf-oct-sign_out:before {
    content: "\f426";
  }
  .nf-oct-smiley:before {
    content: "\f4a2";
  }
  .nf-oct-squirrel:before {
    content: "\f483";
  }
  .nf-oct-star:before {
    content: "\f41e";
  }
  .nf-oct-stop:before {
    content: "\f46e";
  }
  .nf-oct-sync:before {
    content: "\f46a";
  }
  .nf-oct-tag:before {
    content: "\f412";
  }
  .nf-oct-tasklist:before {
    content: "\f4a0";
  }
  .nf-oct-telescope:before {
    content: "\f46b";
  }
  .nf-oct-terminal:before {
    content: "\f489";
  }
  .nf-oct-text_size:before {
    content: "\f49e";
  }
  .nf-oct-three_bars:before {
    content: "\f44e";
  }
  .nf-oct-thumbsdown:before {
    content: "\f497";
  }
  .nf-oct-thumbsup:before {
    content: "\f496";
  }
  .nf-oct-tools:before {
    content: "\f425";
  }
  .nf-oct-trashcan:before {
    content: "\f48e";
  }
  .nf-oct-triangle_down:before {
    content: "\f44b";
  }
  .nf-oct-triangle_left:before {
    content: "\f438";
  }
  .nf-oct-triangle_right:before {
    content: "\f44a";
  }
  .nf-oct-triangle_up:before {
    content: "\f47e";
  }
  .nf-oct-unfold:before {
    content: "\f42d";
  }
  .nf-oct-unmute:before {
    content: "\f485";
  }
  .nf-oct-unverified:before {
    content: "\f4a3";
  }
  .nf-oct-verified:before {
    content: "\f4a1";
  }
  .nf-oct-versions:before {
    content: "\f454";
  }
  .nf-oct-watch:before {
    content: "\f49b";
  }
  .nf-oct-x:before {
    content: "\f467";
  }
  .nf-oct-zap:before {
    content: "\26a1";
  }
  .nf-pl-branch:before {
    content: "\e0a0";
  }
  .nf-pl-current_line:before {
    content: "\e0a1";
  }
  .nf-pl-hostname:before {
    content: "\e0a2";
  }
  .nf-pl-left_hard_divider:before {
    content: "\e0b0";
  }
  .nf-pl-left_soft_divider:before {
    content: "\e0b1";
  }
  .nf-pl-line_number:before {
    content: "\e0a1";
  }
  .nf-pl-readonly:before {
    content: "\e0a2";
  }
  .nf-pl-right_hard_divider:before {
    content: "\e0b2";
  }
  .nf-pl-right_soft_divider:before {
    content: "\e0b3";
  }
  .nf-ple-backslash_separator:before {
    content: "\e0b9";
  }
  .nf-ple-backslash_separator_redundant:before {
    content: "\e0bf";
  }
  .nf-ple-column_number:before {
    content: "\e0a3";
  }
  .nf-ple-current_column:before {
    content: "\e0a3";
  }
  .nf-ple-flame_thick:before {
    content: "\e0c0";
  }
  .nf-ple-flame_thick_mirrored:before {
    content: "\e0c2";
  }
  .nf-ple-flame_thin:before {
    content: "\e0c1";
  }
  .nf-ple-flame_thin_mirrored:before {
    content: "\e0c3";
  }
  .nf-ple-forwardslash_separator:before {
    content: "\e0bb";
  }
  .nf-ple-forwardslash_separator_redundant:before {
    content: "\e0bd";
  }
  .nf-ple-honeycomb:before {
    content: "\e0cc";
  }
  .nf-ple-honeycomb_outline:before {
    content: "\e0cd";
  }
  .nf-ple-ice_waveform:before {
    content: "\e0c8";
  }
  .nf-ple-ice_waveform_mirrored:before {
    content: "\e0ca";
  }
  .nf-ple-left_half_circle_thick:before {
    content: "\e0b6";
  }
  .nf-ple-left_half_circle_thin:before {
    content: "\e0b7";
  }
  .nf-ple-lego_block_facing:before {
    content: "\e0d0";
  }
  .nf-ple-lego_block_sideways:before {
    content: "\e0d1";
  }
  .nf-ple-lego_separator:before {
    content: "\e0ce";
  }
  .nf-ple-lego_separator_thin:before {
    content: "\e0cf";
  }
  .nf-ple-lower_left_triangle:before {
    content: "\e0b8";
  }
  .nf-ple-lower_right_triangle:before {
    content: "\e0ba";
  }
  .nf-ple-pixelated_squares_big:before {
    content: "\e0c6";
  }
  .nf-ple-pixelated_squares_big_mirrored:before {
    content: "\e0c7";
  }
  .nf-ple-pixelated_squares_small:before {
    content: "\e0c4";
  }
  .nf-ple-pixelated_squares_small_mirrored:before {
    content: "\e0c5";
  }
  .nf-ple-right_half_circle_thick:before {
    content: "\e0b4";
  }
  .nf-ple-right_half_circle_thin:before {
    content: "\e0b5";
  }
  .nf-ple-trapezoid_top_bottom:before {
    content: "\e0d2";
  }
  .nf-ple-trapezoid_top_bottom_mirrored:before {
    content: "\e0d4";
  }
  .nf-ple-upper_left_triangle:before {
    content: "\e0bc";
  }
  .nf-ple-upper_right_triangle:before {
    content: "\e0be";
  }
  .nf-pom-away:before {
    content: "\e007";
  }
  .nf-pom-clean_code:before {
    content: "\e000";
  }
  .nf-pom-external_interruption:before {
    content: "\e00a";
  }
  .nf-pom-internal_interruption:before {
    content: "\e009";
  }
  .nf-pom-long_pause:before {
    content: "\e006";
  }
  .nf-pom-pair_programming:before {
    content: "\e008";
  }
  .nf-pom-pomodoro_done:before {
    content: "\e001";
  }
  .nf-pom-pomodoro_estimated:before {
    content: "\e002";
  }
  .nf-pom-pomodoro_squashed:before {
    content: "\e004";
  }
  .nf-pom-pomodoro_ticking:before {
    content: "\e003";
  }
  .nf-pom-short_pause:before {
    content: "\e005";
  }
  .nf-seti-bower:before {
    content: "\e61a";
  }
  .nf-seti-cjsx:before {
    content: "\e61b";
  }
  .nf-seti-coffee:before {
    content: "\e61b";
  }
  .nf-seti-config:before {
    content: "\e615";
  }
  .nf-seti-css:before {
    content: "\e614";
  }
  .nf-seti-default:before {
    content: "\e612";
  }
  .nf-seti-ejs:before {
    content: "\e618";
  }
  .nf-seti-favicon:before {
    content: "\e623";
  }
  .nf-seti-folder:before {
    content: "\e613";
  }
  .nf-seti-go:before {
    content: "\e627";
  }
  .nf-seti-grunt:before {
    content: "\e611";
  }
  .nf-seti-gulp:before {
    content: "\e610";
  }
  .nf-seti-haskell:before {
    content: "\e61f";
  }
  .nf-seti-heroku:before {
    content: "\e607";
  }
  .nf-seti-home:before {
    content: "\e617";
  }
  .nf-seti-html:before {
    content: "\e60e";
  }
  .nf-seti-image:before {
    content: "\e60d";
  }
  .nf-seti-javascript:before {
    content: "\e60c";
  }
  .nf-seti-json:before {
    content: "\e60b";
  }
  .nf-seti-julia:before {
    content: "\e624";
  }
  .nf-seti-karma:before {
    content: "\e622";
  }
  .nf-seti-less:before {
    content: "\e60b";
  }
  .nf-seti-license:before {
    content: "\e60a";
  }
  .nf-seti-lua:before {
    content: "\e620";
  }
  .nf-seti-markdown:before {
    content: "\e609";
  }
  .nf-seti-mustache:before {
    content: "\e60f";
  }
  .nf-seti-npm:before {
    content: "\e616";
  }
  .nf-seti-php:before {
    content: "\e608";
  }
  .nf-seti-play_arrow:before {
    content: "\e602";
  }
  .nf-seti-project:before {
    content: "\e601";
  }
  .nf-seti-python:before {
    content: "\e606";
  }
  .nf-seti-rails:before {
    content: "\e604";
  }
  .nf-seti-react:before {
    content: "\e625";
  }
  .nf-seti-ruby:before {
    content: "\e605";
  }
  .nf-seti-sass:before {
    content: "\e603";
  }
  .nf-seti-stylus:before {
    content: "\e600";
  }
  .nf-seti-text:before {
    content: "\e612";
  }
  .nf-seti-twig:before {
    content: "\e61c";
  }
  .nf-seti-typescript:before {
    content: "\e628";
  }
  .nf-seti-xml:before {
    content: "\e619";
  }
  .nf-weather-alien:before {
    content: "\e36e";
  }
  .nf-weather-aliens:before {
    content: "\e345";
  }
  .nf-weather-barometer:before {
    content: "\e372";
  }
  .nf-weather-celsius:before {
    content: "\e339";
  }
  .nf-weather-cloud:before {
    content: "\e33d";
  }
  .nf-weather-cloud_down:before {
    content: "\e33a";
  }
  .nf-weather-cloud_refresh:before {
    content: "\e33b";
  }
  .nf-weather-cloud_up:before {
    content: "\e33c";
  }
  .nf-weather-cloudy:before {
    content: "\e312";
  }
  .nf-weather-cloudy_gusts:before {
    content: "\e310";
  }
  .nf-weather-cloudy_windy:before {
    content: "\e311";
  }
  .nf-weather-day_cloudy:before {
    content: "\e302";
  }
  .nf-weather-day_cloudy_gusts:before {
    content: "\e300";
  }
  .nf-weather-day_cloudy_high:before {
    content: "\e376";
  }
  .nf-weather-day_cloudy_windy:before {
    content: "\e301";
  }
  .nf-weather-day_fog:before {
    content: "\e303";
  }
  .nf-weather-day_hail:before {
    content: "\e304";
  }
  .nf-weather-day_haze:before {
    content: "\e3ae";
  }
  .nf-weather-day_light_wind:before {
    content: "\e3bc";
  }
  .nf-weather-day_lightning:before {
    content: "\e305";
  }
  .nf-weather-day_rain:before {
    content: "\e308";
  }
  .nf-weather-day_rain_mix:before {
    content: "\e306";
  }
  .nf-weather-day_rain_wind:before {
    content: "\e307";
  }
  .nf-weather-day_showers:before {
    content: "\e309";
  }
  .nf-weather-day_sleet:before {
    content: "\e3aa";
  }
  .nf-weather-day_sleet_storm:before {
    content: "\e362";
  }
  .nf-weather-day_snow:before {
    content: "\e30a";
  }
  .nf-weather-day_snow_thunderstorm:before {
    content: "\e365";
  }
  .nf-weather-day_snow_wind:before {
    content: "\e35f";
  }
  .nf-weather-day_sprinkle:before {
    content: "\e30b";
  }
  .nf-weather-day_storm_showers:before {
    content: "\e30e";
  }
  .nf-weather-day_sunny:before {
    content: "\e30d";
  }
  .nf-weather-day_sunny_overcast:before {
    content: "\e30c";
  }
  .nf-weather-day_thunderstorm:before {
    content: "\e30f";
  }
  .nf-weather-day_windy:before {
    content: "\e37d";
  }
  .nf-weather-degrees:before {
    content: "\e33e";
  }
  .nf-weather-direction_down:before {
    content: "\e340";
  }
  .nf-weather-direction_down_left:before {
    content: "\e33f";
  }
  .nf-weather-direction_down_right:before {
    content: "\e380";
  }
  .nf-weather-direction_left:before {
    content: "\e344";
  }
  .nf-weather-direction_right:before {
    content: "\e349";
  }
  .nf-weather-direction_up:before {
    content: "\e353";
  }
  .nf-weather-direction_up_left:before {
    content: "\e37f";
  }
  .nf-weather-direction_up_right:before {
    content: "\e352";
  }
  .nf-weather-dust:before {
    content: "\e35d";
  }
  .nf-weather-earthquake:before {
    content: "\e3be";
  }
  .nf-weather-fahrenheit:before {
    content: "\e341";
  }
  .nf-weather-fire:before {
    content: "\e3bf";
  }
  .nf-weather-flood:before {
    content: "\e375";
  }
  .nf-weather-fog:before {
    content: "\e313";
  }
  .nf-weather-gale_warning:before {
    content: "\e3c5";
  }
  .nf-weather-hail:before {
    content: "\e314";
  }
  .nf-weather-horizon:before {
    content: "\e343";
  }
  .nf-weather-horizon_alt:before {
    content: "\e342";
  }
  .nf-weather-hot:before {
    content: "\e36b";
  }
  .nf-weather-humidity:before {
    content: "\e373";
  }
  .nf-weather-hurricane:before {
    content: "\e36c";
  }
  .nf-weather-hurricane_warning:before {
    content: "\e3c7";
  }
  .nf-weather-lightning:before {
    content: "\e315";
  }
  .nf-weather-lunar_eclipse:before {
    content: "\e369";
  }
  .nf-weather-meteor:before {
    content: "\e36a";
  }
  .nf-weather-moon_alt_first_quarter:before {
    content: "\e3ce";
  }
  .nf-weather-moon_alt_full:before {
    content: "\e3d5";
  }
  .nf-weather-moon_alt_new:before {
    content: "\e3e3";
  }
  .nf-weather-moon_alt_third_quarter:before {
    content: "\e3dc";
  }
  .nf-weather-moon_alt_waning_crescent_1:before {
    content: "\e3dd";
  }
  .nf-weather-moon_alt_waning_crescent_2:before {
    content: "\e3de";
  }
  .nf-weather-moon_alt_waning_crescent_3:before {
    content: "\e3df";
  }
  .nf-weather-moon_alt_waning_crescent_4:before {
    content: "\e3e0";
  }
  .nf-weather-moon_alt_waning_crescent_5:before {
    content: "\e3e1";
  }
  .nf-weather-moon_alt_waning_crescent_6:before {
    content: "\e3e2";
  }
  .nf-weather-moon_alt_waning_gibbous_1:before {
    content: "\e3d6";
  }
  .nf-weather-moon_alt_waning_gibbous_2:before {
    content: "\e3d7";
  }
  .nf-weather-moon_alt_waning_gibbous_3:before {
    content: "\e3d8";
  }
  .nf-weather-moon_alt_waning_gibbous_4:before {
    content: "\e3d9";
  }
  .nf-weather-moon_alt_waning_gibbous_5:before {
    content: "\e3da";
  }
  .nf-weather-moon_alt_waning_gibbous_6:before {
    content: "\e3db";
  }
  .nf-weather-moon_alt_waxing_crescent_1:before {
    content: "\e3c8";
  }
  .nf-weather-moon_alt_waxing_crescent_2:before {
    content: "\e3c9";
  }
  .nf-weather-moon_alt_waxing_crescent_3:before {
    content: "\e3ca";
  }
  .nf-weather-moon_alt_waxing_crescent_4:before {
    content: "\e3cb";
  }
  .nf-weather-moon_alt_waxing_crescent_5:before {
    content: "\e3cc";
  }
  .nf-weather-moon_alt_waxing_crescent_6:before {
    content: "\e3cd";
  }
  .nf-weather-moon_alt_waxing_gibbous_1:before {
    content: "\e3cf";
  }
  .nf-weather-moon_alt_waxing_gibbous_2:before {
    content: "\e3d0";
  }
  .nf-weather-moon_alt_waxing_gibbous_3:before {
    content: "\e3d1";
  }
  .nf-weather-moon_alt_waxing_gibbous_4:before {
    content: "\e3d2";
  }
  .nf-weather-moon_alt_waxing_gibbous_5:before {
    content: "\e3d3";
  }
  .nf-weather-moon_alt_waxing_gibbous_6:before {
    content: "\e3d4";
  }
  .nf-weather-moon_first_quarter:before {
    content: "\e394";
  }
  .nf-weather-moon_full:before {
    content: "\e39b";
  }
  .nf-weather-moon_new:before {
    content: "\e38d";
  }
  .nf-weather-moon_third_quarter:before {
    content: "\e3a2";
  }
  .nf-weather-moon_waning_crescent_1:before {
    content: "\e3a3";
  }
  .nf-weather-moon_waning_crescent_2:before {
    content: "\e3a4";
  }
  .nf-weather-moon_waning_crescent_3:before {
    content: "\e3a5";
  }
  .nf-weather-moon_waning_crescent_4:before {
    content: "\e3a6";
  }
  .nf-weather-moon_waning_crescent_5:before {
    content: "\e3a7";
  }
  .nf-weather-moon_waning_crescent_6:before {
    content: "\e3a8";
  }
  .nf-weather-moon_waning_gibbous_1:before {
    content: "\e39c";
  }
  .nf-weather-moon_waning_gibbous_2:before {
    content: "\e39d";
  }
  .nf-weather-moon_waning_gibbous_3:before {
    content: "\e39e";
  }
  .nf-weather-moon_waning_gibbous_4:before {
    content: "\e39f";
  }
  .nf-weather-moon_waning_gibbous_5:before {
    content: "\e3a0";
  }
  .nf-weather-moon_waning_gibbous_6:before {
    content: "\e3a1";
  }
  .nf-weather-moon_waxing_crescent_1:before {
    content: "\e38e";
  }
  .nf-weather-moon_waxing_crescent_2:before {
    content: "\e38f";
  }
  .nf-weather-moon_waxing_crescent_3:before {
    content: "\e390";
  }
  .nf-weather-moon_waxing_crescent_4:before {
    content: "\e391";
  }
  .nf-weather-moon_waxing_crescent_5:before {
    content: "\e392";
  }
  .nf-weather-moon_waxing_crescent_6:before {
    content: "\e393";
  }
  .nf-weather-moon_waxing_gibbous_1:before {
    content: "\e395";
  }
  .nf-weather-moon_waxing_gibbous_2:before {
    content: "\e396";
  }
  .nf-weather-moon_waxing_gibbous_3:before {
    content: "\e397";
  }
  .nf-weather-moon_waxing_gibbous_4:before {
    content: "\e398";
  }
  .nf-weather-moon_waxing_gibbous_5:before {
    content: "\e399";
  }
  .nf-weather-moon_waxing_gibbous_6:before {
    content: "\e39a";
  }
  .nf-weather-moonrise:before {
    content: "\e3c1";
  }
  .nf-weather-moonset:before {
    content: "\e3c2";
  }
  .nf-weather-na:before {
    content: "\e374";
  }
  .nf-weather-night_alt_cloudy:before {
    content: "\e37e";
  }
  .nf-weather-night_alt_cloudy_gusts:before {
    content: "\e31f";
  }
  .nf-weather-night_alt_cloudy_high:before {
    content: "\e377";
  }
  .nf-weather-night_alt_cloudy_windy:before {
    content: "\e320";
  }
  .nf-weather-night_alt_hail:before {
    content: "\e321";
  }
  .nf-weather-night_alt_lightning:before {
    content: "\e322";
  }
  .nf-weather-night_alt_partly_cloudy:before {
    content: "\e379";
  }
  .nf-weather-night_alt_rain:before {
    content: "\e325";
  }
  .nf-weather-night_alt_rain_mix:before {
    content: "\e326";
  }
  .nf-weather-night_alt_rain_wind:before {
    content: "\e324";
  }
  .nf-weather-night_alt_sleet:before {
    content: "\e3ac";
  }
  .nf-weather-night_alt_sleet_storm:before {
    content: "\e364";
  }
  .nf-weather-night_alt_snow:before {
    content: "\e327";
  }
  .nf-weather-night_alt_snow_thunderstorm:before {
    content: "\e367";
  }
  .nf-weather-night_alt_snow_wind:before {
    content: "\e361";
  }
  .nf-weather-night_alt_sprinkle:before {
    content: "\e328";
  }
  .nf-weather-night_alt_storm_showers:before {
    content: "\e329";
  }
  .nf-weather-night_alt_thunderstorm:before {
    content: "\e32a";
  }
  .nf-weather-night_clear:before {
    content: "\e32b";
  }
  .nf-weather-night_cloudy:before {
    content: "\e32e";
  }
  .nf-weather-night_cloudy_gusts:before {
    content: "\e32c";
  }
  .nf-weather-night_cloudy_high:before {
    content: "\e378";
  }
  .nf-weather-night_cloudy_windy:before {
    content: "\e32d";
  }
  .nf-weather-night_fog:before {
    content: "\e346";
  }
  .nf-weather-night_hail:before {
    content: "\e32f";
  }
  .nf-weather-night_lightning:before {
    content: "\e330";
  }
  .nf-weather-night_partly_cloudy:before {
    content: "\e37b";
  }
  .nf-weather-night_rain:before {
    content: "\e333";
  }
  .nf-weather-night_rain_mix:before {
    content: "\e331";
  }
  .nf-weather-night_rain_wind:before {
    content: "\e332";
  }
  .nf-weather-night_showers:before {
    content: "\e334";
  }
  .nf-weather-night_sleet:before {
    content: "\e3ab";
  }
  .nf-weather-night_sleet_storm:before {
    content: "\e363";
  }
  .nf-weather-night_snow:before {
    content: "\e335";
  }
  .nf-weather-night_snow_thunderstorm:before {
    content: "\e366";
  }
  .nf-weather-night_snow_wind:before {
    content: "\e360";
  }
  .nf-weather-night_sprinkle:before {
    content: "\e336";
  }
  .nf-weather-night_storm_showers:before {
    content: "\e337";
  }
  .nf-weather-night_thunderstorm:before {
    content: "\e338";
  }
  .nf-weather-rain:before {
    content: "\e318";
  }
  .nf-weather-rain_mix:before {
    content: "\e316";
  }
  .nf-weather-rain_wind:before {
    content: "\e317";
  }
  .nf-weather-raindrop:before {
    content: "\e371";
  }
  .nf-weather-raindrops:before {
    content: "\e34a";
  }
  .nf-weather-refresh:before {
    content: "\e348";
  }
  .nf-weather-refresh_alt:before {
    content: "\e347";
  }
  .nf-weather-sandstorm:before {
    content: "\e37a";
  }
  .nf-weather-showers:before {
    content: "\e319";
  }
  .nf-weather-sleet:before {
    content: "\e3ad";
  }
  .nf-weather-small_craft_advisory:before {
    content: "\e3c4";
  }
  .nf-weather-smog:before {
    content: "\e36d";
  }
  .nf-weather-smoke:before {
    content: "\e35c";
  }
  .nf-weather-snow:before {
    content: "\e31a";
  }
  .nf-weather-snow_wind:before {
    content: "\e35e";
  }
  .nf-weather-snowflake_cold:before {
    content: "\e36f";
  }
  .nf-weather-solar_eclipse:before {
    content: "\e368";
  }
  .nf-weather-sprinkle:before {
    content: "\e31b";
  }
  .nf-weather-stars:before {
    content: "\e370";
  }
  .nf-weather-storm_showers:before {
    content: "\e31c";
  }
  .nf-weather-storm_warning:before {
    content: "\e3c6";
  }
  .nf-weather-strong_wind:before {
    content: "\e34b";
  }
  .nf-weather-sunrise:before {
    content: "\e34c";
  }
  .nf-weather-sunset:before {
    content: "\e34d";
  }
  .nf-weather-thermometer:before {
    content: "\e350";
  }
  .nf-weather-thermometer_exterior:before {
    content: "\e34e";
  }
  .nf-weather-thermometer_internal:before {
    content: "\e34f";
  }
  .nf-weather-thunderstorm:before {
    content: "\e31d";
  }
  .nf-weather-time_1:before {
    content: "\e382";
  }
  .nf-weather-time_10:before {
    content: "\e38b";
  }
  .nf-weather-time_11:before {
    content: "\e38c";
  }
  .nf-weather-time_12:before {
    content: "\e381";
  }
  .nf-weather-time_2:before {
    content: "\e383";
  }
  .nf-weather-time_3:before {
    content: "\e384";
  }
  .nf-weather-time_4:before {
    content: "\e385";
  }
  .nf-weather-time_5:before {
    content: "\e386";
  }
  .nf-weather-time_6:before {
    content: "\e387";
  }
  .nf-weather-time_7:before {
    content: "\e388";
  }
  .nf-weather-time_8:before {
    content: "\e389";
  }
  .nf-weather-time_9:before {
    content: "\e38a";
  }
  .nf-weather-tornado:before {
    content: "\e351";
  }
  .nf-weather-train:before {
    content: "\e3c3";
  }
  .nf-weather-tsunami:before {
    content: "\e3bd";
  }
  .nf-weather-umbrella:before {
    content: "\e37c";
  }
  .nf-weather-volcano:before {
    content: "\e3c0";
  }
  .nf-weather-wind_beaufort_0:before {
    content: "\e3af";
  }
  .nf-weather-wind_beaufort_1:before {
    content: "\e3b0";
  }
  .nf-weather-wind_beaufort_10:before {
    content: "\e3b9";
  }
  .nf-weather-wind_beaufort_11:before {
    content: "\e3ba";
  }
  .nf-weather-wind_beaufort_12:before {
    content: "\e3bb";
  }
  .nf-weather-wind_beaufort_2:before {
    content: "\e3b1";
  }
  .nf-weather-wind_beaufort_3:before {
    content: "\e3b2";
  }
  .nf-weather-wind_beaufort_4:before {
    content: "\e3b3";
  }
  .nf-weather-wind_beaufort_5:before {
    content: "\e3b4";
  }
  .nf-weather-wind_beaufort_6:before {
    content: "\e3b5";
  }
  .nf-weather-wind_beaufort_7:before {
    content: "\e3b6";
  }
  .nf-weather-wind_beaufort_8:before {
    content: "\e3b7";
  }
  .nf-weather-wind_beaufort_9:before {
    content: "\e3b8";
  }
  .nf-weather-wind_direction:before {
    content: "\e3a9";
  }
  .nf-weather-wind_east:before {
    content: "\e35b";
  }
  .nf-weather-wind_north:before {
    content: "\e35a";
  }
  .nf-weather-wind_north_east:before {
    content: "\e359";
  }
  .nf-weather-wind_north_west:before {
    content: "\e358";
  }
  .nf-weather-wind_south:before {
    content: "\e357";
  }
  .nf-weather-wind_south_east:before {
    content: "\e356";
  }
  .nf-weather-wind_south_west:before {
    content: "\e355";
  }
  .nf-weather-wind_west:before {
    content: "\e354";
  }
  .nf-weather-windy:before {
    content: "\e31e";
  }
  