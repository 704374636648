.main-container{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 28rem;
}

.myVideo {
    position: fixed;
    object-fit: cover;
    width: 30vw;
    height: 50vh;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }



.logoHome {
  margin-top: 15%;
  position: relative;
  z-index: 2;
}

.logoContainer{
  text-align: center;
}

  .homeSection{
    min-width: 100%;
    min-height: 100%;
  }

  @media screen and (max-width: 1300px) {
    .logoHome{
      width: 90%;
      margin-top: 70%;
      position: relative;
      z-index: 2;
    }
}

  
