.breedsSection{
    background-image: url("../../../static/images/breeds.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.breedsGrid {
    margin-left: 40%;
    margin-right: 40%;
    max-width: 30rem;
    display: flex;
    justify-content: center;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.breedsButton {
    min-width: 100%;
    margin: 1.5em;
    font-size: 2em;
}

.breedsButton:hover {
    text-decoration: none;
}

.restrictedList {
    list-style: none;
}

@media screen and (max-width: 1300px) {
    .breedsButton {
        padding: 0.7em;
        margin: 0.3em;
    }
}
