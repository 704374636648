.navigationActive {
    opacity: 0.5;
    text-decoration: none;
    cursor: default;
}
.navigationActive:hover {
    opacity: 0.5;
}

.tabMenu  {
    border-top: 2px solid #c0c0c0;
    border-bottom: 2px solid #c0c0c0;
    display: flex;
    padding: 1em 0.5em;
    text-align: center;
    justify-content: space-evenly;
}

.contentTab {
    margin-top: 1.5em;
}

.dotLevelSection{
    display: flex;
    flex-direction: column;
  }
  
  .dotLevel{
    display: grid;
    grid-template-columns: .15fr lfr;
    grid-gap: 20px;
    gap: 20px
  }

@media screen and (max-width: 1300px) {
    .tabMenu{
        flex-direction: column;
        grid-gap: 25px;
        gap: 25px;
    }
}
