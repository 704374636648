.attributesSection{
    background-image: url("../../../static/images/attributes.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.description{
  color: white;
}

.attributesGrid{
    display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.attributesRow{
  padding: 2rem;
  text-align: center;
}
.attributesButton {
margin-left: 10%;
margin-right: 10%;
}
.attributesButton:hover {
  text-decoration: none;
}

@media screen and (max-width: 1300px) {
  .attributesGrid{
  display: block;
}

.attributesButton {
 margin: 0;
}
}