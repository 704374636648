a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.musicSection{
    background-image: url("../../static/images/music.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.sheetsSection{
    background-image: url("../../static/images/sheets.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.miscButton{
    background-color: transparent;
    width: 18rem;
    display: flex;
    flex-direction: row;
}

.musicButton {
    padding: 0;
}

.sheetsContent{
    display: grid;
    justify-content: space-around;
}

.mediaIcon{
    margin-right: 1em;
}