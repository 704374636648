.equipmentSection{
    background-image: url("../../../static/images/equipment.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.card {
    border: 1px solid hsla(0,0%,100%,.3);
    border-radius: 1em;
    padding: 2em;
    display: grid;
    grid-template-columns: auto auto auto;
}

.cardGrid {
    margin-top: 1em;
    max-width: 100em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
    column-gap: 50px;
    grid-row-gap: 1em;
    row-gap: 1em;
    justify-content: space-between;
}

@media screen and (max-width: 1300px) {
    .cardGrid {
        grid-template-columns: 1fr;
    }
    .card {
        padding: 1em;
        display: flex;
        flex-direction: column;
    }
}