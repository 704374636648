/* Dropdown Button */
.dropbtn {
  background-color: #000000;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  font-family: 'werewolf';
  min-width: 7.5em;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}



.menuItem {
  flex: 1;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #000000;
  min-width: 7.5em;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-family: 'werewolf';
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: rgb(255, 255, 255);
  padding: 12px 16px;
  font-size: 16px;
  text-decoration: none;
  display: block;

}

.dropdownLink {
  display: inline-block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  text-decoration: underline;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #707070;
}

@media screen and (max-width: 1300px) {
  .dropbtn {
    min-width: 7em;
    font-size: 1.5rem;
  }
  .dropdown {
    width: 100%;
  }

  .menuItem {
   display: none;
  }

  .menuItem-mobile {

  }

  .dropdown-content {
    width: 50%;
    top: 8px;
    right: 10px;
    position: absolute;
    display: row;
  }

  .dropdown-content a {
    
  }

  .dropdownLink {
  }

  .dropdown-content a:hover {

  }

  .dropdown:hover .dropdown-content {
  }

  .dropdown:hover .dropbtn {
  }
}

@media screen and (min-width: 3000px) {}