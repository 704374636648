.abilitiesSection{
    background-image: url("../../../static/images/abilities.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.abilitiesGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    grid-column-gap: 5em;
    -webkit-column-gap: 5em;
    column-gap: 5em;
}

.abilitiesRow{
    display: grid;
    grid-template-columns: .5fr .5fr;
    grid-auto-flow: row;
    justify-content: center;
    grid-gap: 1em;
    gap: 1em;
}
.abilityButton {

}
.abilityButton:hover {
  text-decoration: none;
}

@media screen and (max-width: 1300px) {
    .abilitiesGrid{
        grid-template-columns: 1fr;
        grid-row-gap: 1em;
        row-gap: 1em;
        margin-left: 1em;
        margin-right: 1em;
        padding-bottom: 2.5vh;
  }
}
