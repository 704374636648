.tribesSection{
    background-image: url("../../../static/images/tribes.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.tribesGrid {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto auto auto;
    padding: 10px;
}

.tribeButton{
    margin: 1rem;
    padding: 2rem;
}

.tribeButton:hover{
    text-decoration: none;
}

@media screen and (max-width: 1300px) {
    .tribesGrid {
        margin-left: 20%;
        margin-right: 20%;
        max-width: 10rem;
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto auto;
        padding: 10px;
    }
    .tribeButton{
        margin: 0.5rem;
        padding: 1rem;
    }
}