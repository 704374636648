.fadeIn {
    animation: "fadeIn" 1s ease-in-out;
    -webkit-animation: "fadeIn" 1s ease-in-out;
  }
  
  .fadeInSlower {
    animation: "fadeIn" 1.5s ease-in-out;
    -webkit-animation: "fadeIn" 1.5s ease-in-out;
  }
  
  .fadeInSlowest {
    animation: "fadeIn" 3s;
    -webkit-animation: "fadeIn" 3s ease-in-out;
  }
  
  @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
  }
  
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }