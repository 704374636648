.infoSection {
    background-image: url("../../static/images/info.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.charactersSection {
    background-image: url("../../static/images/caelan.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.storyInfo {
    padding-top: 5vw;
    padding-bottom: 10vw;
    font-size: 10vw;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    width: 100%;
}

.storyInfoContent{
    padding-top: 10vw;
    padding-bottom: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

@media screen and (max-width: 1300px) {
    .storyInfo {
        padding-bottom: 15vw;
        font-size: 10vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        width: 100%;
    }
    .storyInfoContent{
        padding-top: 10vw;
        padding-bottom: 10vw;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150vh;
    }
}
