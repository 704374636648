.auspicesSection{
    background-image: url("../../../static/images/auspices.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.auspicesGrid {
    margin-left: 40%;
    margin-right: 40%;
    max-width: 30rem;
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.auspiceButton{
    margin: 1rem;
    padding: 2rem;
}

.auspiceButton:hover{
    text-decoration: none;
}

@media screen and (max-width: 1300px) {
    .auspicesGrid {
        margin-left: 20%;
        margin-right: 20%;
        max-width: 10rem;
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto auto;
        padding: 10px;
    }
    .auspiceButton{
        margin: 0.5rem;
        padding: 1rem;
    }
}
