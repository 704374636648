@font-face {
    font-family: werewolf;
    src: url('../static/WEREWOLF.TTF');
}
@font-face {
  font-family:HK Grotesk;
  src:url('../static/HKGrotesk-Regular.otf') format("opentype");
  font-weight:400;
  font-style:normal
 }
 @font-face {
  font-family:HK Grotesk;
  src:url('../static/HKGrotesk-Bold.otf') format("opentype");
  font-weight:700;
  font-style:normal
 }
 @font-face {
  font-family:HK Grotesk;
  src:url('../static/HKGrotesk-Light.otf') format("opentype");
  font-weight:300;
  font-style:normal
 }

body {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow:hidden;
}

html { 

}

h3{
  font-size: 1.3em;
}

.mainSection {
    padding: 4rem 1.5rem;
    padding-bottom: 100%;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.contentContainer {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 30px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 2rem 1.5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    text-align: center;
    z-index: 1;
    min-width: 80%;
    min-height: 36rem;
    max-height: 36rem;
    overflow: auto;
}

.topContent{
  display: flex;
  justify-content: space-evenly;
}

.headerArea{
  display: grid;
  grid-template-columns: 1fr repeat(3, auto) 1fr;
  grid-column-gap: 5px;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.contentContainer::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
  .contentContainer::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
  }
  
  .contentContainer::-webkit-scrollbar-thumb {
    background-color: white;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    margin-top: 5%;
}

.subContainer {
  margin-top: 2rem;
    margin-left: 15%;
    margin-right: 15%;
}

.wolfHeader {
    font-family: 'werewolf';
}

.button {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    padding-bottom: 1em;
    border: 1px solid #e0e0db;
    opacity: .4;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity .5s;
    margin-bottom: 1em;
    text-decoration: none;
    color: white;
    font-family: 'werewolf';
}

.backButton{
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 2em;
  background-color: transparent;
  flex: 1;
}

.button:hover {
    transition: opacity 1s;
    background-color: #000000;
    opacity: 1;
}

h1 {
    font-size: 2.5em;
}

.capitalize {
    text-transform: capitalize;
  }
  
  a {
    font-size: 1.1rem;
    color: #e0e0db;
    font-weight: bold;
    text-decoration: underline transparent;
    position: relative;
    transition: border-color 300ms, color 300ms, text-decoration 300ms, opacity 300ms; 
  }
  
  a:hover {
    color: white;
    text-decoration: underline #e0e0db;
    
  }
  
  .navLink {
    text-decoration: none;
    border-bottom: 2px solid transparent;
  }
  
  .navLink:hover {
    color: #c0c0c0;
    text-decoration: none;
    border-color: #c0c0c0 !important;
  }
  
  .activeLink {
    opacity: 0.5;
  }
  
  .activeLink:hover {
    opacity: 0.7;
    border-color: transparent;
  }
  
  .characterLogo{
    max-height: 10rem;
    max-width: 10rem;
  }

  .buttonLogo {
    height: 2rem;
    max-height: 2rem;
    max-width: 2rem;
    margin-bottom: 1rem;
  }

  .characterLogoContainer{
    display: flex;
    justify-content: center;
  }

  .dotLevelSection{
    display: flex;
    flex-direction: column;
  }
  
  .dotLevel{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }


  @media screen and (max-width: 1300px) {
    body {
      overflow: auto;
    }
    .mainSection {
      padding: 1rem;
      padding-top: 4rem;
      padding-bottom: 5rem;
      display: flex;
  
  }
  
  .contentContainer {
      padding: 0.5rem;
      max-height: fit-content;
      margin-left: 0px;
      margin-right: 0px;
      overflow: hidden;
  }

  .button {
   
  }
  .dotLevel{

  }
  }
  
  @media screen and (min-width: 3000px) {
    .backButton{
      padding-left: 0.3em;
      padding-bottom: 0.5em;
      font-size: 2em;
      background-color: transparent;
      flex: 1;
    }
  
  }